import {
    getTodoByIdAPi,
    approveTodoApi,
    submitApprovalApi,
    createCardAPi,
    updateTodoAPi,
    deleteTodoAPi,
    getTodoTableApi,
    getApproversByLogUserIdApi,
    createAbroadAPi,
    updateAbroadAPi,
    revokeAbroadApi,
    getYSCGWorkDaysByTimeApi,
    portPrivateExitApp,
    portAuditCgPrivateExitApp
} from "@/api/request/todo";
import {getAllOptionsApi,queryTravelReportUser} from '@/api/request/userInfo'

export const getWorkDaysByTimeAsync = (data: any) => {
    return getYSCGWorkDaysByTimeApi(data).then((res: any) => {
        if (res.code === 200) {
            return res;
        }
    })
}

export const operateAbroadApplyAsync = (type: any, data: any) => {
    const port = (type: string) => {
        let temp: any = null;
        switch (type) {
            case 'create':
                temp = createAbroadAPi;
                break;
            case 'update':
                temp = updateAbroadAPi;
                break;
            case 'delete':
                temp = deleteTodoAPi;
                break;
        }
        return temp;
    }
    return port(type)(data).then((res: any) => {
        return res;
    })
}
export const operateTodoAsync = (type: any, data: any) => {
    const port = (type: string) => {
        let temp: any = null;
        switch (type) {
            case 'create':
                temp = createCardAPi;
                break;
            case 'update':
                temp = updateTodoAPi;
                break;
            case 'delete':
                temp = deleteTodoAPi;
                break;
        }
        return temp;
    }
    return port(type)(data).then((res: any) => {
        return res;
    })
}

export const submitOwnApprovalAsync = (data) => {
    return approveTodoApi(data).then((res: any) => {
        return res;
    })
}
export const revokeAbroadApplyAsync = (data) => {
    return revokeAbroadApi(data).then((res: any) => {
        return res;
    })
}
export const  revokePortPrivateExitApps = (data)=>{
    return portPrivateExitApp(data).then((res: any) => {
        return res;
    })
}
export const submitApprovalAsync = (data) => {
    return submitApprovalApi(data).then((res: any) => {
        return res;
    })
}

export const submitportAuditCgPrivateExitApp = (data) => {
    return portAuditCgPrivateExitApp(data).then((res: any) => {
        return res;
    })
}
export const getTodoByIdAsync = (id) => {
    return getTodoByIdAPi(id).then((res: any) => {
        if (res.code === 200) {
            return res;
        }
    })
}
let leaveStatusData:any = [];


const handleCovertStatus = (code) => {
    switch (code) {
        case '10':
            return '草稿';
        case '15':
            return '待提交';
        case '20':
            return '审批中';
        case '30':
            return '审批通过';
        case '40':
            return '退回修改';
        case '60':
            return '已销假';
    }
}

export const getTodoTableAsync = (data) => {
    return getTodoTableApi(data).then((res: any) => {
        if (res.code === 200) {
            return {
                total: res.data.total,
                data: res.data.list?.map((item: any) => {
                    return {
                        ...item,
                        userNameJoinNum: `${item.userNumber}-${item.userName}`,
                        moduleTypeName: item.dataSourceBusinessCode === 'CGJ' ? '出国境' : '办证办签',
                        toDoTypeName: item.toDoType === 'apply' ? '申请' : '审批',
                        updateIcon: item.privateStatusCode === '10' || item.privateStatusCode === '15' || item.privateStatusCode === '40' || item.privateStatusCode === '80' || item.privateStatusCode === '70' ,
                        deleteIcon: item.privateStatusCode === '10' || item.privateStatusCode === '40' || item.privateStatusCode === '15'|| item.privateStatusCode === '80' ,
                        submitIcon: item.privateStatusCode === '15',
                        onApproval: item.privateStatusCode === '50',
                        approvalIcon: (item.privateStatusCode === '20' || item.privateStatusCode === '60' || item.privateStatusCode === '90') && item.toDoType === 'approve',
                        viewIcon: false,
                        backoutIcon: item.privateStatusCode === '30' && item.dataSourceBusinessCode != "BZBQ"
                    }
                })

            }
        }
    })

}
// 获取值集
export const getAllOptionsAsync = async () => {
    const res: any = await getAllOptionsApi();
    console.log('getAllOptionsApi:', res);
    if (res.code === 200) {
        console.log('获取所有值集:', res.data);

        return {
            userRoleTypeData: res.data?.filter((item: any) => item.codesetCode === 'sys.xt.grouproletypecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            filingUserStatusData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.decordsstatuscode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            genderTypeData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.usergendercode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                }
            }),
            userTypeData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.usertypecode2').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                }
            }),
            groupUserTypeData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.usertypecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                }
            }), dealData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.businesstypecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            licenseNameData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.licensetypecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            cardVCertificatesData: res.data?.filter((item: any) => item.codesetCode === 'sys.xt.approvebusinesstypeCode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            licenseHandleCountData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.visaapplicationcode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            passCardData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.hongKongMacao').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            homeLocationData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.placeOfConsent').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            ccqjProcessBusData: res.data?.filter((item: any) => item.codesetCode === 'sys.xt.processbusinesscode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            yscgProcessBusData: res.data?.filter((item: any) => item.codesetCode === 'sys.xt.processbusinesscodeYSCG').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            holidayTypeData: res.data?.filter((item: any) => item.codesetCode === 'sys.qj.vacationtypecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            holidayComputeData: res.data?.filter((item: any) => item.codesetCode === 'sys.qj.vacationmodecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            holidayBaseUnitData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.vacationunitcode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            holidayExtraDistributeData: res.data?.filter((item: any) => item.codesetCode === 'sys.qj.balancemodecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            holidayValidDateData: res.data?.filter((item: any) => item.codesetCode === 'sys.qj.validtimecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            holidayAlarmData: res.data?.filter((item: any) => item.codesetCode === 'sys.qj.overduetimecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            holidayExtraUnitData: res.data?.filter((item: any) => item.codesetCode === 'sys.qj.balanceretaintypecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            ccqjProcessTemplateData: res.data?.filter((item: any) => item.codesetCode === 'sys.xt.processtemplatecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            yscgBusStatusCodeData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.busstatuscode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            yscgProcessTemplateData: res.data?.filter((item: any) => item.codesetCode === 'sys.xt.processtemplatecodeYSCG').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            processTypeData: res.data?.filter((item: any) => item.codesetCode === 'sys.xt.processtypecode').map(item => {
                return {
                    value: item.value,
                    label: item.label,
                    disabled: false,
                }
            }),
            processevectiontypecode: res.data?.filter((item: any) => item.codesetCode === 'sys.xt.processevectiontypecode').map(item => {
              return {
                  value: item.value,
                  label: item.label,
                  disabled: false,
              }
          }),
          adjusttypecode: res.data?.filter((item: any) => item.codesetCode === 'sys.adjusttypecode').map(item => {
            return {
                value: item.value,
                label: item.label,
                disabled: false,
            }
          }),
          licensetypecode: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.licensetypecode2').map(item => {
            return {
                value: item.value,
                label: item.label,
                disabled: false,
            }
          }),
            leaveStatusData: res.data?.filter((item: any) => item.codesetCode === 'sys.qj.leaveStatus'),
            todoLeaveStatusData: res.data?.filter((item: any) => item.codesetCode === 'sys.qj.leaveStatusQuery'),
            doneLeaveStatusData: res.data?.filter((item: any) => item.codesetCode === 'sys.qj.ybleaveStatusQuery'),
            sealStatusData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.sealstatuscode'),
            sealTypeData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.sealapplytypecode'),
            licenseTypeCodeData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.licensetypecode'),
            licenseStatusCodeData: res.data?.filter((item: any) => item.codesetCode === 'sys.cgj.licensestatuscode'),
        }

    } else {
        return []
    }
}

export const exportqueryTravelReportUser = () =>{
  return queryTravelReportUser().then((res)=>{
    return res
  })
}

export const getApproverOptionsByLogUserIdAsync = (todoId) => {
    return getApproversByLogUserIdApi(todoId).then((res: any) => {
        if (res.code === 200) {
            console.log('getApproverOptionsByLogUserIdAsync:', res)
            return {
                isEnd: res.data.nodeCode === 'End',
                data: res.data.userList?.map((item: any) => {
                    return {
                        value: item.userId,
                        label: item.userName,
                        groupCode: item.groupCode,
                        disabled: false,
                    }
                })
            };
        }
        return [];
    })
}

export const jsonList = [
    {
      "province": "北京市",
      "code": "110000",
      "provinces": [
        {
          "province": "北京市",
          "code": "110100"
        }
      ]
    },
    {
      "province": "天津市",
      "code": "120000",
      "provinces": [
        {
          "province": "天津市",
          "code": "120100"
        }
      ]
    },
    {
      "province": "河北省",
      "code": "130000",
      "provinces": [
        {
          "province": "石家庄市",
          "code": "130100"
        },
        {
          "province": "唐山市",
          "code": "130200"
        },
        {
          "province": "秦皇岛市",
          "code": "130300"
        },
        {
          "province": "邯郸市",
          "code": "130400"
        },
        {
          "province": "邢台市",
          "code": "130500",
          "areas": [
            {
              "area": "襄都区",
              "code": "130502"
            },
            {
              "area": "信都区",
              "code": "130503"
            },
            {
              "area": "任泽区",
              "code": "130505"
            },
            {
              "area": "南和区",
              "code": "130506"
            },
            {
              "area": "临城县",
              "code": "130522"
            },
            {
              "area": "内丘县",
              "code": "130523"
            },
            {
              "area": "柏乡县",
              "code": "130524"
            },
            {
              "area": "隆尧县",
              "code": "130525"
            },
            {
              "area": "宁晋县",
              "code": "130528"
            },
            {
              "area": "巨鹿县",
              "code": "130529"
            },
            {
              "area": "新河县",
              "code": "130530"
            },
            {
              "area": "广宗县",
              "code": "130531"
            },
            {
              "area": "平乡县",
              "code": "130532"
            },
            {
              "area": "威县",
              "code": "130533"
            },
            {
              "area": "清河县",
              "code": "130534"
            },
            {
              "area": "临西县",
              "code": "130535"
            },
            {
              "area": "河北邢台经济开发区",
              "code": "130571"
            },
            {
              "area": "南宫市",
              "code": "130581"
            },
            {
              "area": "沙河市",
              "code": "130582"
            }
          ]
        },
        {
          "province": "保定市",
          "code": "130600",
          "areas": [
            {
              "area": "竞秀区",
              "code": "130602"
            },
            {
              "area": "莲池区",
              "code": "130606"
            },
            {
              "area": "满城区",
              "code": "130607"
            },
            {
              "area": "清苑区",
              "code": "130608"
            },
            {
              "area": "徐水区",
              "code": "130609"
            },
            {
              "area": "涞水县",
              "code": "130623"
            },
            {
              "area": "阜平县",
              "code": "130624"
            },
            {
              "area": "定兴县",
              "code": "130626"
            },
            {
              "area": "唐县",
              "code": "130627"
            },
            {
              "area": "高阳县",
              "code": "130628"
            },
            {
              "area": "容城县",
              "code": "130629"
            },
            {
              "area": "涞源县",
              "code": "130630"
            },
            {
              "area": "望都县",
              "code": "130631"
            },
            {
              "area": "安新县",
              "code": "130632"
            },
            {
              "area": "易县",
              "code": "130633"
            },
            {
              "area": "曲阳县",
              "code": "130634"
            },
            {
              "area": "蠡县",
              "code": "130635"
            },
            {
              "area": "顺平县",
              "code": "130636"
            },
            {
              "area": "博野县",
              "code": "130637"
            },
            {
              "area": "雄县",
              "code": "130638"
            },
            {
              "area": "保定高新技术产业开发区",
              "code": "130671"
            },
            {
              "area": "保定白沟新城",
              "code": "130672"
            },
            {
              "area": "涿州市",
              "code": "130681"
            },
            {
              "area": "定州市",
              "code": "130682"
            },
            {
              "area": "安国市",
              "code": "130683"
            },
            {
              "area": "高碑店市",
              "code": "130684"
            }
          ]
        },
        {
          "province": "张家口市",
          "code": "130700",
          "areas": [
            {
              "area": "桥东区",
              "code": "130702"
            },
            {
              "area": "桥西区",
              "code": "130703"
            },
            {
              "area": "宣化区",
              "code": "130705"
            },
            {
              "area": "下花园区",
              "code": "130706"
            },
            {
              "area": "万全区",
              "code": "130708"
            },
            {
              "area": "崇礼区",
              "code": "130709"
            },
            {
              "area": "张北县",
              "code": "130722"
            },
            {
              "area": "康保县",
              "code": "130723"
            },
            {
              "area": "沽源县",
              "code": "130724"
            },
            {
              "area": "尚义县",
              "code": "130725"
            },
            {
              "area": "蔚县",
              "code": "130726"
            },
            {
              "area": "阳原县",
              "code": "130727"
            },
            {
              "area": "怀安县",
              "code": "130728"
            },
            {
              "area": "怀来县",
              "code": "130730"
            },
            {
              "area": "涿鹿县",
              "code": "130731"
            },
            {
              "area": "赤城县",
              "code": "130732"
            },
            {
              "area": "张家口经济开发区",
              "code": "130771"
            },
            {
              "area": "张家口市察北管理区",
              "code": "130772"
            },
            {
              "area": "张家口市塞北管理区",
              "code": "130773"
            }
          ]
        },
        {
          "province": "承德市",
          "code": "130800",
          "areas": [
            {
              "area": "双桥区",
              "code": "130802"
            },
            {
              "area": "双滦区",
              "code": "130803"
            },
            {
              "area": "鹰手营子矿区",
              "code": "130804"
            },
            {
              "area": "承德县",
              "code": "130821"
            },
            {
              "area": "兴隆县",
              "code": "130822"
            },
            {
              "area": "滦平县",
              "code": "130824"
            },
            {
              "area": "隆化县",
              "code": "130825"
            },
            {
              "area": "丰宁满族自治县",
              "code": "130826"
            },
            {
              "area": "宽城满族自治县",
              "code": "130827"
            },
            {
              "area": "围场满族蒙古族自治县",
              "code": "130828"
            },
            {
              "area": "承德高新技术产业开发区",
              "code": "130871"
            },
            {
              "area": "平泉市",
              "code": "130881"
            }
          ]
        },
        {
          "province": "沧州市",
          "code": "130900",
          "areas": [
            {
              "area": "新华区",
              "code": "130902"
            },
            {
              "area": "运河区",
              "code": "130903"
            },
            {
              "area": "沧县",
              "code": "130921"
            },
            {
              "area": "青县",
              "code": "130922"
            },
            {
              "area": "东光县",
              "code": "130923"
            },
            {
              "area": "海兴县",
              "code": "130924"
            },
            {
              "area": "盐山县",
              "code": "130925"
            },
            {
              "area": "肃宁县",
              "code": "130926"
            },
            {
              "area": "南皮县",
              "code": "130927"
            },
            {
              "area": "吴桥县",
              "code": "130928"
            },
            {
              "area": "献县",
              "code": "130929"
            },
            {
              "area": "孟村回族自治县",
              "code": "130930"
            },
            {
              "area": "河北沧州经济开发区",
              "code": "130971"
            },
            {
              "area": "沧州高新技术产业开发区",
              "code": "130972"
            },
            {
              "area": "沧州渤海新区",
              "code": "130973"
            },
            {
              "area": "泊头市",
              "code": "130981"
            },
            {
              "area": "任丘市",
              "code": "130982"
            },
            {
              "area": "黄骅市",
              "code": "130983"
            },
            {
              "area": "河间市",
              "code": "130984"
            }
          ]
        },
        {
          "province": "廊坊市",
          "code": "131000",
          "areas": [
            {
              "area": "安次区",
              "code": "131002"
            },
            {
              "area": "广阳区",
              "code": "131003"
            },
            {
              "area": "固安县",
              "code": "131022"
            },
            {
              "area": "永清县",
              "code": "131023"
            },
            {
              "area": "香河县",
              "code": "131024"
            },
            {
              "area": "大城县",
              "code": "131025"
            },
            {
              "area": "文安县",
              "code": "131026"
            },
            {
              "area": "大厂回族自治县",
              "code": "131028"
            },
            {
              "area": "廊坊经济技术开发区",
              "code": "131071"
            },
            {
              "area": "霸州市",
              "code": "131081"
            },
            {
              "area": "三河市",
              "code": "131082"
            }
          ]
        },
        {
          "province": "衡水市",
          "code": "131100",
          "areas": [
            {
              "area": "桃城区",
              "code": "131102"
            },
            {
              "area": "冀州区",
              "code": "131103"
            },
            {
              "area": "枣强县",
              "code": "131121"
            },
            {
              "area": "武邑县",
              "code": "131122"
            },
            {
              "area": "武强县",
              "code": "131123"
            },
            {
              "area": "饶阳县",
              "code": "131124"
            },
            {
              "area": "安平县",
              "code": "131125"
            },
            {
              "area": "故城县",
              "code": "131126"
            },
            {
              "area": "景县",
              "code": "131127"
            },
            {
              "area": "阜城县",
              "code": "131128"
            },
            {
              "area": "河北衡水高新技术产业开发区",
              "code": "131171"
            },
            {
              "area": "衡水滨湖新区",
              "code": "131172"
            },
            {
              "area": "深州市",
              "code": "131182"
            }
          ]
        },
        {
          "province": "雄安新区",
          "code": "133100",
          "areas": [
            {
              "area": "雄安新区",
              "code": "133100"
            }
          ]
        }
      ]
    },
    {
      "province": "山西省",
      "code": "140000",
      "provinces": [
        {
          "province": "太原市",
          "code": "140100",
          "areas": [
            {
              "area": "小店区",
              "code": "140105"
            },
            {
              "area": "迎泽区",
              "code": "140106"
            },
            {
              "area": "杏花岭区",
              "code": "140107"
            },
            {
              "area": "尖草坪区",
              "code": "140108"
            },
            {
              "area": "万柏林区",
              "code": "140109"
            },
            {
              "area": "晋源区",
              "code": "140110"
            },
            {
              "area": "清徐县",
              "code": "140121"
            },
            {
              "area": "阳曲县",
              "code": "140122"
            },
            {
              "area": "娄烦县",
              "code": "140123"
            },
            {
              "area": "山西转型综合改革示范区",
              "code": "140171"
            },
            {
              "area": "古交市",
              "code": "140181"
            }
          ]
        },
        {
          "province": "大同市",
          "code": "140200",
          "areas": [
            {
              "area": "新荣区",
              "code": "140212"
            },
            {
              "area": "平城区",
              "code": "140213"
            },
            {
              "area": "云冈区",
              "code": "140214"
            },
            {
              "area": "云州区",
              "code": "140215"
            },
            {
              "area": "阳高县",
              "code": "140221"
            },
            {
              "area": "天镇县",
              "code": "140222"
            },
            {
              "area": "广灵县",
              "code": "140223"
            },
            {
              "area": "灵丘县",
              "code": "140224"
            },
            {
              "area": "浑源县",
              "code": "140225"
            },
            {
              "area": "左云县",
              "code": "140226"
            },
            {
              "area": "山西大同经济开发区",
              "code": "140271"
            }
          ]
        },
        {
          "province": "阳泉市",
          "code": "140300",
          "areas": [
            {
              "area": "城区",
              "code": "140302"
            },
            {
              "area": "矿区",
              "code": "140303"
            },
            {
              "area": "郊区",
              "code": "140311"
            },
            {
              "area": "平定县",
              "code": "140321"
            },
            {
              "area": "盂县",
              "code": "140322"
            }
          ]
        },
        {
          "province": "长治市",
          "code": "140400",
          "areas": [
            {
              "area": "潞州区",
              "code": "140403"
            },
            {
              "area": "上党区",
              "code": "140404"
            },
            {
              "area": "屯留区",
              "code": "140405"
            },
            {
              "area": "潞城区",
              "code": "140406"
            },
            {
              "area": "襄垣县",
              "code": "140423"
            },
            {
              "area": "平顺县",
              "code": "140425"
            },
            {
              "area": "黎城县",
              "code": "140426"
            },
            {
              "area": "壶关县",
              "code": "140427"
            },
            {
              "area": "长子县",
              "code": "140428"
            },
            {
              "area": "武乡县",
              "code": "140429"
            },
            {
              "area": "沁县",
              "code": "140430"
            },
            {
              "area": "沁源县",
              "code": "140431"
            }
          ]
        },
        {
          "province": "晋城市",
          "code": "140500",
          "areas": [
            {
              "area": "城区",
              "code": "140502"
            },
            {
              "area": "沁水县",
              "code": "140521"
            },
            {
              "area": "阳城县",
              "code": "140522"
            },
            {
              "area": "陵川县",
              "code": "140524"
            },
            {
              "area": "泽州县",
              "code": "140525"
            },
            {
              "area": "高平市",
              "code": "140581"
            }
          ]
        },
        {
          "province": "朔州市",
          "code": "140600",
          "areas": [
            {
              "area": "朔城区",
              "code": "140602"
            },
            {
              "area": "平鲁区",
              "code": "140603"
            },
            {
              "area": "山阴县",
              "code": "140621"
            },
            {
              "area": "应县",
              "code": "140622"
            },
            {
              "area": "右玉县",
              "code": "140623"
            },
            {
              "area": "山西朔州经济开发区",
              "code": "140671"
            },
            {
              "area": "怀仁市",
              "code": "140681"
            }
          ]
        },
        {
          "province": "晋中市",
          "code": "140700",
          "areas": [
            {
              "area": "榆次区",
              "code": "140702"
            },
            {
              "area": "太谷区",
              "code": "140703"
            },
            {
              "area": "榆社县",
              "code": "140721"
            },
            {
              "area": "左权县",
              "code": "140722"
            },
            {
              "area": "和顺县",
              "code": "140723"
            },
            {
              "area": "昔阳县",
              "code": "140724"
            },
            {
              "area": "寿阳县",
              "code": "140725"
            },
            {
              "area": "祁县",
              "code": "140727"
            },
            {
              "area": "平遥县",
              "code": "140728"
            },
            {
              "area": "灵石县",
              "code": "140729"
            },
            {
              "area": "介休市",
              "code": "140781"
            }
          ]
        },
        {
          "province": "运城市",
          "code": "140800",
          "areas": [
            {
              "area": "盐湖区",
              "code": "140802"
            },
            {
              "area": "临猗县",
              "code": "140821"
            },
            {
              "area": "万荣县",
              "code": "140822"
            },
            {
              "area": "闻喜县",
              "code": "140823"
            },
            {
              "area": "稷山县",
              "code": "140824"
            },
            {
              "area": "新绛县",
              "code": "140825"
            },
            {
              "area": "绛县",
              "code": "140826"
            },
            {
              "area": "垣曲县",
              "code": "140827"
            },
            {
              "area": "夏县",
              "code": "140828"
            },
            {
              "area": "平陆县",
              "code": "140829"
            },
            {
              "area": "芮城县",
              "code": "140830"
            },
            {
              "area": "永济市",
              "code": "140881"
            },
            {
              "area": "河津市",
              "code": "140882"
            }
          ]
        },
        {
          "province": "忻州市",
          "code": "140900",
          "areas": [
            {
              "area": "忻府区",
              "code": "140902"
            },
            {
              "area": "定襄县",
              "code": "140921"
            },
            {
              "area": "五台县",
              "code": "140922"
            },
            {
              "area": "代县",
              "code": "140923"
            },
            {
              "area": "繁峙县",
              "code": "140924"
            },
            {
              "area": "宁武县",
              "code": "140925"
            },
            {
              "area": "静乐县",
              "code": "140926"
            },
            {
              "area": "神池县",
              "code": "140927"
            },
            {
              "area": "五寨县",
              "code": "140928"
            },
            {
              "area": "岢岚县",
              "code": "140929"
            },
            {
              "area": "河曲县",
              "code": "140930"
            },
            {
              "area": "保德县",
              "code": "140931"
            },
            {
              "area": "偏关县",
              "code": "140932"
            },
            {
              "area": "五台山风景名胜区",
              "code": "140971"
            },
            {
              "area": "原平市",
              "code": "140981"
            }
          ]
        },
        {
          "province": "临汾市",
          "code": "141000",
          "areas": [
            {
              "area": "尧都区",
              "code": "141002"
            },
            {
              "area": "曲沃县",
              "code": "141021"
            },
            {
              "area": "翼城县",
              "code": "141022"
            },
            {
              "area": "襄汾县",
              "code": "141023"
            },
            {
              "area": "洪洞县",
              "code": "141024"
            },
            {
              "area": "古县",
              "code": "141025"
            },
            {
              "area": "安泽县",
              "code": "141026"
            },
            {
              "area": "浮山县",
              "code": "141027"
            },
            {
              "area": "吉县",
              "code": "141028"
            },
            {
              "area": "乡宁县",
              "code": "141029"
            },
            {
              "area": "大宁县",
              "code": "141030"
            },
            {
              "area": "隰县",
              "code": "141031"
            },
            {
              "area": "永和县",
              "code": "141032"
            },
            {
              "area": "蒲县",
              "code": "141033"
            },
            {
              "area": "汾西县",
              "code": "141034"
            },
            {
              "area": "侯马市",
              "code": "141081"
            },
            {
              "area": "霍州市",
              "code": "141082"
            }
          ]
        },
        {
          "province": "吕梁市",
          "code": "141100",
          "areas": [
            {
              "area": "离石区",
              "code": "141102"
            },
            {
              "area": "文水县",
              "code": "141121"
            },
            {
              "area": "交城县",
              "code": "141122"
            },
            {
              "area": "兴县",
              "code": "141123"
            },
            {
              "area": "临县",
              "code": "141124"
            },
            {
              "area": "柳林县",
              "code": "141125"
            },
            {
              "area": "石楼县",
              "code": "141126"
            },
            {
              "area": "岚县",
              "code": "141127"
            },
            {
              "area": "方山县",
              "code": "141128"
            },
            {
              "area": "中阳县",
              "code": "141129"
            },
            {
              "area": "交口县",
              "code": "141130"
            },
            {
              "area": "孝义市",
              "code": "141181"
            },
            {
              "area": "汾阳市",
              "code": "141182"
            }
          ]
        }
      ]
    },
    {
      "province": "内蒙古自治区",
      "code": "150000",
      "provinces": [
        {
          "province": "呼和浩特市",
          "code": "150100",
          "areas": [
            {
              "area": "新城区",
              "code": "150102"
            },
            {
              "area": "回民区",
              "code": "150103"
            },
            {
              "area": "玉泉区",
              "code": "150104"
            },
            {
              "area": "赛罕区",
              "code": "150105"
            },
            {
              "area": "土默特左旗",
              "code": "150121"
            },
            {
              "area": "托克托县",
              "code": "150122"
            },
            {
              "area": "和林格尔县",
              "code": "150123"
            },
            {
              "area": "清水河县",
              "code": "150124"
            },
            {
              "area": "武川县",
              "code": "150125"
            },
            {
              "area": "呼和浩特经济技术开发区",
              "code": "150172"
            }
          ]
        },
        {
          "province": "包头市",
          "code": "150200",
          "areas": [
            {
              "area": "东河区",
              "code": "150202"
            },
            {
              "area": "昆都仑区",
              "code": "150203"
            },
            {
              "area": "青山区",
              "code": "150204"
            },
            {
              "area": "石拐区",
              "code": "150205"
            },
            {
              "area": "白云鄂博矿区",
              "code": "150206"
            },
            {
              "area": "九原区",
              "code": "150207"
            },
            {
              "area": "土默特右旗",
              "code": "150221"
            },
            {
              "area": "固阳县",
              "code": "150222"
            },
            {
              "area": "达尔罕茂明安联合旗",
              "code": "150223"
            },
            {
              "area": "包头稀土高新技术产业开发区",
              "code": "150271"
            }
          ]
        },
        {
          "province": "乌海市",
          "code": "150300",
          "areas": [
            {
              "area": "海勃湾区",
              "code": "150302"
            },
            {
              "area": "海南区",
              "code": "150303"
            },
            {
              "area": "乌达区",
              "code": "150304"
            }
          ]
        },
        {
          "province": "赤峰市",
          "code": "150400",
          "areas": [
            {
              "area": "红山区",
              "code": "150402"
            },
            {
              "area": "元宝山区",
              "code": "150403"
            },
            {
              "area": "松山区",
              "code": "150404"
            },
            {
              "area": "阿鲁科尔沁旗",
              "code": "150421"
            },
            {
              "area": "巴林左旗",
              "code": "150422"
            },
            {
              "area": "巴林右旗",
              "code": "150423"
            },
            {
              "area": "林西县",
              "code": "150424"
            },
            {
              "area": "克什克腾旗",
              "code": "150425"
            },
            {
              "area": "翁牛特旗",
              "code": "150426"
            },
            {
              "area": "喀喇沁旗",
              "code": "150428"
            },
            {
              "area": "宁城县",
              "code": "150429"
            },
            {
              "area": "敖汉旗",
              "code": "150430"
            }
          ]
        },
        {
          "province": "通辽市",
          "code": "150500",
          "areas": [
            {
              "area": "科尔沁区",
              "code": "150502"
            },
            {
              "area": "科尔沁左翼中旗",
              "code": "150521"
            },
            {
              "area": "科尔沁左翼后旗",
              "code": "150522"
            },
            {
              "area": "开鲁县",
              "code": "150523"
            },
            {
              "area": "库伦旗",
              "code": "150524"
            },
            {
              "area": "奈曼旗",
              "code": "150525"
            },
            {
              "area": "扎鲁特旗",
              "code": "150526"
            },
            {
              "area": "通辽经济技术开发区",
              "code": "150571"
            },
            {
              "area": "霍林郭勒市",
              "code": "150581"
            }
          ]
        },
        {
          "province": "鄂尔多斯市",
          "code": "150600",
          "areas": [
            {
              "area": "东胜区",
              "code": "150602"
            },
            {
              "area": "康巴什区",
              "code": "150603"
            },
            {
              "area": "达拉特旗",
              "code": "150621"
            },
            {
              "area": "准格尔旗",
              "code": "150622"
            },
            {
              "area": "鄂托克前旗",
              "code": "150623"
            },
            {
              "area": "鄂托克旗",
              "code": "150624"
            },
            {
              "area": "杭锦旗",
              "code": "150625"
            },
            {
              "area": "乌审旗",
              "code": "150626"
            },
            {
              "area": "伊金霍洛旗",
              "code": "150627"
            }
          ]
        },
        {
          "province": "呼伦贝尔市",
          "code": "150700",
          "areas": [
            {
              "area": "海拉尔区",
              "code": "150702"
            },
            {
              "area": "扎赉诺尔区",
              "code": "150703"
            },
            {
              "area": "阿荣旗",
              "code": "150721"
            },
            {
              "area": "莫力达瓦达斡尔族自治旗",
              "code": "150722"
            },
            {
              "area": "鄂伦春自治旗",
              "code": "150723"
            },
            {
              "area": "鄂温克族自治旗",
              "code": "150724"
            },
            {
              "area": "陈巴尔虎旗",
              "code": "150725"
            },
            {
              "area": "新巴尔虎左旗",
              "code": "150726"
            },
            {
              "area": "新巴尔虎右旗",
              "code": "150727"
            },
            {
              "area": "满洲里市",
              "code": "150781"
            },
            {
              "area": "牙克石市",
              "code": "150782"
            },
            {
              "area": "扎兰屯市",
              "code": "150783"
            },
            {
              "area": "额尔古纳市",
              "code": "150784"
            },
            {
              "area": "根河市",
              "code": "150785"
            }
          ]
        },
        {
          "province": "巴彦淖尔市",
          "code": "150800",
          "areas": [
            {
              "area": "临河区",
              "code": "150802"
            },
            {
              "area": "五原县",
              "code": "150821"
            },
            {
              "area": "磴口县",
              "code": "150822"
            },
            {
              "area": "乌拉特前旗",
              "code": "150823"
            },
            {
              "area": "乌拉特中旗",
              "code": "150824"
            },
            {
              "area": "乌拉特后旗",
              "code": "150825"
            },
            {
              "area": "杭锦后旗",
              "code": "150826"
            }
          ]
        },
        {
          "province": "乌兰察布市",
          "code": "150900",
          "areas": [
            {
              "area": "集宁区",
              "code": "150902"
            },
            {
              "area": "卓资县",
              "code": "150921"
            },
            {
              "area": "化德县",
              "code": "150922"
            },
            {
              "area": "商都县",
              "code": "150923"
            },
            {
              "area": "兴和县",
              "code": "150924"
            },
            {
              "area": "凉城县",
              "code": "150925"
            },
            {
              "area": "察哈尔右翼前旗",
              "code": "150926"
            },
            {
              "area": "察哈尔右翼中旗",
              "code": "150927"
            },
            {
              "area": "察哈尔右翼后旗",
              "code": "150928"
            },
            {
              "area": "四子王旗",
              "code": "150929"
            },
            {
              "area": "丰镇市",
              "code": "150981"
            }
          ]
        },
        {
          "province": "兴安盟",
          "code": "152200",
          "areas": [
            {
              "area": "乌兰浩特市",
              "code": "152201"
            },
            {
              "area": "阿尔山市",
              "code": "152202"
            },
            {
              "area": "科尔沁右翼前旗",
              "code": "152221"
            },
            {
              "area": "科尔沁右翼中旗",
              "code": "152222"
            },
            {
              "area": "扎赉特旗",
              "code": "152223"
            },
            {
              "area": "突泉县",
              "code": "152224"
            }
          ]
        },
        {
          "province": "锡林郭勒盟",
          "code": "152500",
          "areas": [
            {
              "area": "二连浩特市",
              "code": "152501"
            },
            {
              "area": "锡林浩特市",
              "code": "152502"
            },
            {
              "area": "阿巴嘎旗",
              "code": "152522"
            },
            {
              "area": "苏尼特左旗",
              "code": "152523"
            },
            {
              "area": "苏尼特右旗",
              "code": "152524"
            },
            {
              "area": "东乌珠穆沁旗",
              "code": "152525"
            },
            {
              "area": "西乌珠穆沁旗",
              "code": "152526"
            },
            {
              "area": "太仆寺旗",
              "code": "152527"
            },
            {
              "area": "镶黄旗",
              "code": "152528"
            },
            {
              "area": "正镶白旗",
              "code": "152529"
            },
            {
              "area": "正蓝旗",
              "code": "152530"
            },
            {
              "area": "多伦县",
              "code": "152531"
            },
            {
              "area": "乌拉盖管理区管委会",
              "code": "152571"
            }
          ]
        },
        {
          "province": "阿拉善盟",
          "code": "152900",
          "areas": [
            {
              "area": "阿拉善左旗",
              "code": "152921"
            },
            {
              "area": "阿拉善右旗",
              "code": "152922"
            },
            {
              "area": "额济纳旗",
              "code": "152923"
            },
            {
              "area": "内蒙古阿拉善高新技术产业开发区",
              "code": "152971"
            }
          ]
        }
      ]
    },
    {
      "province": "辽宁省",
      "code": "210000",
      "provinces": [
        {
          "province": "沈阳市",
          "code": "210100",
          "areas": [
            {
              "area": "和平区",
              "code": "210102"
            },
            {
              "area": "沈河区",
              "code": "210103"
            },
            {
              "area": "大东区",
              "code": "210104"
            },
            {
              "area": "皇姑区",
              "code": "210105"
            },
            {
              "area": "铁西区",
              "code": "210106"
            },
            {
              "area": "苏家屯区",
              "code": "210111"
            },
            {
              "area": "浑南区",
              "code": "210112"
            },
            {
              "area": "沈北新区",
              "code": "210113"
            },
            {
              "area": "于洪区",
              "code": "210114"
            },
            {
              "area": "辽中区",
              "code": "210115"
            },
            {
              "area": "康平县",
              "code": "210123"
            },
            {
              "area": "法库县",
              "code": "210124"
            },
            {
              "area": "新民市",
              "code": "210181"
            }
          ]
        },
        {
          "province": "大连市",
          "code": "210200",
          "areas": [
            {
              "area": "中山区",
              "code": "210202"
            },
            {
              "area": "西岗区",
              "code": "210203"
            },
            {
              "area": "沙河口区",
              "code": "210204"
            },
            {
              "area": "甘井子区",
              "code": "210211"
            },
            {
              "area": "旅顺口区",
              "code": "210212"
            },
            {
              "area": "金州区",
              "code": "210213"
            },
            {
              "area": "普兰店区",
              "code": "210214"
            },
            {
              "area": "长海县",
              "code": "210224"
            },
            {
              "area": "瓦房店市",
              "code": "210281"
            },
            {
              "area": "庄河市",
              "code": "210283"
            }
          ]
        },
        {
          "province": "鞍山市",
          "code": "210300",
          "areas": [
            {
              "area": "铁东区",
              "code": "210302"
            },
            {
              "area": "铁西区",
              "code": "210303"
            },
            {
              "area": "立山区",
              "code": "210304"
            },
            {
              "area": "千山区",
              "code": "210311"
            },
            {
              "area": "台安县",
              "code": "210321"
            },
            {
              "area": "岫岩满族自治县",
              "code": "210323"
            },
            {
              "area": "海城市",
              "code": "210381"
            }
          ]
        },
        {
          "province": "抚顺市",
          "code": "210400",
          "areas": [
            {
              "area": "新抚区",
              "code": "210402"
            },
            {
              "area": "东洲区",
              "code": "210403"
            },
            {
              "area": "望花区",
              "code": "210404"
            },
            {
              "area": "顺城区",
              "code": "210411"
            },
            {
              "area": "抚顺县",
              "code": "210421"
            },
            {
              "area": "新宾满族自治县",
              "code": "210422"
            },
            {
              "area": "清原满族自治县",
              "code": "210423"
            }
          ]
        },
        {
          "province": "本溪市",
          "code": "210500",
          "areas": [
            {
              "area": "平山区",
              "code": "210502"
            },
            {
              "area": "溪湖区",
              "code": "210503"
            },
            {
              "area": "明山区",
              "code": "210504"
            },
            {
              "area": "南芬区",
              "code": "210505"
            },
            {
              "area": "本溪满族自治县",
              "code": "210521"
            },
            {
              "area": "桓仁满族自治县",
              "code": "210522"
            }
          ]
        },
        {
          "province": "丹东市",
          "code": "210600",
          "areas": [
            {
              "area": "元宝区",
              "code": "210602"
            },
            {
              "area": "振兴区",
              "code": "210603"
            },
            {
              "area": "振安区",
              "code": "210604"
            },
            {
              "area": "宽甸满族自治县",
              "code": "210624"
            },
            {
              "area": "东港市",
              "code": "210681"
            },
            {
              "area": "凤城市",
              "code": "210682"
            }
          ]
        },
        {
          "province": "锦州市",
          "code": "210700",
          "areas": [
            {
              "area": "古塔区",
              "code": "210702"
            },
            {
              "area": "凌河区",
              "code": "210703"
            },
            {
              "area": "太和区",
              "code": "210711"
            },
            {
              "area": "黑山县",
              "code": "210726"
            },
            {
              "area": "义县",
              "code": "210727"
            },
            {
              "area": "凌海市",
              "code": "210781"
            },
            {
              "area": "北镇市",
              "code": "210782"
            }
          ]
        },
        {
          "province": "营口市",
          "code": "210800",
          "areas": [
            {
              "area": "站前区",
              "code": "210802"
            },
            {
              "area": "西市区",
              "code": "210803"
            },
            {
              "area": "鲅鱼圈区",
              "code": "210804"
            },
            {
              "area": "老边区",
              "code": "210811"
            },
            {
              "area": "盖州市",
              "code": "210881"
            },
            {
              "area": "大石桥市",
              "code": "210882"
            }
          ]
        },
        {
          "province": "阜新市",
          "code": "210900",
          "areas": [
            {
              "area": "海州区",
              "code": "210902"
            },
            {
              "area": "新邱区",
              "code": "210903"
            },
            {
              "area": "太平区",
              "code": "210904"
            },
            {
              "area": "清河门区",
              "code": "210905"
            },
            {
              "area": "细河区",
              "code": "210911"
            },
            {
              "area": "阜新蒙古族自治县",
              "code": "210921"
            },
            {
              "area": "彰武县",
              "code": "210922"
            }
          ]
        },
        {
          "province": "辽阳市",
          "code": "211000",
          "areas": [
            {
              "area": "白塔区",
              "code": "211002"
            },
            {
              "area": "文圣区",
              "code": "211003"
            },
            {
              "area": "宏伟区",
              "code": "211004"
            },
            {
              "area": "弓长岭区",
              "code": "211005"
            },
            {
              "area": "太子河区",
              "code": "211011"
            },
            {
              "area": "辽阳县",
              "code": "211021"
            },
            {
              "area": "灯塔市",
              "code": "211081"
            }
          ]
        },
        {
          "province": "盘锦市",
          "code": "211100",
          "areas": [
            {
              "area": "双台子区",
              "code": "211102"
            },
            {
              "area": "兴隆台区",
              "code": "211103"
            },
            {
              "area": "大洼区",
              "code": "211104"
            },
            {
              "area": "盘山县",
              "code": "211122"
            }
          ]
        },
        {
          "province": "铁岭市",
          "code": "211200",
          "areas": [
            {
              "area": "银州区",
              "code": "211202"
            },
            {
              "area": "清河区",
              "code": "211204"
            },
            {
              "area": "铁岭县",
              "code": "211221"
            },
            {
              "area": "西丰县",
              "code": "211223"
            },
            {
              "area": "昌图县",
              "code": "211224"
            },
            {
              "area": "调兵山市",
              "code": "211281"
            },
            {
              "area": "开原市",
              "code": "211282"
            }
          ]
        },
        {
          "province": "朝阳市",
          "code": "211300",
          "areas": [
            {
              "area": "双塔区",
              "code": "211302"
            },
            {
              "area": "龙城区",
              "code": "211303"
            },
            {
              "area": "朝阳县",
              "code": "211321"
            },
            {
              "area": "建平县",
              "code": "211322"
            },
            {
              "area": "喀喇沁左翼蒙古族自治县",
              "code": "211324"
            },
            {
              "area": "北票市",
              "code": "211381"
            },
            {
              "area": "凌源市",
              "code": "211382"
            }
          ]
        },
        {
          "province": "葫芦岛市",
          "code": "211400",
          "areas": [
            {
              "area": "连山区",
              "code": "211402"
            },
            {
              "area": "龙港区",
              "code": "211403"
            },
            {
              "area": "南票区",
              "code": "211404"
            },
            {
              "area": "绥中县",
              "code": "211421"
            },
            {
              "area": "建昌县",
              "code": "211422"
            },
            {
              "area": "兴城市",
              "code": "211481"
            }
          ]
        }
      ]
    },
    {
      "province": "吉林省",
      "code": "220000",
      "provinces": [
        {
          "province": "长春市",
          "code": "220100",
          "areas": [
            {
              "area": "南关区",
              "code": "220102"
            },
            {
              "area": "宽城区",
              "code": "220103"
            },
            {
              "area": "朝阳区",
              "code": "220104"
            },
            {
              "area": "二道区",
              "code": "220105"
            },
            {
              "area": "绿园区",
              "code": "220106"
            },
            {
              "area": "双阳区",
              "code": "220112"
            },
            {
              "area": "九台区",
              "code": "220113"
            },
            {
              "area": "农安县",
              "code": "220122"
            },
            {
              "area": "长春经济技术开发区",
              "code": "220171"
            },
            {
              "area": "长春净月高新技术产业开发区",
              "code": "220172"
            },
            {
              "area": "长春高新技术产业开发区",
              "code": "220173"
            },
            {
              "area": "长春汽车经济技术开发区",
              "code": "220174"
            },
            {
              "area": "榆树市",
              "code": "220182"
            },
            {
              "area": "德惠市",
              "code": "220183"
            },
            {
              "area": "公主岭市",
              "code": "220184"
            }
          ]
        },
        {
          "province": "吉林市",
          "code": "220200",
          "areas": [
            {
              "area": "昌邑区",
              "code": "220202"
            },
            {
              "area": "龙潭区",
              "code": "220203"
            },
            {
              "area": "船营区",
              "code": "220204"
            },
            {
              "area": "丰满区",
              "code": "220211"
            },
            {
              "area": "永吉县",
              "code": "220221"
            },
            {
              "area": "吉林经济开发区",
              "code": "220271"
            },
            {
              "area": "吉林高新技术产业开发区",
              "code": "220272"
            },
            {
              "area": "吉林中国新加坡食品区",
              "code": "220273"
            },
            {
              "area": "蛟河市",
              "code": "220281"
            },
            {
              "area": "桦甸市",
              "code": "220282"
            },
            {
              "area": "舒兰市",
              "code": "220283"
            },
            {
              "area": "磐石市",
              "code": "220284"
            }
          ]
        },
        {
          "province": "四平市",
          "code": "220300",
          "areas": [
            {
              "area": "铁西区",
              "code": "220302"
            },
            {
              "area": "铁东区",
              "code": "220303"
            },
            {
              "area": "梨树县",
              "code": "220322"
            },
            {
              "area": "伊通满族自治县",
              "code": "220323"
            },
            {
              "area": "双辽市",
              "code": "220382"
            }
          ]
        },
        {
          "province": "辽源市",
          "code": "220400",
          "areas": [
            {
              "area": "龙山区",
              "code": "220402"
            },
            {
              "area": "西安区",
              "code": "220403"
            },
            {
              "area": "东丰县",
              "code": "220421"
            },
            {
              "area": "东辽县",
              "code": "220422"
            }
          ]
        },
        {
          "province": "通化市",
          "code": "220500",
          "areas": [
            {
              "area": "东昌区",
              "code": "220502"
            },
            {
              "area": "二道江区",
              "code": "220503"
            },
            {
              "area": "通化县",
              "code": "220521"
            },
            {
              "area": "辉南县",
              "code": "220523"
            },
            {
              "area": "柳河县",
              "code": "220524"
            },
            {
              "area": "梅河口市",
              "code": "220581"
            },
            {
              "area": "集安市",
              "code": "220582"
            }
          ]
        },
        {
          "province": "白山市",
          "code": "220600",
          "areas": [
            {
              "area": "浑江区",
              "code": "220602"
            },
            {
              "area": "江源区",
              "code": "220605"
            },
            {
              "area": "抚松县",
              "code": "220621"
            },
            {
              "area": "靖宇县",
              "code": "220622"
            },
            {
              "area": "长白朝鲜族自治县",
              "code": "220623"
            },
            {
              "area": "临江市",
              "code": "220681"
            }
          ]
        },
        {
          "province": "松原市",
          "code": "220700",
          "areas": [
            {
              "area": "宁江区",
              "code": "220702"
            },
            {
              "area": "前郭尔罗斯蒙古族自治县",
              "code": "220721"
            },
            {
              "area": "长岭县",
              "code": "220722"
            },
            {
              "area": "乾安县",
              "code": "220723"
            },
            {
              "area": "吉林松原经济开发区",
              "code": "220771"
            },
            {
              "area": "扶余市",
              "code": "220781"
            }
          ]
        },
        {
          "province": "白城市",
          "code": "220800",
          "areas": [
            {
              "area": "洮北区",
              "code": "220802"
            },
            {
              "area": "镇赉县",
              "code": "220821"
            },
            {
              "area": "通榆县",
              "code": "220822"
            },
            {
              "area": "吉林白城经济开发区",
              "code": "220871"
            },
            {
              "area": "洮南市",
              "code": "220881"
            },
            {
              "area": "大安市",
              "code": "220882"
            }
          ]
        },
        {
          "province": "延边朝鲜族自治州",
          "code": "222400",
          "areas": [
            {
              "area": "延吉市",
              "code": "222401"
            },
            {
              "area": "图们市",
              "code": "222402"
            },
            {
              "area": "敦化市",
              "code": "222403"
            },
            {
              "area": "珲春市",
              "code": "222404"
            },
            {
              "area": "龙井市",
              "code": "222405"
            },
            {
              "area": "和龙市",
              "code": "222406"
            },
            {
              "area": "汪清县",
              "code": "222424"
            },
            {
              "area": "安图县",
              "code": "222426"
            }
          ]
        }
      ]
    },
    {
      "province": "黑龙江省",
      "code": "230000",
      "provinces": [
        {
          "province": "哈尔滨市",
          "code": "230100",
          "areas": [
            {
              "area": "道里区",
              "code": "230102"
            },
            {
              "area": "南岗区",
              "code": "230103"
            },
            {
              "area": "道外区",
              "code": "230104"
            },
            {
              "area": "平房区",
              "code": "230108"
            },
            {
              "area": "松北区",
              "code": "230109"
            },
            {
              "area": "香坊区",
              "code": "230110"
            },
            {
              "area": "呼兰区",
              "code": "230111"
            },
            {
              "area": "阿城区",
              "code": "230112"
            },
            {
              "area": "双城区",
              "code": "230113"
            },
            {
              "area": "依兰县",
              "code": "230123"
            },
            {
              "area": "方正县",
              "code": "230124"
            },
            {
              "area": "宾县",
              "code": "230125"
            },
            {
              "area": "巴彦县",
              "code": "230126"
            },
            {
              "area": "木兰县",
              "code": "230127"
            },
            {
              "area": "通河县",
              "code": "230128"
            },
            {
              "area": "延寿县",
              "code": "230129"
            },
            {
              "area": "尚志市",
              "code": "230183"
            },
            {
              "area": "五常市",
              "code": "230184"
            }
          ]
        },
        {
          "province": "齐齐哈尔市",
          "code": "230200",
          "areas": [
            {
              "area": "龙沙区",
              "code": "230202"
            },
            {
              "area": "建华区",
              "code": "230203"
            },
            {
              "area": "铁锋区",
              "code": "230204"
            },
            {
              "area": "昂昂溪区",
              "code": "230205"
            },
            {
              "area": "富拉尔基区",
              "code": "230206"
            },
            {
              "area": "碾子山区",
              "code": "230207"
            },
            {
              "area": "梅里斯达斡尔族区",
              "code": "230208"
            },
            {
              "area": "龙江县",
              "code": "230221"
            },
            {
              "area": "依安县",
              "code": "230223"
            },
            {
              "area": "泰来县",
              "code": "230224"
            },
            {
              "area": "甘南县",
              "code": "230225"
            },
            {
              "area": "富裕县",
              "code": "230227"
            },
            {
              "area": "克山县",
              "code": "230229"
            },
            {
              "area": "克东县",
              "code": "230230"
            },
            {
              "area": "拜泉县",
              "code": "230231"
            },
            {
              "area": "讷河市",
              "code": "230281"
            }
          ]
        },
        {
          "province": "鸡西市",
          "code": "230300",
          "areas": [
            {
              "area": "鸡冠区",
              "code": "230302"
            },
            {
              "area": "恒山区",
              "code": "230303"
            },
            {
              "area": "滴道区",
              "code": "230304"
            },
            {
              "area": "梨树区",
              "code": "230305"
            },
            {
              "area": "城子河区",
              "code": "230306"
            },
            {
              "area": "麻山区",
              "code": "230307"
            },
            {
              "area": "鸡东县",
              "code": "230321"
            },
            {
              "area": "虎林市",
              "code": "230381"
            },
            {
              "area": "密山市",
              "code": "230382"
            }
          ]
        },
        {
          "province": "鹤岗市",
          "code": "230400",
          "areas": [
            {
              "area": "向阳区",
              "code": "230402"
            },
            {
              "area": "工农区",
              "code": "230403"
            },
            {
              "area": "南山区",
              "code": "230404"
            },
            {
              "area": "兴安区",
              "code": "230405"
            },
            {
              "area": "东山区",
              "code": "230406"
            },
            {
              "area": "兴山区",
              "code": "230407"
            },
            {
              "area": "萝北县",
              "code": "230421"
            },
            {
              "area": "绥滨县",
              "code": "230422"
            }
          ]
        },
        {
          "province": "双鸭山市",
          "code": "230500",
          "areas": [
            {
              "area": "尖山区",
              "code": "230502"
            },
            {
              "area": "岭东区",
              "code": "230503"
            },
            {
              "area": "四方台区",
              "code": "230505"
            },
            {
              "area": "宝山区",
              "code": "230506"
            },
            {
              "area": "集贤县",
              "code": "230521"
            },
            {
              "area": "友谊县",
              "code": "230522"
            },
            {
              "area": "宝清县",
              "code": "230523"
            },
            {
              "area": "饶河县",
              "code": "230524"
            }
          ]
        },
        {
          "province": "大庆市",
          "code": "230600",
          "areas": [
            {
              "area": "萨尔图区",
              "code": "230602"
            },
            {
              "area": "龙凤区",
              "code": "230603"
            },
            {
              "area": "让胡路区",
              "code": "230604"
            },
            {
              "area": "红岗区",
              "code": "230605"
            },
            {
              "area": "大同区",
              "code": "230606"
            },
            {
              "area": "肇州县",
              "code": "230621"
            },
            {
              "area": "肇源县",
              "code": "230622"
            },
            {
              "area": "林甸县",
              "code": "230623"
            },
            {
              "area": "杜尔伯特蒙古族自治县",
              "code": "230624"
            },
            {
              "area": "大庆高新技术产业开发区",
              "code": "230671"
            }
          ]
        },
        {
          "province": "伊春市",
          "code": "230700",
          "areas": [
            {
              "area": "伊美区",
              "code": "230717"
            },
            {
              "area": "乌翠区",
              "code": "230718"
            },
            {
              "area": "友好区",
              "code": "230719"
            },
            {
              "area": "嘉荫县",
              "code": "230722"
            },
            {
              "area": "汤旺县",
              "code": "230723"
            },
            {
              "area": "丰林县",
              "code": "230724"
            },
            {
              "area": "大箐山县",
              "code": "230725"
            },
            {
              "area": "南岔县",
              "code": "230726"
            },
            {
              "area": "金林区",
              "code": "230751"
            },
            {
              "area": "铁力市",
              "code": "230781"
            }
          ]
        },
        {
          "province": "佳木斯市",
          "code": "230800",
          "areas": [
            {
              "area": "向阳区",
              "code": "230803"
            },
            {
              "area": "前进区",
              "code": "230804"
            },
            {
              "area": "东风区",
              "code": "230805"
            },
            {
              "area": "郊区",
              "code": "230811"
            },
            {
              "area": "桦南县",
              "code": "230822"
            },
            {
              "area": "桦川县",
              "code": "230826"
            },
            {
              "area": "汤原县",
              "code": "230828"
            },
            {
              "area": "同江市",
              "code": "230881"
            },
            {
              "area": "富锦市",
              "code": "230882"
            },
            {
              "area": "抚远市",
              "code": "230883"
            }
          ]
        },
        {
          "province": "七台河市",
          "code": "230900",
          "areas": [
            {
              "area": "新兴区",
              "code": "230902"
            },
            {
              "area": "桃山区",
              "code": "230903"
            },
            {
              "area": "茄子河区",
              "code": "230904"
            },
            {
              "area": "勃利县",
              "code": "230921"
            }
          ]
        },
        {
          "province": "牡丹江市",
          "code": "231000",
          "areas": [
            {
              "area": "东安区",
              "code": "231002"
            },
            {
              "area": "阳明区",
              "code": "231003"
            },
            {
              "area": "爱民区",
              "code": "231004"
            },
            {
              "area": "西安区",
              "code": "231005"
            },
            {
              "area": "林口县",
              "code": "231025"
            },
            {
              "area": "绥芬河市",
              "code": "231081"
            },
            {
              "area": "海林市",
              "code": "231083"
            },
            {
              "area": "宁安市",
              "code": "231084"
            },
            {
              "area": "穆棱市",
              "code": "231085"
            },
            {
              "area": "东宁市",
              "code": "231086"
            }
          ]
        },
        {
          "province": "黑河市",
          "code": "231100",
          "areas": [
            {
              "area": "爱辉区",
              "code": "231102"
            },
            {
              "area": "逊克县",
              "code": "231123"
            },
            {
              "area": "孙吴县",
              "code": "231124"
            },
            {
              "area": "北安市",
              "code": "231181"
            },
            {
              "area": "五大连池市",
              "code": "231182"
            },
            {
              "area": "嫩江市",
              "code": "231183"
            }
          ]
        },
        {
          "province": "绥化市",
          "code": "231200",
          "areas": [
            {
              "area": "北林区",
              "code": "231202"
            },
            {
              "area": "望奎县",
              "code": "231221"
            },
            {
              "area": "兰西县",
              "code": "231222"
            },
            {
              "area": "青冈县",
              "code": "231223"
            },
            {
              "area": "庆安县",
              "code": "231224"
            },
            {
              "area": "明水县",
              "code": "231225"
            },
            {
              "area": "绥棱县",
              "code": "231226"
            },
            {
              "area": "安达市",
              "code": "231281"
            },
            {
              "area": "肇东市",
              "code": "231282"
            },
            {
              "area": "海伦市",
              "code": "231283"
            }
          ]
        },
        {
          "province": "大兴安岭地区",
          "code": "232700",
          "areas": [
            {
              "area": "漠河市",
              "code": "232701"
            },
            {
              "area": "呼玛县",
              "code": "232721"
            },
            {
              "area": "塔河县",
              "code": "232722"
            },
            {
              "area": "加格达奇区",
              "code": "232761"
            },
            {
              "area": "松岭区",
              "code": "232762"
            },
            {
              "area": "新林区",
              "code": "232763"
            },
            {
              "area": "呼中区",
              "code": "232764"
            }
          ]
        }
      ]
    },
    {
      "province": "上海市",
      "code": "310000",
      "provinces": [
        {
          "province": "上海市",
          "code": "310100",
          "areas": [
            {
              "area": "黄浦区",
              "code": "310101"
            },
            {
              "area": "徐汇区",
              "code": "310104"
            },
            {
              "area": "长宁区",
              "code": "310105"
            },
            {
              "area": "静安区",
              "code": "310106"
            },
            {
              "area": "普陀区",
              "code": "310107"
            },
            {
              "area": "虹口区",
              "code": "310109"
            },
            {
              "area": "杨浦区",
              "code": "310110"
            },
            {
              "area": "闵行区",
              "code": "310112"
            },
            {
              "area": "宝山区",
              "code": "310113"
            },
            {
              "area": "嘉定区",
              "code": "310114"
            },
            {
              "area": "浦东新区",
              "code": "310115"
            },
            {
              "area": "金山区",
              "code": "310116"
            },
            {
              "area": "松江区",
              "code": "310117"
            },
            {
              "area": "青浦区",
              "code": "310118"
            },
            {
              "area": "奉贤区",
              "code": "310120"
            },
            {
              "area": "崇明区",
              "code": "310151"
            }
          ]
        }
      ]
    },
    {
      "province": "江苏省",
      "code": "320000",
      "provinces": [
        {
          "province": "南京市",
          "code": "320100",
          "areas": [
            {
              "area": "玄武区",
              "code": "320102"
            },
            {
              "area": "秦淮区",
              "code": "320104"
            },
            {
              "area": "建邺区",
              "code": "320105"
            },
            {
              "area": "鼓楼区",
              "code": "320106"
            },
            {
              "area": "浦口区",
              "code": "320111"
            },
            {
              "area": "栖霞区",
              "code": "320113"
            },
            {
              "area": "雨花台区",
              "code": "320114"
            },
            {
              "area": "江宁区",
              "code": "320115"
            },
            {
              "area": "六合区",
              "code": "320116"
            },
            {
              "area": "溧水区",
              "code": "320117"
            },
            {
              "area": "高淳区",
              "code": "320118"
            }
          ]
        },
        {
          "province": "无锡市",
          "code": "320200",
          "areas": [
            {
              "area": "锡山区",
              "code": "320205"
            },
            {
              "area": "惠山区",
              "code": "320206"
            },
            {
              "area": "滨湖区",
              "code": "320211"
            },
            {
              "area": "梁溪区",
              "code": "320213"
            },
            {
              "area": "新吴区",
              "code": "320214"
            },
            {
              "area": "江阴市",
              "code": "320281"
            },
            {
              "area": "宜兴市",
              "code": "320282"
            }
          ]
        },
        {
          "province": "徐州市",
          "code": "320300",
          "areas": [
            {
              "area": "鼓楼区",
              "code": "320302"
            },
            {
              "area": "云龙区",
              "code": "320303"
            },
            {
              "area": "贾汪区",
              "code": "320305"
            },
            {
              "area": "泉山区",
              "code": "320311"
            },
            {
              "area": "铜山区",
              "code": "320312"
            },
            {
              "area": "丰县",
              "code": "320321"
            },
            {
              "area": "沛县",
              "code": "320322"
            },
            {
              "area": "睢宁县",
              "code": "320324"
            },
            {
              "area": "徐州经济技术开发区",
              "code": "320371"
            },
            {
              "area": "新沂市",
              "code": "320381"
            },
            {
              "area": "邳州市",
              "code": "320382"
            }
          ]
        },
        {
          "province": "常州市",
          "code": "320400",
          "areas": [
            {
              "area": "天宁区",
              "code": "320402"
            },
            {
              "area": "钟楼区",
              "code": "320404"
            },
            {
              "area": "新北区",
              "code": "320411"
            },
            {
              "area": "武进区",
              "code": "320412"
            },
            {
              "area": "金坛区",
              "code": "320413"
            },
            {
              "area": "溧阳市",
              "code": "320481"
            }
          ]
        },
        {
          "province": "苏州市",
          "code": "320500",
          "areas": [
            {
              "area": "虎丘区",
              "code": "320505"
            },
            {
              "area": "吴中区",
              "code": "320506"
            },
            {
              "area": "相城区",
              "code": "320507"
            },
            {
              "area": "姑苏区",
              "code": "320508"
            },
            {
              "area": "吴江区",
              "code": "320509"
            },
            {
              "area": "苏州工业园区",
              "code": "320576"
            },
            {
              "area": "常熟市",
              "code": "320581"
            },
            {
              "area": "张家港市",
              "code": "320582"
            },
            {
              "area": "昆山市",
              "code": "320583"
            },
            {
              "area": "太仓市",
              "code": "320585"
            }
          ]
        },
        {
          "province": "南通市",
          "code": "320600",
          "areas": [
            {
              "area": "通州区",
              "code": "320612"
            },
            {
              "area": "崇川区",
              "code": "320613"
            },
            {
              "area": "海门区",
              "code": "320614"
            },
            {
              "area": "如东县",
              "code": "320623"
            },
            {
              "area": "南通经济技术开发区",
              "code": "320671"
            },
            {
              "area": "启东市",
              "code": "320681"
            },
            {
              "area": "如皋市",
              "code": "320682"
            },
            {
              "area": "海安市",
              "code": "320685"
            }
          ]
        },
        {
          "province": "连云港市",
          "code": "320700",
          "areas": [
            {
              "area": "连云区",
              "code": "320703"
            },
            {
              "area": "海州区",
              "code": "320706"
            },
            {
              "area": "赣榆区",
              "code": "320707"
            },
            {
              "area": "东海县",
              "code": "320722"
            },
            {
              "area": "灌云县",
              "code": "320723"
            },
            {
              "area": "灌南县",
              "code": "320724"
            },
            {
              "area": "连云港经济技术开发区",
              "code": "320771"
            }
          ]
        },
        {
          "province": "淮安市",
          "code": "320800",
          "areas": [
            {
              "area": "淮安区",
              "code": "320803"
            },
            {
              "area": "淮阴区",
              "code": "320804"
            },
            {
              "area": "清江浦区",
              "code": "320812"
            },
            {
              "area": "洪泽区",
              "code": "320813"
            },
            {
              "area": "涟水县",
              "code": "320826"
            },
            {
              "area": "盱眙县",
              "code": "320830"
            },
            {
              "area": "金湖县",
              "code": "320831"
            },
            {
              "area": "淮安经济技术开发区",
              "code": "320871"
            }
          ]
        },
        {
          "province": "盐城市",
          "code": "320900",
          "areas": [
            {
              "area": "亭湖区",
              "code": "320902"
            },
            {
              "area": "盐都区",
              "code": "320903"
            },
            {
              "area": "大丰区",
              "code": "320904"
            },
            {
              "area": "响水县",
              "code": "320921"
            },
            {
              "area": "滨海县",
              "code": "320922"
            },
            {
              "area": "阜宁县",
              "code": "320923"
            },
            {
              "area": "射阳县",
              "code": "320924"
            },
            {
              "area": "建湖县",
              "code": "320925"
            },
            {
              "area": "盐城经济技术开发区",
              "code": "320971"
            },
            {
              "area": "东台市",
              "code": "320981"
            }
          ]
        },
        {
          "province": "扬州市",
          "code": "321000",
          "areas": [
            {
              "area": "广陵区",
              "code": "321002"
            },
            {
              "area": "邗江区",
              "code": "321003"
            },
            {
              "area": "江都区",
              "code": "321012"
            },
            {
              "area": "宝应县",
              "code": "321023"
            },
            {
              "area": "扬州经济技术开发区",
              "code": "321071"
            },
            {
              "area": "仪征市",
              "code": "321081"
            },
            {
              "area": "高邮市",
              "code": "321084"
            }
          ]
        },
        {
          "province": "镇江市",
          "code": "321100",
          "areas": [
            {
              "area": "京口区",
              "code": "321102"
            },
            {
              "area": "润州区",
              "code": "321111"
            },
            {
              "area": "丹徒区",
              "code": "321112"
            },
            {
              "area": "镇江新区",
              "code": "321171"
            },
            {
              "area": "丹阳市",
              "code": "321181"
            },
            {
              "area": "扬中市",
              "code": "321182"
            },
            {
              "area": "句容市",
              "code": "321183"
            }
          ]
        },
        {
          "province": "泰州市",
          "code": "321200",
          "areas": [
            {
              "area": "海陵区",
              "code": "321202"
            },
            {
              "area": "高港区",
              "code": "321203"
            },
            {
              "area": "姜堰区",
              "code": "321204"
            },
            {
              "area": "兴化市",
              "code": "321281"
            },
            {
              "area": "靖江市",
              "code": "321282"
            },
            {
              "area": "泰兴市",
              "code": "321283"
            }
          ]
        },
        {
          "province": "宿迁市",
          "code": "321300",
          "areas": [
            {
              "area": "宿城区",
              "code": "321302"
            },
            {
              "area": "宿豫区",
              "code": "321311"
            },
            {
              "area": "沭阳县",
              "code": "321322"
            },
            {
              "area": "泗阳县",
              "code": "321323"
            },
            {
              "area": "泗洪县",
              "code": "321324"
            },
            {
              "area": "宿迁经济技术开发区",
              "code": "321371"
            }
          ]
        }
      ]
    },
    {
      "province": "浙江省",
      "code": "330000",
      "provinces": [
        {
          "province": "杭州市",
          "code": "330100",
          "areas": [
            {
              "area": "上城区",
              "code": "330102"
            },
            {
              "area": "拱墅区",
              "code": "330105"
            },
            {
              "area": "西湖区",
              "code": "330106"
            },
            {
              "area": "滨江区",
              "code": "330108"
            },
            {
              "area": "萧山区",
              "code": "330109"
            },
            {
              "area": "余杭区",
              "code": "330110"
            },
            {
              "area": "富阳区",
              "code": "330111"
            },
            {
              "area": "临安区",
              "code": "330112"
            },
            {
              "area": "临平区",
              "code": "330113"
            },
            {
              "area": "钱塘区",
              "code": "330114"
            },
            {
              "area": "桐庐县",
              "code": "330122"
            },
            {
              "area": "淳安县",
              "code": "330127"
            },
            {
              "area": "建德市",
              "code": "330182"
            }
          ]
        },
        {
          "province": "宁波市",
          "code": "330200",
          "areas": [
            {
              "area": "海曙区",
              "code": "330203"
            },
            {
              "area": "江北区",
              "code": "330205"
            },
            {
              "area": "北仑区",
              "code": "330206"
            },
            {
              "area": "镇海区",
              "code": "330211"
            },
            {
              "area": "鄞州区",
              "code": "330212"
            },
            {
              "area": "奉化区",
              "code": "330213"
            },
            {
              "area": "象山县",
              "code": "330225"
            },
            {
              "area": "宁海县",
              "code": "330226"
            },
            {
              "area": "余姚市",
              "code": "330281"
            },
            {
              "area": "慈溪市",
              "code": "330282"
            }
          ]
        },
        {
          "province": "温州市",
          "code": "330300",
          "areas": [
            {
              "area": "鹿城区",
              "code": "330302"
            },
            {
              "area": "龙湾区",
              "code": "330303"
            },
            {
              "area": "瓯海区",
              "code": "330304"
            },
            {
              "area": "洞头区",
              "code": "330305"
            },
            {
              "area": "永嘉县",
              "code": "330324"
            },
            {
              "area": "平阳县",
              "code": "330326"
            },
            {
              "area": "苍南县",
              "code": "330327"
            },
            {
              "area": "文成县",
              "code": "330328"
            },
            {
              "area": "泰顺县",
              "code": "330329"
            },
            {
              "area": "瑞安市",
              "code": "330381"
            },
            {
              "area": "乐清市",
              "code": "330382"
            },
            {
              "area": "龙港市",
              "code": "330383"
            }
          ]
        },
        {
          "province": "嘉兴市",
          "code": "330400",
          "areas": [
            {
              "area": "南湖区",
              "code": "330402"
            },
            {
              "area": "秀洲区",
              "code": "330411"
            },
            {
              "area": "嘉善县",
              "code": "330421"
            },
            {
              "area": "海盐县",
              "code": "330424"
            },
            {
              "area": "海宁市",
              "code": "330481"
            },
            {
              "area": "平湖市",
              "code": "330482"
            },
            {
              "area": "桐乡市",
              "code": "330483"
            }
          ]
        },
        {
          "province": "湖州市",
          "code": "330500",
          "areas": [
            {
              "area": "吴兴区",
              "code": "330502"
            },
            {
              "area": "南浔区",
              "code": "330503"
            },
            {
              "area": "德清县",
              "code": "330521"
            },
            {
              "area": "长兴县",
              "code": "330522"
            },
            {
              "area": "安吉县",
              "code": "330523"
            }
          ]
        },
        {
          "province": "绍兴市",
          "code": "330600",
          "areas": [
            {
              "area": "越城区",
              "code": "330602"
            },
            {
              "area": "柯桥区",
              "code": "330603"
            },
            {
              "area": "上虞区",
              "code": "330604"
            },
            {
              "area": "新昌县",
              "code": "330624"
            },
            {
              "area": "诸暨市",
              "code": "330681"
            },
            {
              "area": "嵊州市",
              "code": "330683"
            }
          ]
        },
        {
          "province": "金华市",
          "code": "330700",
          "areas": [
            {
              "area": "婺城区",
              "code": "330702"
            },
            {
              "area": "金东区",
              "code": "330703"
            },
            {
              "area": "武义县",
              "code": "330723"
            },
            {
              "area": "浦江县",
              "code": "330726"
            },
            {
              "area": "磐安县",
              "code": "330727"
            },
            {
              "area": "兰溪市",
              "code": "330781"
            },
            {
              "area": "义乌市",
              "code": "330782"
            },
            {
              "area": "东阳市",
              "code": "330783"
            },
            {
              "area": "永康市",
              "code": "330784"
            }
          ]
        },
        {
          "province": "衢州市",
          "code": "330800",
          "areas": [
            {
              "area": "柯城区",
              "code": "330802"
            },
            {
              "area": "衢江区",
              "code": "330803"
            },
            {
              "area": "常山县",
              "code": "330822"
            },
            {
              "area": "开化县",
              "code": "330824"
            },
            {
              "area": "龙游县",
              "code": "330825"
            },
            {
              "area": "江山市",
              "code": "330881"
            }
          ]
        },
        {
          "province": "舟山市",
          "code": "330900",
          "areas": [
            {
              "area": "定海区",
              "code": "330902"
            },
            {
              "area": "普陀区",
              "code": "330903"
            },
            {
              "area": "岱山县",
              "code": "330921"
            },
            {
              "area": "嵊泗县",
              "code": "330922"
            }
          ]
        },
        {
          "province": "台州市",
          "code": "331000",
          "areas": [
            {
              "area": "椒江区",
              "code": "331002"
            },
            {
              "area": "黄岩区",
              "code": "331003"
            },
            {
              "area": "路桥区",
              "code": "331004"
            },
            {
              "area": "三门县",
              "code": "331022"
            },
            {
              "area": "天台县",
              "code": "331023"
            },
            {
              "area": "仙居县",
              "code": "331024"
            },
            {
              "area": "温岭市",
              "code": "331081"
            },
            {
              "area": "临海市",
              "code": "331082"
            },
            {
              "area": "玉环市",
              "code": "331083"
            }
          ]
        },
        {
          "province": "丽水市",
          "code": "331100",
          "areas": [
            {
              "area": "莲都区",
              "code": "331102"
            },
            {
              "area": "青田县",
              "code": "331121"
            },
            {
              "area": "缙云县",
              "code": "331122"
            },
            {
              "area": "遂昌县",
              "code": "331123"
            },
            {
              "area": "松阳县",
              "code": "331124"
            },
            {
              "area": "云和县",
              "code": "331125"
            },
            {
              "area": "庆元县",
              "code": "331126"
            },
            {
              "area": "景宁畲族自治县",
              "code": "331127"
            },
            {
              "area": "龙泉市",
              "code": "331181"
            }
          ]
        }
      ]
    },
    {
      "province": "安徽省",
      "code": "340000",
      "provinces": [
        {
          "province": "合肥市",
          "code": "340100",
          "areas": [
            {
              "area": "瑶海区",
              "code": "340102"
            },
            {
              "area": "庐阳区",
              "code": "340103"
            },
            {
              "area": "蜀山区",
              "code": "340104"
            },
            {
              "area": "包河区",
              "code": "340111"
            },
            {
              "area": "长丰县",
              "code": "340121"
            },
            {
              "area": "肥东县",
              "code": "340122"
            },
            {
              "area": "肥西县",
              "code": "340123"
            },
            {
              "area": "庐江县",
              "code": "340124"
            },
            {
              "area": "合肥高新技术产业开发区",
              "code": "340176"
            },
            {
              "area": "合肥经济技术开发区",
              "code": "340177"
            },
            {
              "area": "合肥新站高新技术产业开发区",
              "code": "340178"
            },
            {
              "area": "巢湖市",
              "code": "340181"
            }
          ]
        },
        {
          "province": "芜湖市",
          "code": "340200",
          "areas": [
            {
              "area": "镜湖区",
              "code": "340202"
            },
            {
              "area": "鸠江区",
              "code": "340207"
            },
            {
              "area": "弋江区",
              "code": "340209"
            },
            {
              "area": "湾沚区",
              "code": "340210"
            },
            {
              "area": "繁昌区",
              "code": "340212"
            },
            {
              "area": "南陵县",
              "code": "340223"
            },
            {
              "area": "芜湖经济技术开发区",
              "code": "340271"
            },
            {
              "area": "安徽芜湖三山经济开发区",
              "code": "340272"
            },
            {
              "area": "无为市",
              "code": "340281"
            }
          ]
        },
        {
          "province": "蚌埠市",
          "code": "340300",
          "areas": [
            {
              "area": "龙子湖区",
              "code": "340302"
            },
            {
              "area": "蚌山区",
              "code": "340303"
            },
            {
              "area": "禹会区",
              "code": "340304"
            },
            {
              "area": "淮上区",
              "code": "340311"
            },
            {
              "area": "怀远县",
              "code": "340321"
            },
            {
              "area": "五河县",
              "code": "340322"
            },
            {
              "area": "固镇县",
              "code": "340323"
            },
            {
              "area": "蚌埠市高新技术开发区",
              "code": "340371"
            },
            {
              "area": "蚌埠市经济开发区",
              "code": "340372"
            }
          ]
        },
        {
          "province": "淮南市",
          "code": "340400",
          "areas": [
            {
              "area": "大通区",
              "code": "340402"
            },
            {
              "area": "田家庵区",
              "code": "340403"
            },
            {
              "area": "谢家集区",
              "code": "340404"
            },
            {
              "area": "八公山区",
              "code": "340405"
            },
            {
              "area": "潘集区",
              "code": "340406"
            },
            {
              "area": "凤台县",
              "code": "340421"
            },
            {
              "area": "寿县",
              "code": "340422"
            }
          ]
        },
        {
          "province": "马鞍山市",
          "code": "340500",
          "areas": [
            {
              "area": "花山区",
              "code": "340503"
            },
            {
              "area": "雨山区",
              "code": "340504"
            },
            {
              "area": "博望区",
              "code": "340506"
            },
            {
              "area": "当涂县",
              "code": "340521"
            },
            {
              "area": "含山县",
              "code": "340522"
            },
            {
              "area": "和县",
              "code": "340523"
            }
          ]
        },
        {
          "province": "淮北市",
          "code": "340600",
          "areas": [
            {
              "area": "杜集区",
              "code": "340602"
            },
            {
              "area": "相山区",
              "code": "340603"
            },
            {
              "area": "烈山区",
              "code": "340604"
            },
            {
              "area": "濉溪县",
              "code": "340621"
            }
          ]
        },
        {
          "province": "铜陵市",
          "code": "340700",
          "areas": [
            {
              "area": "铜官区",
              "code": "340705"
            },
            {
              "area": "义安区",
              "code": "340706"
            },
            {
              "area": "郊区",
              "code": "340711"
            },
            {
              "area": "枞阳县",
              "code": "340722"
            }
          ]
        },
        {
          "province": "安庆市",
          "code": "340800",
          "areas": [
            {
              "area": "迎江区",
              "code": "340802"
            },
            {
              "area": "大观区",
              "code": "340803"
            },
            {
              "area": "宜秀区",
              "code": "340811"
            },
            {
              "area": "怀宁县",
              "code": "340822"
            },
            {
              "area": "太湖县",
              "code": "340825"
            },
            {
              "area": "宿松县",
              "code": "340826"
            },
            {
              "area": "望江县",
              "code": "340827"
            },
            {
              "area": "岳西县",
              "code": "340828"
            },
            {
              "area": "安徽安庆经济开发区",
              "code": "340871"
            },
            {
              "area": "桐城市",
              "code": "340881"
            },
            {
              "area": "潜山市",
              "code": "340882"
            }
          ]
        },
        {
          "province": "黄山市",
          "code": "341000",
          "areas": [
            {
              "area": "屯溪区",
              "code": "341002"
            },
            {
              "area": "黄山区",
              "code": "341003"
            },
            {
              "area": "徽州区",
              "code": "341004"
            },
            {
              "area": "歙县",
              "code": "341021"
            },
            {
              "area": "休宁县",
              "code": "341022"
            },
            {
              "area": "黟县",
              "code": "341023"
            },
            {
              "area": "祁门县",
              "code": "341024"
            }
          ]
        },
        {
          "province": "滁州市",
          "code": "341100",
          "areas": [
            {
              "area": "琅琊区",
              "code": "341102"
            },
            {
              "area": "南谯区",
              "code": "341103"
            },
            {
              "area": "来安县",
              "code": "341122"
            },
            {
              "area": "全椒县",
              "code": "341124"
            },
            {
              "area": "定远县",
              "code": "341125"
            },
            {
              "area": "凤阳县",
              "code": "341126"
            },
            {
              "area": "中新苏滁高新技术产业开发区",
              "code": "341171"
            },
            {
              "area": "滁州经济技术开发区",
              "code": "341172"
            },
            {
              "area": "天长市",
              "code": "341181"
            },
            {
              "area": "明光市",
              "code": "341182"
            }
          ]
        },
        {
          "province": "阜阳市",
          "code": "341200",
          "areas": [
            {
              "area": "颍州区",
              "code": "341202"
            },
            {
              "area": "颍东区",
              "code": "341203"
            },
            {
              "area": "颍泉区",
              "code": "341204"
            },
            {
              "area": "临泉县",
              "code": "341221"
            },
            {
              "area": "太和县",
              "code": "341222"
            },
            {
              "area": "阜南县",
              "code": "341225"
            },
            {
              "area": "颍上县",
              "code": "341226"
            },
            {
              "area": "阜阳合肥现代产业园区",
              "code": "341271"
            },
            {
              "area": "阜阳经济技术开发区",
              "code": "341272"
            },
            {
              "area": "界首市",
              "code": "341282"
            }
          ]
        },
        {
          "province": "宿州市",
          "code": "341300",
          "areas": [
            {
              "area": "埇桥区",
              "code": "341302"
            },
            {
              "area": "砀山县",
              "code": "341321"
            },
            {
              "area": "萧县",
              "code": "341322"
            },
            {
              "area": "灵璧县",
              "code": "341323"
            },
            {
              "area": "泗县",
              "code": "341324"
            },
            {
              "area": "宿州马鞍山现代产业园区",
              "code": "341371"
            },
            {
              "area": "宿州经济技术开发区",
              "code": "341372"
            }
          ]
        },
        {
          "province": "六安市",
          "code": "341500",
          "areas": [
            {
              "area": "金安区",
              "code": "341502"
            },
            {
              "area": "裕安区",
              "code": "341503"
            },
            {
              "area": "叶集区",
              "code": "341504"
            },
            {
              "area": "霍邱县",
              "code": "341522"
            },
            {
              "area": "舒城县",
              "code": "341523"
            },
            {
              "area": "金寨县",
              "code": "341524"
            },
            {
              "area": "霍山县",
              "code": "341525"
            }
          ]
        },
        {
          "province": "亳州市",
          "code": "341600",
          "areas": [
            {
              "area": "谯城区",
              "code": "341602"
            },
            {
              "area": "涡阳县",
              "code": "341621"
            },
            {
              "area": "蒙城县",
              "code": "341622"
            },
            {
              "area": "利辛县",
              "code": "341623"
            }
          ]
        },
        {
          "province": "池州市",
          "code": "341700",
          "areas": [
            {
              "area": "贵池区",
              "code": "341702"
            },
            {
              "area": "东至县",
              "code": "341721"
            },
            {
              "area": "石台县",
              "code": "341722"
            },
            {
              "area": "青阳县",
              "code": "341723"
            }
          ]
        },
        {
          "province": "宣城市",
          "code": "341800",
          "areas": [
            {
              "area": "宣州区",
              "code": "341802"
            },
            {
              "area": "郎溪县",
              "code": "341821"
            },
            {
              "area": "泾县",
              "code": "341823"
            },
            {
              "area": "绩溪县",
              "code": "341824"
            },
            {
              "area": "旌德县",
              "code": "341825"
            },
            {
              "area": "宣城市经济开发区",
              "code": "341871"
            },
            {
              "area": "宁国市",
              "code": "341881"
            },
            {
              "area": "广德市",
              "code": "341882"
            }
          ]
        }
      ]
    },
    {
      "province": "福建省",
      "code": "350000",
      "provinces": [
        {
          "province": "福州市",
          "code": "350100",
          "areas": [
            {
              "area": "鼓楼区",
              "code": "350102"
            },
            {
              "area": "台江区",
              "code": "350103"
            },
            {
              "area": "仓山区",
              "code": "350104"
            },
            {
              "area": "马尾区",
              "code": "350105"
            },
            {
              "area": "晋安区",
              "code": "350111"
            },
            {
              "area": "长乐区",
              "code": "350112"
            },
            {
              "area": "闽侯县",
              "code": "350121"
            },
            {
              "area": "连江县",
              "code": "350122"
            },
            {
              "area": "罗源县",
              "code": "350123"
            },
            {
              "area": "闽清县",
              "code": "350124"
            },
            {
              "area": "永泰县",
              "code": "350125"
            },
            {
              "area": "平潭县",
              "code": "350128"
            },
            {
              "area": "福清市",
              "code": "350181"
            }
          ]
        },
        {
          "province": "厦门市",
          "code": "350200",
          "areas": [
            {
              "area": "思明区",
              "code": "350203"
            },
            {
              "area": "海沧区",
              "code": "350205"
            },
            {
              "area": "湖里区",
              "code": "350206"
            },
            {
              "area": "集美区",
              "code": "350211"
            },
            {
              "area": "同安区",
              "code": "350212"
            },
            {
              "area": "翔安区",
              "code": "350213"
            }
          ]
        },
        {
          "province": "莆田市",
          "code": "350300",
          "areas": [
            {
              "area": "城厢区",
              "code": "350302"
            },
            {
              "area": "涵江区",
              "code": "350303"
            },
            {
              "area": "荔城区",
              "code": "350304"
            },
            {
              "area": "秀屿区",
              "code": "350305"
            },
            {
              "area": "仙游县",
              "code": "350322"
            }
          ]
        },
        {
          "province": "三明市",
          "code": "350400",
          "areas": [
            {
              "area": "三元区",
              "code": "350404"
            },
            {
              "area": "沙县区",
              "code": "350405"
            },
            {
              "area": "明溪县",
              "code": "350421"
            },
            {
              "area": "清流县",
              "code": "350423"
            },
            {
              "area": "宁化县",
              "code": "350424"
            },
            {
              "area": "大田县",
              "code": "350425"
            },
            {
              "area": "尤溪县",
              "code": "350426"
            },
            {
              "area": "将乐县",
              "code": "350428"
            },
            {
              "area": "泰宁县",
              "code": "350429"
            },
            {
              "area": "建宁县",
              "code": "350430"
            },
            {
              "area": "永安市",
              "code": "350481"
            }
          ]
        },
        {
          "province": "泉州市",
          "code": "350500",
          "areas": [
            {
              "area": "鲤城区",
              "code": "350502"
            },
            {
              "area": "丰泽区",
              "code": "350503"
            },
            {
              "area": "洛江区",
              "code": "350504"
            },
            {
              "area": "泉港区",
              "code": "350505"
            },
            {
              "area": "惠安县",
              "code": "350521"
            },
            {
              "area": "安溪县",
              "code": "350524"
            },
            {
              "area": "永春县",
              "code": "350525"
            },
            {
              "area": "德化县",
              "code": "350526"
            },
            {
              "area": "金门县",
              "code": "350527"
            },
            {
              "area": "石狮市",
              "code": "350581"
            },
            {
              "area": "晋江市",
              "code": "350582"
            },
            {
              "area": "南安市",
              "code": "350583"
            }
          ]
        },
        {
          "province": "漳州市",
          "code": "350600",
          "areas": [
            {
              "area": "芗城区",
              "code": "350602"
            },
            {
              "area": "龙文区",
              "code": "350603"
            },
            {
              "area": "龙海区",
              "code": "350604"
            },
            {
              "area": "长泰区",
              "code": "350605"
            },
            {
              "area": "云霄县",
              "code": "350622"
            },
            {
              "area": "漳浦县",
              "code": "350623"
            },
            {
              "area": "诏安县",
              "code": "350624"
            },
            {
              "area": "东山县",
              "code": "350626"
            },
            {
              "area": "南靖县",
              "code": "350627"
            },
            {
              "area": "平和县",
              "code": "350628"
            },
            {
              "area": "华安县",
              "code": "350629"
            }
          ]
        },
        {
          "province": "南平市",
          "code": "350700",
          "areas": [
            {
              "area": "延平区",
              "code": "350702"
            },
            {
              "area": "建阳区",
              "code": "350703"
            },
            {
              "area": "顺昌县",
              "code": "350721"
            },
            {
              "area": "浦城县",
              "code": "350722"
            },
            {
              "area": "光泽县",
              "code": "350723"
            },
            {
              "area": "松溪县",
              "code": "350724"
            },
            {
              "area": "政和县",
              "code": "350725"
            },
            {
              "area": "邵武市",
              "code": "350781"
            },
            {
              "area": "武夷山市",
              "code": "350782"
            },
            {
              "area": "建瓯市",
              "code": "350783"
            }
          ]
        },
        {
          "province": "龙岩市",
          "code": "350800",
          "areas": [
            {
              "area": "新罗区",
              "code": "350802"
            },
            {
              "area": "永定区",
              "code": "350803"
            },
            {
              "area": "长汀县",
              "code": "350821"
            },
            {
              "area": "上杭县",
              "code": "350823"
            },
            {
              "area": "武平县",
              "code": "350824"
            },
            {
              "area": "连城县",
              "code": "350825"
            },
            {
              "area": "漳平市",
              "code": "350881"
            }
          ]
        },
        {
          "province": "宁德市",
          "code": "350900",
          "areas": [
            {
              "area": "蕉城区",
              "code": "350902"
            },
            {
              "area": "霞浦县",
              "code": "350921"
            },
            {
              "area": "古田县",
              "code": "350922"
            },
            {
              "area": "屏南县",
              "code": "350923"
            },
            {
              "area": "寿宁县",
              "code": "350924"
            },
            {
              "area": "周宁县",
              "code": "350925"
            },
            {
              "area": "柘荣县",
              "code": "350926"
            },
            {
              "area": "福安市",
              "code": "350981"
            },
            {
              "area": "福鼎市",
              "code": "350982"
            }
          ]
        }
      ]
    },
    {
      "province": "江西省",
      "code": "360000",
      "provinces": [
        {
          "province": "南昌市",
          "code": "360100",
          "areas": [
            {
              "area": "东湖区",
              "code": "360102"
            },
            {
              "area": "西湖区",
              "code": "360103"
            },
            {
              "area": "青云谱区",
              "code": "360104"
            },
            {
              "area": "青山湖区",
              "code": "360111"
            },
            {
              "area": "新建区",
              "code": "360112"
            },
            {
              "area": "红谷滩区",
              "code": "360113"
            },
            {
              "area": "南昌县",
              "code": "360121"
            },
            {
              "area": "安义县",
              "code": "360123"
            },
            {
              "area": "进贤县",
              "code": "360124"
            }
          ]
        },
        {
          "province": "景德镇市",
          "code": "360200",
          "areas": [
            {
              "area": "昌江区",
              "code": "360202"
            },
            {
              "area": "珠山区",
              "code": "360203"
            },
            {
              "area": "浮梁县",
              "code": "360222"
            },
            {
              "area": "乐平市",
              "code": "360281"
            }
          ]
        },
        {
          "province": "萍乡市",
          "code": "360300",
          "areas": [
            {
              "area": "安源区",
              "code": "360302"
            },
            {
              "area": "湘东区",
              "code": "360313"
            },
            {
              "area": "莲花县",
              "code": "360321"
            },
            {
              "area": "上栗县",
              "code": "360322"
            },
            {
              "area": "芦溪县",
              "code": "360323"
            }
          ]
        },
        {
          "province": "九江市",
          "code": "360400",
          "areas": [
            {
              "area": "濂溪区",
              "code": "360402"
            },
            {
              "area": "浔阳区",
              "code": "360403"
            },
            {
              "area": "柴桑区",
              "code": "360404"
            },
            {
              "area": "武宁县",
              "code": "360423"
            },
            {
              "area": "修水县",
              "code": "360424"
            },
            {
              "area": "永修县",
              "code": "360425"
            },
            {
              "area": "德安县",
              "code": "360426"
            },
            {
              "area": "都昌县",
              "code": "360428"
            },
            {
              "area": "湖口县",
              "code": "360429"
            },
            {
              "area": "彭泽县",
              "code": "360430"
            },
            {
              "area": "瑞昌市",
              "code": "360481"
            },
            {
              "area": "共青城市",
              "code": "360482"
            },
            {
              "area": "庐山市",
              "code": "360483"
            }
          ]
        },
        {
          "province": "新余市",
          "code": "360500",
          "areas": [
            {
              "area": "渝水区",
              "code": "360502"
            },
            {
              "area": "分宜县",
              "code": "360521"
            }
          ]
        },
        {
          "province": "鹰潭市",
          "code": "360600",
          "areas": [
            {
              "area": "月湖区",
              "code": "360602"
            },
            {
              "area": "余江区",
              "code": "360603"
            },
            {
              "area": "贵溪市",
              "code": "360681"
            }
          ]
        },
        {
          "province": "赣州市",
          "code": "360700",
          "areas": [
            {
              "area": "章贡区",
              "code": "360702"
            },
            {
              "area": "南康区",
              "code": "360703"
            },
            {
              "area": "赣县区",
              "code": "360704"
            },
            {
              "area": "信丰县",
              "code": "360722"
            },
            {
              "area": "大余县",
              "code": "360723"
            },
            {
              "area": "上犹县",
              "code": "360724"
            },
            {
              "area": "崇义县",
              "code": "360725"
            },
            {
              "area": "安远县",
              "code": "360726"
            },
            {
              "area": "定南县",
              "code": "360728"
            },
            {
              "area": "全南县",
              "code": "360729"
            },
            {
              "area": "宁都县",
              "code": "360730"
            },
            {
              "area": "于都县",
              "code": "360731"
            },
            {
              "area": "兴国县",
              "code": "360732"
            },
            {
              "area": "会昌县",
              "code": "360733"
            },
            {
              "area": "寻乌县",
              "code": "360734"
            },
            {
              "area": "石城县",
              "code": "360735"
            },
            {
              "area": "瑞金市",
              "code": "360781"
            },
            {
              "area": "龙南市",
              "code": "360783"
            }
          ]
        },
        {
          "province": "吉安市",
          "code": "360800",
          "areas": [
            {
              "area": "吉州区",
              "code": "360802"
            },
            {
              "area": "青原区",
              "code": "360803"
            },
            {
              "area": "吉安县",
              "code": "360821"
            },
            {
              "area": "吉水县",
              "code": "360822"
            },
            {
              "area": "峡江县",
              "code": "360823"
            },
            {
              "area": "新干县",
              "code": "360824"
            },
            {
              "area": "永丰县",
              "code": "360825"
            },
            {
              "area": "泰和县",
              "code": "360826"
            },
            {
              "area": "遂川县",
              "code": "360827"
            },
            {
              "area": "万安县",
              "code": "360828"
            },
            {
              "area": "安福县",
              "code": "360829"
            },
            {
              "area": "永新县",
              "code": "360830"
            },
            {
              "area": "井冈山市",
              "code": "360881"
            }
          ]
        },
        {
          "province": "宜春市",
          "code": "360900",
          "areas": [
            {
              "area": "袁州区",
              "code": "360902"
            },
            {
              "area": "奉新县",
              "code": "360921"
            },
            {
              "area": "万载县",
              "code": "360922"
            },
            {
              "area": "上高县",
              "code": "360923"
            },
            {
              "area": "宜丰县",
              "code": "360924"
            },
            {
              "area": "靖安县",
              "code": "360925"
            },
            {
              "area": "铜鼓县",
              "code": "360926"
            },
            {
              "area": "丰城市",
              "code": "360981"
            },
            {
              "area": "樟树市",
              "code": "360982"
            },
            {
              "area": "高安市",
              "code": "360983"
            }
          ]
        },
        {
          "province": "抚州市",
          "code": "361000",
          "areas": [
            {
              "area": "临川区",
              "code": "361002"
            },
            {
              "area": "东乡区",
              "code": "361003"
            },
            {
              "area": "南城县",
              "code": "361021"
            },
            {
              "area": "黎川县",
              "code": "361022"
            },
            {
              "area": "南丰县",
              "code": "361023"
            },
            {
              "area": "崇仁县",
              "code": "361024"
            },
            {
              "area": "乐安县",
              "code": "361025"
            },
            {
              "area": "宜黄县",
              "code": "361026"
            },
            {
              "area": "金溪县",
              "code": "361027"
            },
            {
              "area": "资溪县",
              "code": "361028"
            },
            {
              "area": "广昌县",
              "code": "361030"
            }
          ]
        },
        {
          "province": "上饶市",
          "code": "361100",
          "areas": [
            {
              "area": "信州区",
              "code": "361102"
            },
            {
              "area": "广丰区",
              "code": "361103"
            },
            {
              "area": "广信区",
              "code": "361104"
            },
            {
              "area": "玉山县",
              "code": "361123"
            },
            {
              "area": "铅山县",
              "code": "361124"
            },
            {
              "area": "横峰县",
              "code": "361125"
            },
            {
              "area": "弋阳县",
              "code": "361126"
            },
            {
              "area": "余干县",
              "code": "361127"
            },
            {
              "area": "鄱阳县",
              "code": "361128"
            },
            {
              "area": "万年县",
              "code": "361129"
            },
            {
              "area": "婺源县",
              "code": "361130"
            },
            {
              "area": "德兴市",
              "code": "361181"
            }
          ]
        }
      ]
    },
    {
      "province": "山东省",
      "code": "370000",
      "provinces": [
        {
          "province": "济南市",
          "code": "370100",
          "areas": [
            {
              "area": "历下区",
              "code": "370102"
            },
            {
              "area": "市中区",
              "code": "370103"
            },
            {
              "area": "槐荫区",
              "code": "370104"
            },
            {
              "area": "天桥区",
              "code": "370105"
            },
            {
              "area": "历城区",
              "code": "370112"
            },
            {
              "area": "长清区",
              "code": "370113"
            },
            {
              "area": "章丘区",
              "code": "370114"
            },
            {
              "area": "济阳区",
              "code": "370115"
            },
            {
              "area": "莱芜区",
              "code": "370116"
            },
            {
              "area": "钢城区",
              "code": "370117"
            },
            {
              "area": "平阴县",
              "code": "370124"
            },
            {
              "area": "商河县",
              "code": "370126"
            },
            {
              "area": "济南高新技术产业开发区",
              "code": "370176"
            }
          ]
        },
        {
          "province": "青岛市",
          "code": "370200",
          "areas": [
            {
              "area": "市南区",
              "code": "370202"
            },
            {
              "area": "市北区",
              "code": "370203"
            },
            {
              "area": "黄岛区",
              "code": "370211"
            },
            {
              "area": "崂山区",
              "code": "370212"
            },
            {
              "area": "李沧区",
              "code": "370213"
            },
            {
              "area": "城阳区",
              "code": "370214"
            },
            {
              "area": "即墨区",
              "code": "370215"
            },
            {
              "area": "胶州市",
              "code": "370281"
            },
            {
              "area": "平度市",
              "code": "370283"
            },
            {
              "area": "莱西市",
              "code": "370285"
            }
          ]
        },
        {
          "province": "淄博市",
          "code": "370300",
          "areas": [
            {
              "area": "淄川区",
              "code": "370302"
            },
            {
              "area": "张店区",
              "code": "370303"
            },
            {
              "area": "博山区",
              "code": "370304"
            },
            {
              "area": "临淄区",
              "code": "370305"
            },
            {
              "area": "周村区",
              "code": "370306"
            },
            {
              "area": "桓台县",
              "code": "370321"
            },
            {
              "area": "高青县",
              "code": "370322"
            },
            {
              "area": "沂源县",
              "code": "370323"
            }
          ]
        },
        {
          "province": "枣庄市",
          "code": "370400",
          "areas": [
            {
              "area": "市中区",
              "code": "370402"
            },
            {
              "area": "薛城区",
              "code": "370403"
            },
            {
              "area": "峄城区",
              "code": "370404"
            },
            {
              "area": "台儿庄区",
              "code": "370405"
            },
            {
              "area": "山亭区",
              "code": "370406"
            },
            {
              "area": "滕州市",
              "code": "370481"
            }
          ]
        },
        {
          "province": "东营市",
          "code": "370500",
          "areas": [
            {
              "area": "东营区",
              "code": "370502"
            },
            {
              "area": "河口区",
              "code": "370503"
            },
            {
              "area": "垦利区",
              "code": "370505"
            },
            {
              "area": "利津县",
              "code": "370522"
            },
            {
              "area": "广饶县",
              "code": "370523"
            },
            {
              "area": "东营经济技术开发区",
              "code": "370571"
            },
            {
              "area": "东营港经济开发区",
              "code": "370572"
            }
          ]
        },
        {
          "province": "烟台市",
          "code": "370600",
          "areas": [
            {
              "area": "芝罘区",
              "code": "370602"
            },
            {
              "area": "福山区",
              "code": "370611"
            },
            {
              "area": "牟平区",
              "code": "370612"
            },
            {
              "area": "莱山区",
              "code": "370613"
            },
            {
              "area": "蓬莱区",
              "code": "370614"
            },
            {
              "area": "烟台高新技术产业开发区",
              "code": "370671"
            },
            {
              "area": "烟台经济技术开发区",
              "code": "370676"
            },
            {
              "area": "龙口市",
              "code": "370681"
            },
            {
              "area": "莱阳市",
              "code": "370682"
            },
            {
              "area": "莱州市",
              "code": "370683"
            },
            {
              "area": "招远市",
              "code": "370685"
            },
            {
              "area": "栖霞市",
              "code": "370686"
            },
            {
              "area": "海阳市",
              "code": "370687"
            }
          ]
        },
        {
          "province": "潍坊市",
          "code": "370700",
          "areas": [
            {
              "area": "潍城区",
              "code": "370702"
            },
            {
              "area": "寒亭区",
              "code": "370703"
            },
            {
              "area": "坊子区",
              "code": "370704"
            },
            {
              "area": "奎文区",
              "code": "370705"
            },
            {
              "area": "临朐县",
              "code": "370724"
            },
            {
              "area": "昌乐县",
              "code": "370725"
            },
            {
              "area": "潍坊滨海经济技术开发区",
              "code": "370772"
            },
            {
              "area": "青州市",
              "code": "370781"
            },
            {
              "area": "诸城市",
              "code": "370782"
            },
            {
              "area": "寿光市",
              "code": "370783"
            },
            {
              "area": "安丘市",
              "code": "370784"
            },
            {
              "area": "高密市",
              "code": "370785"
            },
            {
              "area": "昌邑市",
              "code": "370786"
            }
          ]
        },
        {
          "province": "济宁市",
          "code": "370800",
          "areas": [
            {
              "area": "任城区",
              "code": "370811"
            },
            {
              "area": "兖州区",
              "code": "370812"
            },
            {
              "area": "微山县",
              "code": "370826"
            },
            {
              "area": "鱼台县",
              "code": "370827"
            },
            {
              "area": "金乡县",
              "code": "370828"
            },
            {
              "area": "嘉祥县",
              "code": "370829"
            },
            {
              "area": "汶上县",
              "code": "370830"
            },
            {
              "area": "泗水县",
              "code": "370831"
            },
            {
              "area": "梁山县",
              "code": "370832"
            },
            {
              "area": "济宁高新技术产业开发区",
              "code": "370871"
            },
            {
              "area": "曲阜市",
              "code": "370881"
            },
            {
              "area": "邹城市",
              "code": "370883"
            }
          ]
        },
        {
          "province": "泰安市",
          "code": "370900",
          "areas": [
            {
              "area": "泰山区",
              "code": "370902"
            },
            {
              "area": "岱岳区",
              "code": "370911"
            },
            {
              "area": "宁阳县",
              "code": "370921"
            },
            {
              "area": "东平县",
              "code": "370923"
            },
            {
              "area": "新泰市",
              "code": "370982"
            },
            {
              "area": "肥城市",
              "code": "370983"
            }
          ]
        },
        {
          "province": "威海市",
          "code": "371000",
          "areas": [
            {
              "area": "环翠区",
              "code": "371002"
            },
            {
              "area": "文登区",
              "code": "371003"
            },
            {
              "area": "威海火炬高技术产业开发区",
              "code": "371071"
            },
            {
              "area": "威海经济技术开发区",
              "code": "371072"
            },
            {
              "area": "威海临港经济技术开发区",
              "code": "371073"
            },
            {
              "area": "荣成市",
              "code": "371082"
            },
            {
              "area": "乳山市",
              "code": "371083"
            }
          ]
        },
        {
          "province": "日照市",
          "code": "371100",
          "areas": [
            {
              "area": "东港区",
              "code": "371102"
            },
            {
              "area": "岚山区",
              "code": "371103"
            },
            {
              "area": "五莲县",
              "code": "371121"
            },
            {
              "area": "莒县",
              "code": "371122"
            },
            {
              "area": "日照经济技术开发区",
              "code": "371171"
            }
          ]
        },
        {
          "province": "临沂市",
          "code": "371300",
          "areas": [
            {
              "area": "兰山区",
              "code": "371302"
            },
            {
              "area": "罗庄区",
              "code": "371311"
            },
            {
              "area": "河东区",
              "code": "371312"
            },
            {
              "area": "沂南县",
              "code": "371321"
            },
            {
              "area": "郯城县",
              "code": "371322"
            },
            {
              "area": "沂水县",
              "code": "371323"
            },
            {
              "area": "兰陵县",
              "code": "371324"
            },
            {
              "area": "费县",
              "code": "371325"
            },
            {
              "area": "平邑县",
              "code": "371326"
            },
            {
              "area": "莒南县",
              "code": "371327"
            },
            {
              "area": "蒙阴县",
              "code": "371328"
            },
            {
              "area": "临沭县",
              "code": "371329"
            },
            {
              "area": "临沂高新技术产业开发区",
              "code": "371371"
            }
          ]
        },
        {
          "province": "德州市",
          "code": "371400",
          "areas": [
            {
              "area": "德城区",
              "code": "371402"
            },
            {
              "area": "陵城区",
              "code": "371403"
            },
            {
              "area": "宁津县",
              "code": "371422"
            },
            {
              "area": "庆云县",
              "code": "371423"
            },
            {
              "area": "临邑县",
              "code": "371424"
            },
            {
              "area": "齐河县",
              "code": "371425"
            },
            {
              "area": "平原县",
              "code": "371426"
            },
            {
              "area": "夏津县",
              "code": "371427"
            },
            {
              "area": "武城县",
              "code": "371428"
            },
            {
              "area": "德州天衢新区",
              "code": "371471"
            },
            {
              "area": "乐陵市",
              "code": "371481"
            },
            {
              "area": "禹城市",
              "code": "371482"
            }
          ]
        },
        {
          "province": "聊城市",
          "code": "371500",
          "areas": [
            {
              "area": "东昌府区",
              "code": "371502"
            },
            {
              "area": "茌平区",
              "code": "371503"
            },
            {
              "area": "阳谷县",
              "code": "371521"
            },
            {
              "area": "莘县",
              "code": "371522"
            },
            {
              "area": "东阿县",
              "code": "371524"
            },
            {
              "area": "冠县",
              "code": "371525"
            },
            {
              "area": "高唐县",
              "code": "371526"
            },
            {
              "area": "临清市",
              "code": "371581"
            }
          ]
        },
        {
          "province": "滨州市",
          "code": "371600",
          "areas": [
            {
              "area": "滨城区",
              "code": "371602"
            },
            {
              "area": "沾化区",
              "code": "371603"
            },
            {
              "area": "惠民县",
              "code": "371621"
            },
            {
              "area": "阳信县",
              "code": "371622"
            },
            {
              "area": "无棣县",
              "code": "371623"
            },
            {
              "area": "博兴县",
              "code": "371625"
            },
            {
              "area": "邹平市",
              "code": "371681"
            }
          ]
        },
        {
          "province": "菏泽市",
          "code": "371700",
          "areas": [
            {
              "area": "牡丹区",
              "code": "371702"
            },
            {
              "area": "定陶区",
              "code": "371703"
            },
            {
              "area": "曹县",
              "code": "371721"
            },
            {
              "area": "单县",
              "code": "371722"
            },
            {
              "area": "成武县",
              "code": "371723"
            },
            {
              "area": "巨野县",
              "code": "371724"
            },
            {
              "area": "郓城县",
              "code": "371725"
            },
            {
              "area": "鄄城县",
              "code": "371726"
            },
            {
              "area": "东明县",
              "code": "371728"
            },
            {
              "area": "菏泽经济技术开发区",
              "code": "371771"
            },
            {
              "area": "菏泽高新技术开发区",
              "code": "371772"
            }
          ]
        }
      ]
    },
    {
      "province": "河南省",
      "code": "410000",
      "provinces": [
        {
          "province": "郑州市",
          "code": "410100",
          "areas": [
            {
              "area": "中原区",
              "code": "410102"
            },
            {
              "area": "二七区",
              "code": "410103"
            },
            {
              "area": "管城回族区",
              "code": "410104"
            },
            {
              "area": "金水区",
              "code": "410105"
            },
            {
              "area": "上街区",
              "code": "410106"
            },
            {
              "area": "惠济区",
              "code": "410108"
            },
            {
              "area": "中牟县",
              "code": "410122"
            },
            {
              "area": "郑州经济技术开发区",
              "code": "410171"
            },
            {
              "area": "郑州高新技术产业开发区",
              "code": "410172"
            },
            {
              "area": "郑州航空港经济综合实验区",
              "code": "410173"
            },
            {
              "area": "巩义市",
              "code": "410181"
            },
            {
              "area": "荥阳市",
              "code": "410182"
            },
            {
              "area": "新密市",
              "code": "410183"
            },
            {
              "area": "新郑市",
              "code": "410184"
            },
            {
              "area": "登封市",
              "code": "410185"
            }
          ]
        },
        {
          "province": "开封市",
          "code": "410200",
          "areas": [
            {
              "area": "龙亭区",
              "code": "410202"
            },
            {
              "area": "顺河回族区",
              "code": "410203"
            },
            {
              "area": "鼓楼区",
              "code": "410204"
            },
            {
              "area": "禹王台区",
              "code": "410205"
            },
            {
              "area": "祥符区",
              "code": "410212"
            },
            {
              "area": "杞县",
              "code": "410221"
            },
            {
              "area": "通许县",
              "code": "410222"
            },
            {
              "area": "尉氏县",
              "code": "410223"
            },
            {
              "area": "兰考县",
              "code": "410225"
            }
          ]
        },
        {
          "province": "洛阳市",
          "code": "410300",
          "areas": [
            {
              "area": "老城区",
              "code": "410302"
            },
            {
              "area": "西工区",
              "code": "410303"
            },
            {
              "area": "瀍河回族区",
              "code": "410304"
            },
            {
              "area": "涧西区",
              "code": "410305"
            },
            {
              "area": "偃师区",
              "code": "410307"
            },
            {
              "area": "孟津区",
              "code": "410308"
            },
            {
              "area": "洛龙区",
              "code": "410311"
            },
            {
              "area": "新安县",
              "code": "410323"
            },
            {
              "area": "栾川县",
              "code": "410324"
            },
            {
              "area": "嵩县",
              "code": "410325"
            },
            {
              "area": "汝阳县",
              "code": "410326"
            },
            {
              "area": "宜阳县",
              "code": "410327"
            },
            {
              "area": "洛宁县",
              "code": "410328"
            },
            {
              "area": "伊川县",
              "code": "410329"
            },
            {
              "area": "洛阳高新技术产业开发区",
              "code": "410371"
            }
          ]
        },
        {
          "province": "平顶山市",
          "code": "410400",
          "areas": [
            {
              "area": "新华区",
              "code": "410402"
            },
            {
              "area": "卫东区",
              "code": "410403"
            },
            {
              "area": "石龙区",
              "code": "410404"
            },
            {
              "area": "湛河区",
              "code": "410411"
            },
            {
              "area": "宝丰县",
              "code": "410421"
            },
            {
              "area": "叶县",
              "code": "410422"
            },
            {
              "area": "鲁山县",
              "code": "410423"
            },
            {
              "area": "郏县",
              "code": "410425"
            },
            {
              "area": "平顶山高新技术产业开发区",
              "code": "410471"
            },
            {
              "area": "平顶山市城乡一体化示范区",
              "code": "410472"
            },
            {
              "area": "舞钢市",
              "code": "410481"
            },
            {
              "area": "汝州市",
              "code": "410482"
            }
          ]
        },
        {
          "province": "安阳市",
          "code": "410500",
          "areas": [
            {
              "area": "文峰区",
              "code": "410502"
            },
            {
              "area": "北关区",
              "code": "410503"
            },
            {
              "area": "殷都区",
              "code": "410505"
            },
            {
              "area": "龙安区",
              "code": "410506"
            },
            {
              "area": "安阳县",
              "code": "410522"
            },
            {
              "area": "汤阴县",
              "code": "410523"
            },
            {
              "area": "滑县",
              "code": "410526"
            },
            {
              "area": "内黄县",
              "code": "410527"
            },
            {
              "area": "安阳高新技术产业开发区",
              "code": "410571"
            },
            {
              "area": "林州市",
              "code": "410581"
            }
          ]
        },
        {
          "province": "鹤壁市",
          "code": "410600",
          "areas": [
            {
              "area": "鹤山区",
              "code": "410602"
            },
            {
              "area": "山城区",
              "code": "410603"
            },
            {
              "area": "淇滨区",
              "code": "410611"
            },
            {
              "area": "浚县",
              "code": "410621"
            },
            {
              "area": "淇县",
              "code": "410622"
            },
            {
              "area": "鹤壁经济技术开发区",
              "code": "410671"
            }
          ]
        },
        {
          "province": "新乡市",
          "code": "410700",
          "areas": [
            {
              "area": "红旗区",
              "code": "410702"
            },
            {
              "area": "卫滨区",
              "code": "410703"
            },
            {
              "area": "凤泉区",
              "code": "410704"
            },
            {
              "area": "牧野区",
              "code": "410711"
            },
            {
              "area": "新乡县",
              "code": "410721"
            },
            {
              "area": "获嘉县",
              "code": "410724"
            },
            {
              "area": "原阳县",
              "code": "410725"
            },
            {
              "area": "延津县",
              "code": "410726"
            },
            {
              "area": "封丘县",
              "code": "410727"
            },
            {
              "area": "新乡高新技术产业开发区",
              "code": "410771"
            },
            {
              "area": "新乡经济技术开发区",
              "code": "410772"
            },
            {
              "area": "新乡市平原城乡一体化示范区",
              "code": "410773"
            },
            {
              "area": "卫辉市",
              "code": "410781"
            },
            {
              "area": "辉县市",
              "code": "410782"
            },
            {
              "area": "长垣市",
              "code": "410783"
            }
          ]
        },
        {
          "province": "焦作市",
          "code": "410800",
          "areas": [
            {
              "area": "解放区",
              "code": "410802"
            },
            {
              "area": "中站区",
              "code": "410803"
            },
            {
              "area": "马村区",
              "code": "410804"
            },
            {
              "area": "山阳区",
              "code": "410811"
            },
            {
              "area": "修武县",
              "code": "410821"
            },
            {
              "area": "博爱县",
              "code": "410822"
            },
            {
              "area": "武陟县",
              "code": "410823"
            },
            {
              "area": "温县",
              "code": "410825"
            },
            {
              "area": "焦作城乡一体化示范区",
              "code": "410871"
            },
            {
              "area": "沁阳市",
              "code": "410882"
            },
            {
              "area": "孟州市",
              "code": "410883"
            }
          ]
        },
        {
          "province": "濮阳市",
          "code": "410900",
          "areas": [
            {
              "area": "华龙区",
              "code": "410902"
            },
            {
              "area": "清丰县",
              "code": "410922"
            },
            {
              "area": "南乐县",
              "code": "410923"
            },
            {
              "area": "范县",
              "code": "410926"
            },
            {
              "area": "台前县",
              "code": "410927"
            },
            {
              "area": "濮阳县",
              "code": "410928"
            },
            {
              "area": "河南濮阳工业园区",
              "code": "410971"
            },
            {
              "area": "濮阳经济技术开发区",
              "code": "410972"
            }
          ]
        },
        {
          "province": "许昌市",
          "code": "411000",
          "areas": [
            {
              "area": "魏都区",
              "code": "411002"
            },
            {
              "area": "建安区",
              "code": "411003"
            },
            {
              "area": "鄢陵县",
              "code": "411024"
            },
            {
              "area": "襄城县",
              "code": "411025"
            },
            {
              "area": "许昌经济技术开发区",
              "code": "411071"
            },
            {
              "area": "禹州市",
              "code": "411081"
            },
            {
              "area": "长葛市",
              "code": "411082"
            }
          ]
        },
        {
          "province": "漯河市",
          "code": "411100",
          "areas": [
            {
              "area": "源汇区",
              "code": "411102"
            },
            {
              "area": "郾城区",
              "code": "411103"
            },
            {
              "area": "召陵区",
              "code": "411104"
            },
            {
              "area": "舞阳县",
              "code": "411121"
            },
            {
              "area": "临颍县",
              "code": "411122"
            },
            {
              "area": "漯河经济技术开发区",
              "code": "411171"
            }
          ]
        },
        {
          "province": "三门峡市",
          "code": "411200",
          "areas": [
            {
              "area": "湖滨区",
              "code": "411202"
            },
            {
              "area": "陕州区",
              "code": "411203"
            },
            {
              "area": "渑池县",
              "code": "411221"
            },
            {
              "area": "卢氏县",
              "code": "411224"
            },
            {
              "area": "河南三门峡经济开发区",
              "code": "411271"
            },
            {
              "area": "义马市",
              "code": "411281"
            },
            {
              "area": "灵宝市",
              "code": "411282"
            }
          ]
        },
        {
          "province": "南阳市",
          "code": "411300",
          "areas": [
            {
              "area": "宛城区",
              "code": "411302"
            },
            {
              "area": "卧龙区",
              "code": "411303"
            },
            {
              "area": "南召县",
              "code": "411321"
            },
            {
              "area": "方城县",
              "code": "411322"
            },
            {
              "area": "西峡县",
              "code": "411323"
            },
            {
              "area": "镇平县",
              "code": "411324"
            },
            {
              "area": "内乡县",
              "code": "411325"
            },
            {
              "area": "淅川县",
              "code": "411326"
            },
            {
              "area": "社旗县",
              "code": "411327"
            },
            {
              "area": "唐河县",
              "code": "411328"
            },
            {
              "area": "新野县",
              "code": "411329"
            },
            {
              "area": "桐柏县",
              "code": "411330"
            },
            {
              "area": "南阳高新技术产业开发区",
              "code": "411371"
            },
            {
              "area": "南阳市城乡一体化示范区",
              "code": "411372"
            },
            {
              "area": "邓州市",
              "code": "411381"
            }
          ]
        },
        {
          "province": "商丘市",
          "code": "411400",
          "areas": [
            {
              "area": "梁园区",
              "code": "411402"
            },
            {
              "area": "睢阳区",
              "code": "411403"
            },
            {
              "area": "民权县",
              "code": "411421"
            },
            {
              "area": "睢县",
              "code": "411422"
            },
            {
              "area": "宁陵县",
              "code": "411423"
            },
            {
              "area": "柘城县",
              "code": "411424"
            },
            {
              "area": "虞城县",
              "code": "411425"
            },
            {
              "area": "夏邑县",
              "code": "411426"
            },
            {
              "area": "豫东综合物流产业聚集区",
              "code": "411471"
            },
            {
              "area": "河南商丘经济开发区",
              "code": "411472"
            },
            {
              "area": "永城市",
              "code": "411481"
            }
          ]
        },
        {
          "province": "信阳市",
          "code": "411500",
          "areas": [
            {
              "area": "浉河区",
              "code": "411502"
            },
            {
              "area": "平桥区",
              "code": "411503"
            },
            {
              "area": "罗山县",
              "code": "411521"
            },
            {
              "area": "光山县",
              "code": "411522"
            },
            {
              "area": "新县",
              "code": "411523"
            },
            {
              "area": "商城县",
              "code": "411524"
            },
            {
              "area": "固始县",
              "code": "411525"
            },
            {
              "area": "潢川县",
              "code": "411526"
            },
            {
              "area": "淮滨县",
              "code": "411527"
            },
            {
              "area": "息县",
              "code": "411528"
            },
            {
              "area": "信阳高新技术产业开发区",
              "code": "411571"
            }
          ]
        },
        {
          "province": "周口市",
          "code": "411600",
          "areas": [
            {
              "area": "川汇区",
              "code": "411602"
            },
            {
              "area": "淮阳区",
              "code": "411603"
            },
            {
              "area": "扶沟县",
              "code": "411621"
            },
            {
              "area": "西华县",
              "code": "411622"
            },
            {
              "area": "商水县",
              "code": "411623"
            },
            {
              "area": "沈丘县",
              "code": "411624"
            },
            {
              "area": "郸城县",
              "code": "411625"
            },
            {
              "area": "太康县",
              "code": "411627"
            },
            {
              "area": "鹿邑县",
              "code": "411628"
            },
            {
              "area": "周口临港开发区",
              "code": "411671"
            },
            {
              "area": "项城市",
              "code": "411681"
            }
          ]
        },
        {
          "province": "驻马店市",
          "code": "411700",
          "areas": [
            {
              "area": "驿城区",
              "code": "411702"
            },
            {
              "area": "西平县",
              "code": "411721"
            },
            {
              "area": "上蔡县",
              "code": "411722"
            },
            {
              "area": "平舆县",
              "code": "411723"
            },
            {
              "area": "正阳县",
              "code": "411724"
            },
            {
              "area": "确山县",
              "code": "411725"
            },
            {
              "area": "泌阳县",
              "code": "411726"
            },
            {
              "area": "汝南县",
              "code": "411727"
            },
            {
              "area": "遂平县",
              "code": "411728"
            },
            {
              "area": "新蔡县",
              "code": "411729"
            },
            {
              "area": "河南驻马店经济开发区",
              "code": "411771"
            }
          ]
        },
        {
          "province": "省直辖县级行政区划",
          "code": "419000",
          "areas": [
            {
              "area": "济源市",
              "code": "419001"
            }
          ]
        }
      ]
    },
    {
      "province": "湖北省",
      "code": "420000",
      "provinces": [
        {
          "province": "武汉市",
          "code": "420100",
          "areas": [
            {
              "area": "江岸区",
              "code": "420102"
            },
            {
              "area": "江汉区",
              "code": "420103"
            },
            {
              "area": "硚口区",
              "code": "420104"
            },
            {
              "area": "汉阳区",
              "code": "420105"
            },
            {
              "area": "武昌区",
              "code": "420106"
            },
            {
              "area": "青山区",
              "code": "420107"
            },
            {
              "area": "洪山区",
              "code": "420111"
            },
            {
              "area": "东西湖区",
              "code": "420112"
            },
            {
              "area": "汉南区",
              "code": "420113"
            },
            {
              "area": "蔡甸区",
              "code": "420114"
            },
            {
              "area": "江夏区",
              "code": "420115"
            },
            {
              "area": "黄陂区",
              "code": "420116"
            },
            {
              "area": "新洲区",
              "code": "420117"
            }
          ]
        },
        {
          "province": "黄石市",
          "code": "420200",
          "areas": [
            {
              "area": "黄石港区",
              "code": "420202"
            },
            {
              "area": "西塞山区",
              "code": "420203"
            },
            {
              "area": "下陆区",
              "code": "420204"
            },
            {
              "area": "铁山区",
              "code": "420205"
            },
            {
              "area": "阳新县",
              "code": "420222"
            },
            {
              "area": "大冶市",
              "code": "420281"
            }
          ]
        },
        {
          "province": "十堰市",
          "code": "420300",
          "areas": [
            {
              "area": "茅箭区",
              "code": "420302"
            },
            {
              "area": "张湾区",
              "code": "420303"
            },
            {
              "area": "郧阳区",
              "code": "420304"
            },
            {
              "area": "郧西县",
              "code": "420322"
            },
            {
              "area": "竹山县",
              "code": "420323"
            },
            {
              "area": "竹溪县",
              "code": "420324"
            },
            {
              "area": "房县",
              "code": "420325"
            },
            {
              "area": "丹江口市",
              "code": "420381"
            }
          ]
        },
        {
          "province": "宜昌市",
          "code": "420500",
          "areas": [
            {
              "area": "西陵区",
              "code": "420502"
            },
            {
              "area": "伍家岗区",
              "code": "420503"
            },
            {
              "area": "点军区",
              "code": "420504"
            },
            {
              "area": "猇亭区",
              "code": "420505"
            },
            {
              "area": "夷陵区",
              "code": "420506"
            },
            {
              "area": "远安县",
              "code": "420525"
            },
            {
              "area": "兴山县",
              "code": "420526"
            },
            {
              "area": "秭归县",
              "code": "420527"
            },
            {
              "area": "长阳土家族自治县",
              "code": "420528"
            },
            {
              "area": "五峰土家族自治县",
              "code": "420529"
            },
            {
              "area": "宜都市",
              "code": "420581"
            },
            {
              "area": "当阳市",
              "code": "420582"
            },
            {
              "area": "枝江市",
              "code": "420583"
            }
          ]
        },
        {
          "province": "襄阳市",
          "code": "420600",
          "areas": [
            {
              "area": "襄城区",
              "code": "420602"
            },
            {
              "area": "樊城区",
              "code": "420606"
            },
            {
              "area": "襄州区",
              "code": "420607"
            },
            {
              "area": "南漳县",
              "code": "420624"
            },
            {
              "area": "谷城县",
              "code": "420625"
            },
            {
              "area": "保康县",
              "code": "420626"
            },
            {
              "area": "老河口市",
              "code": "420682"
            },
            {
              "area": "枣阳市",
              "code": "420683"
            },
            {
              "area": "宜城市",
              "code": "420684"
            }
          ]
        },
        {
          "province": "鄂州市",
          "code": "420700",
          "areas": [
            {
              "area": "梁子湖区",
              "code": "420702"
            },
            {
              "area": "华容区",
              "code": "420703"
            },
            {
              "area": "鄂城区",
              "code": "420704"
            }
          ]
        },
        {
          "province": "荆门市",
          "code": "420800",
          "areas": [
            {
              "area": "东宝区",
              "code": "420802"
            },
            {
              "area": "掇刀区",
              "code": "420804"
            },
            {
              "area": "沙洋县",
              "code": "420822"
            },
            {
              "area": "钟祥市",
              "code": "420881"
            },
            {
              "area": "京山市",
              "code": "420882"
            }
          ]
        },
        {
          "province": "孝感市",
          "code": "420900",
          "areas": [
            {
              "area": "孝南区",
              "code": "420902"
            },
            {
              "area": "孝昌县",
              "code": "420921"
            },
            {
              "area": "大悟县",
              "code": "420922"
            },
            {
              "area": "云梦县",
              "code": "420923"
            },
            {
              "area": "应城市",
              "code": "420981"
            },
            {
              "area": "安陆市",
              "code": "420982"
            },
            {
              "area": "汉川市",
              "code": "420984"
            }
          ]
        },
        {
          "province": "荆州市",
          "code": "421000",
          "areas": [
            {
              "area": "沙市区",
              "code": "421002"
            },
            {
              "area": "荆州区",
              "code": "421003"
            },
            {
              "area": "公安县",
              "code": "421022"
            },
            {
              "area": "江陵县",
              "code": "421024"
            },
            {
              "area": "荆州经济技术开发区",
              "code": "421071"
            },
            {
              "area": "石首市",
              "code": "421081"
            },
            {
              "area": "洪湖市",
              "code": "421083"
            },
            {
              "area": "松滋市",
              "code": "421087"
            },
            {
              "area": "监利市",
              "code": "421088"
            }
          ]
        },
        {
          "province": "黄冈市",
          "code": "421100",
          "areas": [
            {
              "area": "黄州区",
              "code": "421102"
            },
            {
              "area": "团风县",
              "code": "421121"
            },
            {
              "area": "红安县",
              "code": "421122"
            },
            {
              "area": "罗田县",
              "code": "421123"
            },
            {
              "area": "英山县",
              "code": "421124"
            },
            {
              "area": "浠水县",
              "code": "421125"
            },
            {
              "area": "蕲春县",
              "code": "421126"
            },
            {
              "area": "黄梅县",
              "code": "421127"
            },
            {
              "area": "龙感湖管理区",
              "code": "421171"
            },
            {
              "area": "麻城市",
              "code": "421181"
            },
            {
              "area": "武穴市",
              "code": "421182"
            }
          ]
        },
        {
          "province": "咸宁市",
          "code": "421200",
          "areas": [
            {
              "area": "咸安区",
              "code": "421202"
            },
            {
              "area": "嘉鱼县",
              "code": "421221"
            },
            {
              "area": "通城县",
              "code": "421222"
            },
            {
              "area": "崇阳县",
              "code": "421223"
            },
            {
              "area": "通山县",
              "code": "421224"
            },
            {
              "area": "赤壁市",
              "code": "421281"
            }
          ]
        },
        {
          "province": "随州市",
          "code": "421300",
          "areas": [
            {
              "area": "曾都区",
              "code": "421303"
            },
            {
              "area": "随县",
              "code": "421321"
            },
            {
              "area": "广水市",
              "code": "421381"
            }
          ]
        },
        {
          "province": "恩施土家族苗族自治州",
          "code": "422800",
          "areas": [
            {
              "area": "恩施市",
              "code": "422801"
            },
            {
              "area": "利川市",
              "code": "422802"
            },
            {
              "area": "建始县",
              "code": "422822"
            },
            {
              "area": "巴东县",
              "code": "422823"
            },
            {
              "area": "宣恩县",
              "code": "422825"
            },
            {
              "area": "咸丰县",
              "code": "422826"
            },
            {
              "area": "来凤县",
              "code": "422827"
            },
            {
              "area": "鹤峰县",
              "code": "422828"
            }
          ]
        },
        {
          "province": "省直辖县级行政区划",
          "code": "429000",
          "areas": [
            {
              "area": "仙桃市",
              "code": "429004"
            },
            {
              "area": "潜江市",
              "code": "429005"
            },
            {
              "area": "天门市",
              "code": "429006"
            },
            {
              "area": "神农架林区",
              "code": "429021"
            }
          ]
        }
      ]
    },
    {
      "province": "湖南省",
      "code": "430000",
      "provinces": [
        {
          "province": "长沙市",
          "code": "430100",
          "areas": [
            {
              "area": "芙蓉区",
              "code": "430102"
            },
            {
              "area": "天心区",
              "code": "430103"
            },
            {
              "area": "岳麓区",
              "code": "430104"
            },
            {
              "area": "开福区",
              "code": "430105"
            },
            {
              "area": "雨花区",
              "code": "430111"
            },
            {
              "area": "望城区",
              "code": "430112"
            },
            {
              "area": "长沙县",
              "code": "430121"
            },
            {
              "area": "浏阳市",
              "code": "430181"
            },
            {
              "area": "宁乡市",
              "code": "430182"
            }
          ]
        },
        {
          "province": "株洲市",
          "code": "430200",
          "areas": [
            {
              "area": "荷塘区",
              "code": "430202"
            },
            {
              "area": "芦淞区",
              "code": "430203"
            },
            {
              "area": "石峰区",
              "code": "430204"
            },
            {
              "area": "天元区",
              "code": "430211"
            },
            {
              "area": "渌口区",
              "code": "430212"
            },
            {
              "area": "攸县",
              "code": "430223"
            },
            {
              "area": "茶陵县",
              "code": "430224"
            },
            {
              "area": "炎陵县",
              "code": "430225"
            },
            {
              "area": "醴陵市",
              "code": "430281"
            }
          ]
        },
        {
          "province": "湘潭市",
          "code": "430300",
          "areas": [
            {
              "area": "雨湖区",
              "code": "430302"
            },
            {
              "area": "岳塘区",
              "code": "430304"
            },
            {
              "area": "湘潭县",
              "code": "430321"
            },
            {
              "area": "湖南湘潭高新技术产业园区",
              "code": "430371"
            },
            {
              "area": "湘潭昭山示范区",
              "code": "430372"
            },
            {
              "area": "湘潭九华示范区",
              "code": "430373"
            },
            {
              "area": "湘乡市",
              "code": "430381"
            },
            {
              "area": "韶山市",
              "code": "430382"
            }
          ]
        },
        {
          "province": "衡阳市",
          "code": "430400",
          "areas": [
            {
              "area": "珠晖区",
              "code": "430405"
            },
            {
              "area": "雁峰区",
              "code": "430406"
            },
            {
              "area": "石鼓区",
              "code": "430407"
            },
            {
              "area": "蒸湘区",
              "code": "430408"
            },
            {
              "area": "南岳区",
              "code": "430412"
            },
            {
              "area": "衡阳县",
              "code": "430421"
            },
            {
              "area": "衡南县",
              "code": "430422"
            },
            {
              "area": "衡山县",
              "code": "430423"
            },
            {
              "area": "衡东县",
              "code": "430424"
            },
            {
              "area": "祁东县",
              "code": "430426"
            },
            {
              "area": "湖南衡阳松木经济开发区",
              "code": "430473"
            },
            {
              "area": "湖南衡阳高新技术产业园区",
              "code": "430476"
            },
            {
              "area": "耒阳市",
              "code": "430481"
            },
            {
              "area": "常宁市",
              "code": "430482"
            }
          ]
        },
        {
          "province": "邵阳市",
          "code": "430500",
          "areas": [
            {
              "area": "双清区",
              "code": "430502"
            },
            {
              "area": "大祥区",
              "code": "430503"
            },
            {
              "area": "北塔区",
              "code": "430511"
            },
            {
              "area": "新邵县",
              "code": "430522"
            },
            {
              "area": "邵阳县",
              "code": "430523"
            },
            {
              "area": "隆回县",
              "code": "430524"
            },
            {
              "area": "洞口县",
              "code": "430525"
            },
            {
              "area": "绥宁县",
              "code": "430527"
            },
            {
              "area": "新宁县",
              "code": "430528"
            },
            {
              "area": "城步苗族自治县",
              "code": "430529"
            },
            {
              "area": "武冈市",
              "code": "430581"
            },
            {
              "area": "邵东市",
              "code": "430582"
            }
          ]
        },
        {
          "province": "岳阳市",
          "code": "430600",
          "areas": [
            {
              "area": "岳阳楼区",
              "code": "430602"
            },
            {
              "area": "云溪区",
              "code": "430603"
            },
            {
              "area": "君山区",
              "code": "430611"
            },
            {
              "area": "岳阳县",
              "code": "430621"
            },
            {
              "area": "华容县",
              "code": "430623"
            },
            {
              "area": "湘阴县",
              "code": "430624"
            },
            {
              "area": "平江县",
              "code": "430626"
            },
            {
              "area": "岳阳市屈原管理区",
              "code": "430671"
            },
            {
              "area": "汨罗市",
              "code": "430681"
            },
            {
              "area": "临湘市",
              "code": "430682"
            }
          ]
        },
        {
          "province": "常德市",
          "code": "430700",
          "areas": [
            {
              "area": "武陵区",
              "code": "430702"
            },
            {
              "area": "鼎城区",
              "code": "430703"
            },
            {
              "area": "安乡县",
              "code": "430721"
            },
            {
              "area": "汉寿县",
              "code": "430722"
            },
            {
              "area": "澧县",
              "code": "430723"
            },
            {
              "area": "临澧县",
              "code": "430724"
            },
            {
              "area": "桃源县",
              "code": "430725"
            },
            {
              "area": "石门县",
              "code": "430726"
            },
            {
              "area": "常德市西洞庭管理区",
              "code": "430771"
            },
            {
              "area": "津市市",
              "code": "430781"
            }
          ]
        },
        {
          "province": "张家界市",
          "code": "430800",
          "areas": [
            {
              "area": "永定区",
              "code": "430802"
            },
            {
              "area": "武陵源区",
              "code": "430811"
            },
            {
              "area": "慈利县",
              "code": "430821"
            },
            {
              "area": "桑植县",
              "code": "430822"
            }
          ]
        },
        {
          "province": "益阳市",
          "code": "430900",
          "areas": [
            {
              "area": "资阳区",
              "code": "430902"
            },
            {
              "area": "赫山区",
              "code": "430903"
            },
            {
              "area": "南县",
              "code": "430921"
            },
            {
              "area": "桃江县",
              "code": "430922"
            },
            {
              "area": "安化县",
              "code": "430923"
            },
            {
              "area": "益阳市大通湖管理区",
              "code": "430971"
            },
            {
              "area": "湖南益阳高新技术产业园区",
              "code": "430972"
            },
            {
              "area": "沅江市",
              "code": "430981"
            }
          ]
        },
        {
          "province": "郴州市",
          "code": "431000",
          "areas": [
            {
              "area": "北湖区",
              "code": "431002"
            },
            {
              "area": "苏仙区",
              "code": "431003"
            },
            {
              "area": "桂阳县",
              "code": "431021"
            },
            {
              "area": "宜章县",
              "code": "431022"
            },
            {
              "area": "永兴县",
              "code": "431023"
            },
            {
              "area": "嘉禾县",
              "code": "431024"
            },
            {
              "area": "临武县",
              "code": "431025"
            },
            {
              "area": "汝城县",
              "code": "431026"
            },
            {
              "area": "桂东县",
              "code": "431027"
            },
            {
              "area": "安仁县",
              "code": "431028"
            },
            {
              "area": "资兴市",
              "code": "431081"
            }
          ]
        },
        {
          "province": "永州市",
          "code": "431100",
          "areas": [
            {
              "area": "零陵区",
              "code": "431102"
            },
            {
              "area": "冷水滩区",
              "code": "431103"
            },
            {
              "area": "东安县",
              "code": "431122"
            },
            {
              "area": "双牌县",
              "code": "431123"
            },
            {
              "area": "道县",
              "code": "431124"
            },
            {
              "area": "江永县",
              "code": "431125"
            },
            {
              "area": "宁远县",
              "code": "431126"
            },
            {
              "area": "蓝山县",
              "code": "431127"
            },
            {
              "area": "新田县",
              "code": "431128"
            },
            {
              "area": "江华瑶族自治县",
              "code": "431129"
            },
            {
              "area": "永州经济技术开发区",
              "code": "431171"
            },
            {
              "area": "永州市回龙圩管理区",
              "code": "431173"
            },
            {
              "area": "祁阳市",
              "code": "431181"
            }
          ]
        },
        {
          "province": "怀化市",
          "code": "431200",
          "areas": [
            {
              "area": "鹤城区",
              "code": "431202"
            },
            {
              "area": "中方县",
              "code": "431221"
            },
            {
              "area": "沅陵县",
              "code": "431222"
            },
            {
              "area": "辰溪县",
              "code": "431223"
            },
            {
              "area": "溆浦县",
              "code": "431224"
            },
            {
              "area": "会同县",
              "code": "431225"
            },
            {
              "area": "麻阳苗族自治县",
              "code": "431226"
            },
            {
              "area": "新晃侗族自治县",
              "code": "431227"
            },
            {
              "area": "芷江侗族自治县",
              "code": "431228"
            },
            {
              "area": "靖州苗族侗族自治县",
              "code": "431229"
            },
            {
              "area": "通道侗族自治县",
              "code": "431230"
            },
            {
              "area": "怀化市洪江管理区",
              "code": "431271"
            },
            {
              "area": "洪江市",
              "code": "431281"
            }
          ]
        },
        {
          "province": "娄底市",
          "code": "431300",
          "areas": [
            {
              "area": "娄星区",
              "code": "431302"
            },
            {
              "area": "双峰县",
              "code": "431321"
            },
            {
              "area": "新化县",
              "code": "431322"
            },
            {
              "area": "冷水江市",
              "code": "431381"
            },
            {
              "area": "涟源市",
              "code": "431382"
            }
          ]
        },
        {
          "province": "湘西土家族苗族自治州",
          "code": "433100",
          "areas": [
            {
              "area": "吉首市",
              "code": "433101"
            },
            {
              "area": "泸溪县",
              "code": "433122"
            },
            {
              "area": "凤凰县",
              "code": "433123"
            },
            {
              "area": "花垣县",
              "code": "433124"
            },
            {
              "area": "保靖县",
              "code": "433125"
            },
            {
              "area": "古丈县",
              "code": "433126"
            },
            {
              "area": "永顺县",
              "code": "433127"
            },
            {
              "area": "龙山县",
              "code": "433130"
            }
          ]
        }
      ]
    },
    {
      "province": "广东省",
      "code": "440000",
      "provinces": [
        {
          "province": "广州市",
          "code": "440100",
          "areas": [
            {
              "area": "荔湾区",
              "code": "440103"
            },
            {
              "area": "越秀区",
              "code": "440104"
            },
            {
              "area": "海珠区",
              "code": "440105"
            },
            {
              "area": "天河区",
              "code": "440106"
            },
            {
              "area": "白云区",
              "code": "440111"
            },
            {
              "area": "黄埔区",
              "code": "440112"
            },
            {
              "area": "番禺区",
              "code": "440113"
            },
            {
              "area": "花都区",
              "code": "440114"
            },
            {
              "area": "南沙区",
              "code": "440115"
            },
            {
              "area": "从化区",
              "code": "440117"
            },
            {
              "area": "增城区",
              "code": "440118"
            }
          ]
        },
        {
          "province": "韶关市",
          "code": "440200",
          "areas": [
            {
              "area": "武江区",
              "code": "440203"
            },
            {
              "area": "浈江区",
              "code": "440204"
            },
            {
              "area": "曲江区",
              "code": "440205"
            },
            {
              "area": "始兴县",
              "code": "440222"
            },
            {
              "area": "仁化县",
              "code": "440224"
            },
            {
              "area": "翁源县",
              "code": "440229"
            },
            {
              "area": "乳源瑶族自治县",
              "code": "440232"
            },
            {
              "area": "新丰县",
              "code": "440233"
            },
            {
              "area": "乐昌市",
              "code": "440281"
            },
            {
              "area": "南雄市",
              "code": "440282"
            }
          ]
        },
        {
          "province": "深圳市",
          "code": "440300",
          "areas": [
            {
              "area": "罗湖区",
              "code": "440303"
            },
            {
              "area": "福田区",
              "code": "440304"
            },
            {
              "area": "南山区",
              "code": "440305"
            },
            {
              "area": "宝安区",
              "code": "440306"
            },
            {
              "area": "龙岗区",
              "code": "440307"
            },
            {
              "area": "盐田区",
              "code": "440308"
            },
            {
              "area": "龙华区",
              "code": "440309"
            },
            {
              "area": "坪山区",
              "code": "440310"
            },
            {
              "area": "光明区",
              "code": "440311"
            }
          ]
        },
        {
          "province": "珠海市",
          "code": "440400",
          "areas": [
            {
              "area": "香洲区",
              "code": "440402"
            },
            {
              "area": "斗门区",
              "code": "440403"
            },
            {
              "area": "金湾区",
              "code": "440404"
            }
          ]
        },
        {
          "province": "汕头市",
          "code": "440500",
          "areas": [
            {
              "area": "龙湖区",
              "code": "440507"
            },
            {
              "area": "金平区",
              "code": "440511"
            },
            {
              "area": "濠江区",
              "code": "440512"
            },
            {
              "area": "潮阳区",
              "code": "440513"
            },
            {
              "area": "潮南区",
              "code": "440514"
            },
            {
              "area": "澄海区",
              "code": "440515"
            },
            {
              "area": "南澳县",
              "code": "440523"
            }
          ]
        },
        {
          "province": "佛山市",
          "code": "440600",
          "areas": [
            {
              "area": "禅城区",
              "code": "440604"
            },
            {
              "area": "南海区",
              "code": "440605"
            },
            {
              "area": "顺德区",
              "code": "440606"
            },
            {
              "area": "三水区",
              "code": "440607"
            },
            {
              "area": "高明区",
              "code": "440608"
            }
          ]
        },
        {
          "province": "江门市",
          "code": "440700",
          "areas": [
            {
              "area": "蓬江区",
              "code": "440703"
            },
            {
              "area": "江海区",
              "code": "440704"
            },
            {
              "area": "新会区",
              "code": "440705"
            },
            {
              "area": "台山市",
              "code": "440781"
            },
            {
              "area": "开平市",
              "code": "440783"
            },
            {
              "area": "鹤山市",
              "code": "440784"
            },
            {
              "area": "恩平市",
              "code": "440785"
            }
          ]
        },
        {
          "province": "湛江市",
          "code": "440800",
          "areas": [
            {
              "area": "赤坎区",
              "code": "440802"
            },
            {
              "area": "霞山区",
              "code": "440803"
            },
            {
              "area": "坡头区",
              "code": "440804"
            },
            {
              "area": "麻章区",
              "code": "440811"
            },
            {
              "area": "遂溪县",
              "code": "440823"
            },
            {
              "area": "徐闻县",
              "code": "440825"
            },
            {
              "area": "廉江市",
              "code": "440881"
            },
            {
              "area": "雷州市",
              "code": "440882"
            },
            {
              "area": "吴川市",
              "code": "440883"
            }
          ]
        },
        {
          "province": "茂名市",
          "code": "440900",
          "areas": [
            {
              "area": "茂南区",
              "code": "440902"
            },
            {
              "area": "电白区",
              "code": "440904"
            },
            {
              "area": "高州市",
              "code": "440981"
            },
            {
              "area": "化州市",
              "code": "440982"
            },
            {
              "area": "信宜市",
              "code": "440983"
            }
          ]
        },
        {
          "province": "肇庆市",
          "code": "441200",
          "areas": [
            {
              "area": "端州区",
              "code": "441202"
            },
            {
              "area": "鼎湖区",
              "code": "441203"
            },
            {
              "area": "高要区",
              "code": "441204"
            },
            {
              "area": "广宁县",
              "code": "441223"
            },
            {
              "area": "怀集县",
              "code": "441224"
            },
            {
              "area": "封开县",
              "code": "441225"
            },
            {
              "area": "德庆县",
              "code": "441226"
            },
            {
              "area": "四会市",
              "code": "441284"
            }
          ]
        },
        {
          "province": "惠州市",
          "code": "441300",
          "areas": [
            {
              "area": "惠城区",
              "code": "441302"
            },
            {
              "area": "惠阳区",
              "code": "441303"
            },
            {
              "area": "博罗县",
              "code": "441322"
            },
            {
              "area": "惠东县",
              "code": "441323"
            },
            {
              "area": "龙门县",
              "code": "441324"
            }
          ]
        },
        {
          "province": "梅州市",
          "code": "441400",
          "areas": [
            {
              "area": "梅江区",
              "code": "441402"
            },
            {
              "area": "梅县区",
              "code": "441403"
            },
            {
              "area": "大埔县",
              "code": "441422"
            },
            {
              "area": "丰顺县",
              "code": "441423"
            },
            {
              "area": "五华县",
              "code": "441424"
            },
            {
              "area": "平远县",
              "code": "441426"
            },
            {
              "area": "蕉岭县",
              "code": "441427"
            },
            {
              "area": "兴宁市",
              "code": "441481"
            }
          ]
        },
        {
          "province": "汕尾市",
          "code": "441500",
          "areas": [
            {
              "area": "城区",
              "code": "441502"
            },
            {
              "area": "海丰县",
              "code": "441521"
            },
            {
              "area": "陆河县",
              "code": "441523"
            },
            {
              "area": "陆丰市",
              "code": "441581"
            }
          ]
        },
        {
          "province": "河源市",
          "code": "441600",
          "areas": [
            {
              "area": "源城区",
              "code": "441602"
            },
            {
              "area": "紫金县",
              "code": "441621"
            },
            {
              "area": "龙川县",
              "code": "441622"
            },
            {
              "area": "连平县",
              "code": "441623"
            },
            {
              "area": "和平县",
              "code": "441624"
            },
            {
              "area": "东源县",
              "code": "441625"
            }
          ]
        },
        {
          "province": "阳江市",
          "code": "441700",
          "areas": [
            {
              "area": "江城区",
              "code": "441702"
            },
            {
              "area": "阳东区",
              "code": "441704"
            },
            {
              "area": "阳西县",
              "code": "441721"
            },
            {
              "area": "阳春市",
              "code": "441781"
            }
          ]
        },
        {
          "province": "清远市",
          "code": "441800",
          "areas": [
            {
              "area": "清城区",
              "code": "441802"
            },
            {
              "area": "清新区",
              "code": "441803"
            },
            {
              "area": "佛冈县",
              "code": "441821"
            },
            {
              "area": "阳山县",
              "code": "441823"
            },
            {
              "area": "连山壮族瑶族自治县",
              "code": "441825"
            },
            {
              "area": "连南瑶族自治县",
              "code": "441826"
            },
            {
              "area": "英德市",
              "code": "441881"
            },
            {
              "area": "连州市",
              "code": "441882"
            }
          ]
        },
        {
          "province": "东莞市",
          "code": "441900",
          "areas": [
            {
              "area": "东城街道",
              "code": "441900003000"
            },
            {
              "area": "南城街道",
              "code": "441900004000"
            },
            {
              "area": "万江街道",
              "code": "441900005000"
            },
            {
              "area": "莞城街道",
              "code": "441900006000"
            },
            {
              "area": "石碣镇",
              "code": "441900101000"
            },
            {
              "area": "石龙镇",
              "code": "441900102000"
            },
            {
              "area": "茶山镇",
              "code": "441900103000"
            },
            {
              "area": "石排镇",
              "code": "441900104000"
            },
            {
              "area": "企石镇",
              "code": "441900105000"
            },
            {
              "area": "横沥镇",
              "code": "441900106000"
            },
            {
              "area": "桥头镇",
              "code": "441900107000"
            },
            {
              "area": "谢岗镇",
              "code": "441900108000"
            },
            {
              "area": "东坑镇",
              "code": "441900109000"
            },
            {
              "area": "常平镇",
              "code": "441900110000"
            },
            {
              "area": "寮步镇",
              "code": "441900111000"
            },
            {
              "area": "樟木头镇",
              "code": "441900112000"
            },
            {
              "area": "大朗镇",
              "code": "441900113000"
            },
            {
              "area": "黄江镇",
              "code": "441900114000"
            },
            {
              "area": "清溪镇",
              "code": "441900115000"
            },
            {
              "area": "塘厦镇",
              "code": "441900116000"
            },
            {
              "area": "凤岗镇",
              "code": "441900117000"
            },
            {
              "area": "大岭山镇",
              "code": "441900118000"
            },
            {
              "area": "长安镇",
              "code": "441900119000"
            },
            {
              "area": "虎门镇",
              "code": "441900121000"
            },
            {
              "area": "厚街镇",
              "code": "441900122000"
            },
            {
              "area": "沙田镇",
              "code": "441900123000"
            },
            {
              "area": "道滘镇",
              "code": "441900124000"
            },
            {
              "area": "洪梅镇",
              "code": "441900125000"
            },
            {
              "area": "麻涌镇",
              "code": "441900126000"
            },
            {
              "area": "望牛墩镇",
              "code": "441900127000"
            },
            {
              "area": "中堂镇",
              "code": "441900128000"
            },
            {
              "area": "高埗镇",
              "code": "441900129000"
            },
            {
              "area": "松山湖",
              "code": "441900401000"
            },
            {
              "area": "东莞港",
              "code": "441900402000"
            },
            {
              "area": "东莞生态园",
              "code": "441900403000"
            },
            {
              "area": "东莞滨海湾新区",
              "code": "441900404000"
            }
          ]
        },
        {
          "province": "中山市",
          "code": "442000",
          "areas": [
            {
              "area": "石岐街道",
              "code": "442000001000"
            },
            {
              "area": "东区街道",
              "code": "442000002000"
            },
            {
              "area": "中山港街道",
              "code": "442000003000"
            },
            {
              "area": "西区街道",
              "code": "442000004000"
            },
            {
              "area": "南区街道",
              "code": "442000005000"
            },
            {
              "area": "五桂山街道",
              "code": "442000006000"
            },
            {
              "area": "民众街道",
              "code": "442000007000"
            },
            {
              "area": "南朗街道",
              "code": "442000008000"
            },
            {
              "area": "黄圃镇",
              "code": "442000101000"
            },
            {
              "area": "东凤镇",
              "code": "442000103000"
            },
            {
              "area": "古镇镇",
              "code": "442000105000"
            },
            {
              "area": "沙溪镇",
              "code": "442000106000"
            },
            {
              "area": "坦洲镇",
              "code": "442000107000"
            },
            {
              "area": "港口镇",
              "code": "442000108000"
            },
            {
              "area": "三角镇",
              "code": "442000109000"
            },
            {
              "area": "横栏镇",
              "code": "442000110000"
            },
            {
              "area": "南头镇",
              "code": "442000111000"
            },
            {
              "area": "阜沙镇",
              "code": "442000112000"
            },
            {
              "area": "三乡镇",
              "code": "442000114000"
            },
            {
              "area": "板芙镇",
              "code": "442000115000"
            },
            {
              "area": "大涌镇",
              "code": "442000116000"
            },
            {
              "area": "神湾镇",
              "code": "442000117000"
            },
            {
              "area": "小榄镇",
              "code": "442000118000"
            }
          ]
        },
        {
          "province": "潮州市",
          "code": "445100",
          "areas": [
            {
              "area": "湘桥区",
              "code": "445102"
            },
            {
              "area": "潮安区",
              "code": "445103"
            },
            {
              "area": "饶平县",
              "code": "445122"
            }
          ]
        },
        {
          "province": "揭阳市",
          "code": "445200",
          "areas": [
            {
              "area": "榕城区",
              "code": "445202"
            },
            {
              "area": "揭东区",
              "code": "445203"
            },
            {
              "area": "揭西县",
              "code": "445222"
            },
            {
              "area": "惠来县",
              "code": "445224"
            },
            {
              "area": "普宁市",
              "code": "445281"
            }
          ]
        },
        {
          "province": "云浮市",
          "code": "445300",
          "areas": [
            {
              "area": "云城区",
              "code": "445302"
            },
            {
              "area": "云安区",
              "code": "445303"
            },
            {
              "area": "新兴县",
              "code": "445321"
            },
            {
              "area": "郁南县",
              "code": "445322"
            },
            {
              "area": "罗定市",
              "code": "445381"
            }
          ]
        }
      ]
    },
    {
      "province": "广西壮族自治区",
      "code": "450000",
      "provinces": [
        {
          "province": "南宁市",
          "code": "450100",
          "areas": [
            {
              "area": "兴宁区",
              "code": "450102"
            },
            {
              "area": "青秀区",
              "code": "450103"
            },
            {
              "area": "江南区",
              "code": "450105"
            },
            {
              "area": "西乡塘区",
              "code": "450107"
            },
            {
              "area": "良庆区",
              "code": "450108"
            },
            {
              "area": "邕宁区",
              "code": "450109"
            },
            {
              "area": "武鸣区",
              "code": "450110"
            },
            {
              "area": "隆安县",
              "code": "450123"
            },
            {
              "area": "马山县",
              "code": "450124"
            },
            {
              "area": "上林县",
              "code": "450125"
            },
            {
              "area": "宾阳县",
              "code": "450126"
            },
            {
              "area": "横州市",
              "code": "450181"
            }
          ]
        },
        {
          "province": "柳州市",
          "code": "450200",
          "areas": [
            {
              "area": "城中区",
              "code": "450202"
            },
            {
              "area": "鱼峰区",
              "code": "450203"
            },
            {
              "area": "柳南区",
              "code": "450204"
            },
            {
              "area": "柳北区",
              "code": "450205"
            },
            {
              "area": "柳江区",
              "code": "450206"
            },
            {
              "area": "柳城县",
              "code": "450222"
            },
            {
              "area": "鹿寨县",
              "code": "450223"
            },
            {
              "area": "融安县",
              "code": "450224"
            },
            {
              "area": "融水苗族自治县",
              "code": "450225"
            },
            {
              "area": "三江侗族自治县",
              "code": "450226"
            }
          ]
        },
        {
          "province": "桂林市",
          "code": "450300",
          "areas": [
            {
              "area": "秀峰区",
              "code": "450302"
            },
            {
              "area": "叠彩区",
              "code": "450303"
            },
            {
              "area": "象山区",
              "code": "450304"
            },
            {
              "area": "七星区",
              "code": "450305"
            },
            {
              "area": "雁山区",
              "code": "450311"
            },
            {
              "area": "临桂区",
              "code": "450312"
            },
            {
              "area": "阳朔县",
              "code": "450321"
            },
            {
              "area": "灵川县",
              "code": "450323"
            },
            {
              "area": "全州县",
              "code": "450324"
            },
            {
              "area": "兴安县",
              "code": "450325"
            },
            {
              "area": "永福县",
              "code": "450326"
            },
            {
              "area": "灌阳县",
              "code": "450327"
            },
            {
              "area": "龙胜各族自治县",
              "code": "450328"
            },
            {
              "area": "资源县",
              "code": "450329"
            },
            {
              "area": "平乐县",
              "code": "450330"
            },
            {
              "area": "恭城瑶族自治县",
              "code": "450332"
            },
            {
              "area": "荔浦市",
              "code": "450381"
            }
          ]
        },
        {
          "province": "梧州市",
          "code": "450400",
          "areas": [
            {
              "area": "万秀区",
              "code": "450403"
            },
            {
              "area": "长洲区",
              "code": "450405"
            },
            {
              "area": "龙圩区",
              "code": "450406"
            },
            {
              "area": "苍梧县",
              "code": "450421"
            },
            {
              "area": "藤县",
              "code": "450422"
            },
            {
              "area": "蒙山县",
              "code": "450423"
            },
            {
              "area": "岑溪市",
              "code": "450481"
            }
          ]
        },
        {
          "province": "北海市",
          "code": "450500",
          "areas": [
            {
              "area": "海城区",
              "code": "450502"
            },
            {
              "area": "银海区",
              "code": "450503"
            },
            {
              "area": "铁山港区",
              "code": "450512"
            },
            {
              "area": "合浦县",
              "code": "450521"
            }
          ]
        },
        {
          "province": "防城港市",
          "code": "450600",
          "areas": [
            {
              "area": "港口区",
              "code": "450602"
            },
            {
              "area": "防城区",
              "code": "450603"
            },
            {
              "area": "上思县",
              "code": "450621"
            },
            {
              "area": "东兴市",
              "code": "450681"
            }
          ]
        },
        {
          "province": "钦州市",
          "code": "450700",
          "areas": [
            {
              "area": "钦南区",
              "code": "450702"
            },
            {
              "area": "钦北区",
              "code": "450703"
            },
            {
              "area": "灵山县",
              "code": "450721"
            },
            {
              "area": "浦北县",
              "code": "450722"
            }
          ]
        },
        {
          "province": "贵港市",
          "code": "450800",
          "areas": [
            {
              "area": "港北区",
              "code": "450802"
            },
            {
              "area": "港南区",
              "code": "450803"
            },
            {
              "area": "覃塘区",
              "code": "450804"
            },
            {
              "area": "平南县",
              "code": "450821"
            },
            {
              "area": "桂平市",
              "code": "450881"
            }
          ]
        },
        {
          "province": "玉林市",
          "code": "450900",
          "areas": [
            {
              "area": "玉州区",
              "code": "450902"
            },
            {
              "area": "福绵区",
              "code": "450903"
            },
            {
              "area": "容县",
              "code": "450921"
            },
            {
              "area": "陆川县",
              "code": "450922"
            },
            {
              "area": "博白县",
              "code": "450923"
            },
            {
              "area": "兴业县",
              "code": "450924"
            },
            {
              "area": "北流市",
              "code": "450981"
            }
          ]
        },
        {
          "province": "百色市",
          "code": "451000",
          "areas": [
            {
              "area": "右江区",
              "code": "451002"
            },
            {
              "area": "田阳区",
              "code": "451003"
            },
            {
              "area": "田东县",
              "code": "451022"
            },
            {
              "area": "德保县",
              "code": "451024"
            },
            {
              "area": "那坡县",
              "code": "451026"
            },
            {
              "area": "凌云县",
              "code": "451027"
            },
            {
              "area": "乐业县",
              "code": "451028"
            },
            {
              "area": "田林县",
              "code": "451029"
            },
            {
              "area": "西林县",
              "code": "451030"
            },
            {
              "area": "隆林各族自治县",
              "code": "451031"
            },
            {
              "area": "靖西市",
              "code": "451081"
            },
            {
              "area": "平果市",
              "code": "451082"
            }
          ]
        },
        {
          "province": "贺州市",
          "code": "451100",
          "areas": [
            {
              "area": "八步区",
              "code": "451102"
            },
            {
              "area": "平桂区",
              "code": "451103"
            },
            {
              "area": "昭平县",
              "code": "451121"
            },
            {
              "area": "钟山县",
              "code": "451122"
            },
            {
              "area": "富川瑶族自治县",
              "code": "451123"
            }
          ]
        },
        {
          "province": "河池市",
          "code": "451200",
          "areas": [
            {
              "area": "金城江区",
              "code": "451202"
            },
            {
              "area": "宜州区",
              "code": "451203"
            },
            {
              "area": "南丹县",
              "code": "451221"
            },
            {
              "area": "天峨县",
              "code": "451222"
            },
            {
              "area": "凤山县",
              "code": "451223"
            },
            {
              "area": "东兰县",
              "code": "451224"
            },
            {
              "area": "罗城仫佬族自治县",
              "code": "451225"
            },
            {
              "area": "环江毛南族自治县",
              "code": "451226"
            },
            {
              "area": "巴马瑶族自治县",
              "code": "451227"
            },
            {
              "area": "都安瑶族自治县",
              "code": "451228"
            },
            {
              "area": "大化瑶族自治县",
              "code": "451229"
            }
          ]
        },
        {
          "province": "来宾市",
          "code": "451300",
          "areas": [
            {
              "area": "兴宾区",
              "code": "451302"
            },
            {
              "area": "忻城县",
              "code": "451321"
            },
            {
              "area": "象州县",
              "code": "451322"
            },
            {
              "area": "武宣县",
              "code": "451323"
            },
            {
              "area": "金秀瑶族自治县",
              "code": "451324"
            },
            {
              "area": "合山市",
              "code": "451381"
            }
          ]
        },
        {
          "province": "崇左市",
          "code": "451400",
          "areas": [
            {
              "area": "江州区",
              "code": "451402"
            },
            {
              "area": "扶绥县",
              "code": "451421"
            },
            {
              "area": "宁明县",
              "code": "451422"
            },
            {
              "area": "龙州县",
              "code": "451423"
            },
            {
              "area": "大新县",
              "code": "451424"
            },
            {
              "area": "天等县",
              "code": "451425"
            },
            {
              "area": "凭祥市",
              "code": "451481"
            }
          ]
        }
      ]
    },
    {
      "province": "海南省",
      "code": "460000",
      "provinces": [
        {
          "province": "海口市",
          "code": "460100",
          "areas": [
            {
              "area": "秀英区",
              "code": "460105"
            },
            {
              "area": "龙华区",
              "code": "460106"
            },
            {
              "area": "琼山区",
              "code": "460107"
            },
            {
              "area": "美兰区",
              "code": "460108"
            }
          ]
        },
        {
          "province": "三亚市",
          "code": "460200",
          "areas": [
            {
              "area": "海棠区",
              "code": "460202"
            },
            {
              "area": "吉阳区",
              "code": "460203"
            },
            {
              "area": "天涯区",
              "code": "460204"
            },
            {
              "area": "崖州区",
              "code": "460205"
            }
          ]
        },
        {
          "province": "三沙市",
          "code": "460300",
          "areas": [
            {
              "area": "西沙群岛",
              "code": "460321"
            },
            {
              "area": "南沙群岛",
              "code": "460322"
            },
            {
              "area": "中沙群岛的岛礁及其海域",
              "code": "460323"
            }
          ]
        },
        {
          "province": "儋州市",
          "code": "460400",
          "areas": [
            {
              "area": "那大镇",
              "code": "460400100000"
            },
            {
              "area": "和庆镇",
              "code": "460400101000"
            },
            {
              "area": "南丰镇",
              "code": "460400102000"
            },
            {
              "area": "大成镇",
              "code": "460400103000"
            },
            {
              "area": "雅星镇",
              "code": "460400104000"
            },
            {
              "area": "兰洋镇",
              "code": "460400105000"
            },
            {
              "area": "光村镇",
              "code": "460400106000"
            },
            {
              "area": "木棠镇",
              "code": "460400107000"
            },
            {
              "area": "海头镇",
              "code": "460400108000"
            },
            {
              "area": "峨蔓镇",
              "code": "460400109000"
            },
            {
              "area": "王五镇",
              "code": "460400111000"
            },
            {
              "area": "白马井镇",
              "code": "460400112000"
            },
            {
              "area": "中和镇",
              "code": "460400113000"
            },
            {
              "area": "排浦镇",
              "code": "460400114000"
            },
            {
              "area": "东成镇",
              "code": "460400115000"
            },
            {
              "area": "新州镇",
              "code": "460400116000"
            },
            {
              "area": "洋浦经济开发区",
              "code": "460400499000"
            },
            {
              "area": "华南热作学院",
              "code": "460400500000"
            }
          ]
        },
        {
          "province": "省直辖县级行政区划",
          "code": "469000",
          "areas": [
            {
              "area": "五指山市",
              "code": "469001"
            },
            {
              "area": "琼海市",
              "code": "469002"
            },
            {
              "area": "文昌市",
              "code": "469005"
            },
            {
              "area": "万宁市",
              "code": "469006"
            },
            {
              "area": "东方市",
              "code": "469007"
            },
            {
              "area": "定安县",
              "code": "469021"
            },
            {
              "area": "屯昌县",
              "code": "469022"
            },
            {
              "area": "澄迈县",
              "code": "469023"
            },
            {
              "area": "临高县",
              "code": "469024"
            },
            {
              "area": "白沙黎族自治县",
              "code": "469025"
            },
            {
              "area": "昌江黎族自治县",
              "code": "469026"
            },
            {
              "area": "乐东黎族自治县",
              "code": "469027"
            },
            {
              "area": "陵水黎族自治县",
              "code": "469028"
            },
            {
              "area": "保亭黎族苗族自治县",
              "code": "469029"
            },
            {
              "area": "琼中黎族苗族自治县",
              "code": "469030"
            }
          ]
        }
      ]
    },
    {
      "province": "重庆市",
      "code": "500000",
      "provinces": [
        {
          "province": "市辖区",
          "code": "500100",
          "areas": [
            {
              "area": "万州区",
              "code": "500101"
            },
            {
              "area": "涪陵区",
              "code": "500102"
            },
            {
              "area": "渝中区",
              "code": "500103"
            },
            {
              "area": "大渡口区",
              "code": "500104"
            },
            {
              "area": "江北区",
              "code": "500105"
            },
            {
              "area": "沙坪坝区",
              "code": "500106"
            },
            {
              "area": "九龙坡区",
              "code": "500107"
            },
            {
              "area": "南岸区",
              "code": "500108"
            },
            {
              "area": "北碚区",
              "code": "500109"
            },
            {
              "area": "綦江区",
              "code": "500110"
            },
            {
              "area": "大足区",
              "code": "500111"
            },
            {
              "area": "渝北区",
              "code": "500112"
            },
            {
              "area": "巴南区",
              "code": "500113"
            },
            {
              "area": "黔江区",
              "code": "500114"
            },
            {
              "area": "长寿区",
              "code": "500115"
            },
            {
              "area": "江津区",
              "code": "500116"
            },
            {
              "area": "合川区",
              "code": "500117"
            },
            {
              "area": "永川区",
              "code": "500118"
            },
            {
              "area": "南川区",
              "code": "500119"
            },
            {
              "area": "璧山区",
              "code": "500120"
            },
            {
              "area": "铜梁区",
              "code": "500151"
            },
            {
              "area": "潼南区",
              "code": "500152"
            },
            {
              "area": "荣昌区",
              "code": "500153"
            },
            {
              "area": "开州区",
              "code": "500154"
            },
            {
              "area": "梁平区",
              "code": "500155"
            },
            {
              "area": "武隆区",
              "code": "500156"
            }
          ]
        },
        {
          "province": "县",
          "code": "500200",
          "areas": [
            {
              "area": "城口县",
              "code": "500229"
            },
            {
              "area": "丰都县",
              "code": "500230"
            },
            {
              "area": "垫江县",
              "code": "500231"
            },
            {
              "area": "忠县",
              "code": "500233"
            },
            {
              "area": "云阳县",
              "code": "500235"
            },
            {
              "area": "奉节县",
              "code": "500236"
            },
            {
              "area": "巫山县",
              "code": "500237"
            },
            {
              "area": "巫溪县",
              "code": "500238"
            },
            {
              "area": "石柱土家族自治县",
              "code": "500240"
            },
            {
              "area": "秀山土家族苗族自治县",
              "code": "500241"
            },
            {
              "area": "酉阳土家族苗族自治县",
              "code": "500242"
            },
            {
              "area": "彭水苗族土家族自治县",
              "code": "500243"
            }
          ]
        }
      ]
    },
    {
      "province": "四川省",
      "code": "510000",
      "provinces": [
        {
          "province": "成都市",
          "code": "510100",
          "areas": [
            {
              "area": "锦江区",
              "code": "510104"
            },
            {
              "area": "青羊区",
              "code": "510105"
            },
            {
              "area": "金牛区",
              "code": "510106"
            },
            {
              "area": "武侯区",
              "code": "510107"
            },
            {
              "area": "成华区",
              "code": "510108"
            },
            {
              "area": "龙泉驿区",
              "code": "510112"
            },
            {
              "area": "青白江区",
              "code": "510113"
            },
            {
              "area": "新都区",
              "code": "510114"
            },
            {
              "area": "温江区",
              "code": "510115"
            },
            {
              "area": "双流区",
              "code": "510116"
            },
            {
              "area": "郫都区",
              "code": "510117"
            },
            {
              "area": "新津区",
              "code": "510118"
            },
            {
              "area": "金堂县",
              "code": "510121"
            },
            {
              "area": "大邑县",
              "code": "510129"
            },
            {
              "area": "蒲江县",
              "code": "510131"
            },
            {
              "area": "都江堰市",
              "code": "510181"
            },
            {
              "area": "彭州市",
              "code": "510182"
            },
            {
              "area": "邛崃市",
              "code": "510183"
            },
            {
              "area": "崇州市",
              "code": "510184"
            },
            {
              "area": "简阳市",
              "code": "510185"
            }
          ]
        },
        {
          "province": "自贡市",
          "code": "510300",
          "areas": [
            {
              "area": "自流井区",
              "code": "510302"
            },
            {
              "area": "贡井区",
              "code": "510303"
            },
            {
              "area": "大安区",
              "code": "510304"
            },
            {
              "area": "沿滩区",
              "code": "510311"
            },
            {
              "area": "荣县",
              "code": "510321"
            },
            {
              "area": "富顺县",
              "code": "510322"
            }
          ]
        },
        {
          "province": "攀枝花市",
          "code": "510400",
          "areas": [
            {
              "area": "东区",
              "code": "510402"
            },
            {
              "area": "西区",
              "code": "510403"
            },
            {
              "area": "仁和区",
              "code": "510411"
            },
            {
              "area": "米易县",
              "code": "510421"
            },
            {
              "area": "盐边县",
              "code": "510422"
            }
          ]
        },
        {
          "province": "泸州市",
          "code": "510500",
          "areas": [
            {
              "area": "江阳区",
              "code": "510502"
            },
            {
              "area": "纳溪区",
              "code": "510503"
            },
            {
              "area": "龙马潭区",
              "code": "510504"
            },
            {
              "area": "泸县",
              "code": "510521"
            },
            {
              "area": "合江县",
              "code": "510522"
            },
            {
              "area": "叙永县",
              "code": "510524"
            },
            {
              "area": "古蔺县",
              "code": "510525"
            }
          ]
        },
        {
          "province": "德阳市",
          "code": "510600",
          "areas": [
            {
              "area": "旌阳区",
              "code": "510603"
            },
            {
              "area": "罗江区",
              "code": "510604"
            },
            {
              "area": "中江县",
              "code": "510623"
            },
            {
              "area": "广汉市",
              "code": "510681"
            },
            {
              "area": "什邡市",
              "code": "510682"
            },
            {
              "area": "绵竹市",
              "code": "510683"
            }
          ]
        },
        {
          "province": "绵阳市",
          "code": "510700",
          "areas": [
            {
              "area": "涪城区",
              "code": "510703"
            },
            {
              "area": "游仙区",
              "code": "510704"
            },
            {
              "area": "安州区",
              "code": "510705"
            },
            {
              "area": "三台县",
              "code": "510722"
            },
            {
              "area": "盐亭县",
              "code": "510723"
            },
            {
              "area": "梓潼县",
              "code": "510725"
            },
            {
              "area": "北川羌族自治县",
              "code": "510726"
            },
            {
              "area": "平武县",
              "code": "510727"
            },
            {
              "area": "江油市",
              "code": "510781"
            }
          ]
        },
        {
          "province": "广元市",
          "code": "510800",
          "areas": [
            {
              "area": "利州区",
              "code": "510802"
            },
            {
              "area": "昭化区",
              "code": "510811"
            },
            {
              "area": "朝天区",
              "code": "510812"
            },
            {
              "area": "旺苍县",
              "code": "510821"
            },
            {
              "area": "青川县",
              "code": "510822"
            },
            {
              "area": "剑阁县",
              "code": "510823"
            },
            {
              "area": "苍溪县",
              "code": "510824"
            }
          ]
        },
        {
          "province": "遂宁市",
          "code": "510900",
          "areas": [
            {
              "area": "船山区",
              "code": "510903"
            },
            {
              "area": "安居区",
              "code": "510904"
            },
            {
              "area": "蓬溪县",
              "code": "510921"
            },
            {
              "area": "大英县",
              "code": "510923"
            },
            {
              "area": "射洪市",
              "code": "510981"
            }
          ]
        },
        {
          "province": "内江市",
          "code": "511000",
          "areas": [
            {
              "area": "市中区",
              "code": "511002"
            },
            {
              "area": "东兴区",
              "code": "511011"
            },
            {
              "area": "威远县",
              "code": "511024"
            },
            {
              "area": "资中县",
              "code": "511025"
            },
            {
              "area": "隆昌市",
              "code": "511083"
            }
          ]
        },
        {
          "province": "乐山市",
          "code": "511100",
          "areas": [
            {
              "area": "市中区",
              "code": "511102"
            },
            {
              "area": "沙湾区",
              "code": "511111"
            },
            {
              "area": "五通桥区",
              "code": "511112"
            },
            {
              "area": "金口河区",
              "code": "511113"
            },
            {
              "area": "犍为县",
              "code": "511123"
            },
            {
              "area": "井研县",
              "code": "511124"
            },
            {
              "area": "夹江县",
              "code": "511126"
            },
            {
              "area": "沐川县",
              "code": "511129"
            },
            {
              "area": "峨边彝族自治县",
              "code": "511132"
            },
            {
              "area": "马边彝族自治县",
              "code": "511133"
            },
            {
              "area": "峨眉山市",
              "code": "511181"
            }
          ]
        },
        {
          "province": "南充市",
          "code": "511300",
          "areas": [
            {
              "area": "顺庆区",
              "code": "511302"
            },
            {
              "area": "高坪区",
              "code": "511303"
            },
            {
              "area": "嘉陵区",
              "code": "511304"
            },
            {
              "area": "南部县",
              "code": "511321"
            },
            {
              "area": "营山县",
              "code": "511322"
            },
            {
              "area": "蓬安县",
              "code": "511323"
            },
            {
              "area": "仪陇县",
              "code": "511324"
            },
            {
              "area": "西充县",
              "code": "511325"
            },
            {
              "area": "阆中市",
              "code": "511381"
            }
          ]
        },
        {
          "province": "眉山市",
          "code": "511400",
          "areas": [
            {
              "area": "东坡区",
              "code": "511402"
            },
            {
              "area": "彭山区",
              "code": "511403"
            },
            {
              "area": "仁寿县",
              "code": "511421"
            },
            {
              "area": "洪雅县",
              "code": "511423"
            },
            {
              "area": "丹棱县",
              "code": "511424"
            },
            {
              "area": "青神县",
              "code": "511425"
            }
          ]
        },
        {
          "province": "宜宾市",
          "code": "511500",
          "areas": [
            {
              "area": "翠屏区",
              "code": "511502"
            },
            {
              "area": "南溪区",
              "code": "511503"
            },
            {
              "area": "叙州区",
              "code": "511504"
            },
            {
              "area": "江安县",
              "code": "511523"
            },
            {
              "area": "长宁县",
              "code": "511524"
            },
            {
              "area": "高县",
              "code": "511525"
            },
            {
              "area": "珙县",
              "code": "511526"
            },
            {
              "area": "筠连县",
              "code": "511527"
            },
            {
              "area": "兴文县",
              "code": "511528"
            },
            {
              "area": "屏山县",
              "code": "511529"
            }
          ]
        },
        {
          "province": "广安市",
          "code": "511600",
          "areas": [
            {
              "area": "广安区",
              "code": "511602"
            },
            {
              "area": "前锋区",
              "code": "511603"
            },
            {
              "area": "岳池县",
              "code": "511621"
            },
            {
              "area": "武胜县",
              "code": "511622"
            },
            {
              "area": "邻水县",
              "code": "511623"
            },
            {
              "area": "华蓥市",
              "code": "511681"
            }
          ]
        },
        {
          "province": "达州市",
          "code": "511700",
          "areas": [
            {
              "area": "通川区",
              "code": "511702"
            },
            {
              "area": "达川区",
              "code": "511703"
            },
            {
              "area": "宣汉县",
              "code": "511722"
            },
            {
              "area": "开江县",
              "code": "511723"
            },
            {
              "area": "大竹县",
              "code": "511724"
            },
            {
              "area": "渠县",
              "code": "511725"
            },
            {
              "area": "万源市",
              "code": "511781"
            }
          ]
        },
        {
          "province": "雅安市",
          "code": "511800",
          "areas": [
            {
              "area": "雨城区",
              "code": "511802"
            },
            {
              "area": "名山区",
              "code": "511803"
            },
            {
              "area": "荥经县",
              "code": "511822"
            },
            {
              "area": "汉源县",
              "code": "511823"
            },
            {
              "area": "石棉县",
              "code": "511824"
            },
            {
              "area": "天全县",
              "code": "511825"
            },
            {
              "area": "芦山县",
              "code": "511826"
            },
            {
              "area": "宝兴县",
              "code": "511827"
            }
          ]
        },
        {
          "province": "巴中市",
          "code": "511900",
          "areas": [
            {
              "area": "巴州区",
              "code": "511902"
            },
            {
              "area": "恩阳区",
              "code": "511903"
            },
            {
              "area": "通江县",
              "code": "511921"
            },
            {
              "area": "南江县",
              "code": "511922"
            },
            {
              "area": "平昌县",
              "code": "511923"
            }
          ]
        },
        {
          "province": "资阳市",
          "code": "512000",
          "areas": [
            {
              "area": "雁江区",
              "code": "512002"
            },
            {
              "area": "安岳县",
              "code": "512021"
            },
            {
              "area": "乐至县",
              "code": "512022"
            }
          ]
        },
        {
          "province": "阿坝藏族羌族自治州",
          "code": "513200",
          "areas": [
            {
              "area": "马尔康市",
              "code": "513201"
            },
            {
              "area": "汶川县",
              "code": "513221"
            },
            {
              "area": "理县",
              "code": "513222"
            },
            {
              "area": "茂县",
              "code": "513223"
            },
            {
              "area": "松潘县",
              "code": "513224"
            },
            {
              "area": "九寨沟县",
              "code": "513225"
            },
            {
              "area": "金川县",
              "code": "513226"
            },
            {
              "area": "小金县",
              "code": "513227"
            },
            {
              "area": "黑水县",
              "code": "513228"
            },
            {
              "area": "壤塘县",
              "code": "513230"
            },
            {
              "area": "阿坝县",
              "code": "513231"
            },
            {
              "area": "若尔盖县",
              "code": "513232"
            },
            {
              "area": "红原县",
              "code": "513233"
            }
          ]
        },
        {
          "province": "甘孜藏族自治州",
          "code": "513300",
          "areas": [
            {
              "area": "康定市",
              "code": "513301"
            },
            {
              "area": "泸定县",
              "code": "513322"
            },
            {
              "area": "丹巴县",
              "code": "513323"
            },
            {
              "area": "九龙县",
              "code": "513324"
            },
            {
              "area": "雅江县",
              "code": "513325"
            },
            {
              "area": "道孚县",
              "code": "513326"
            },
            {
              "area": "炉霍县",
              "code": "513327"
            },
            {
              "area": "甘孜县",
              "code": "513328"
            },
            {
              "area": "新龙县",
              "code": "513329"
            },
            {
              "area": "德格县",
              "code": "513330"
            },
            {
              "area": "白玉县",
              "code": "513331"
            },
            {
              "area": "石渠县",
              "code": "513332"
            },
            {
              "area": "色达县",
              "code": "513333"
            },
            {
              "area": "理塘县",
              "code": "513334"
            },
            {
              "area": "巴塘县",
              "code": "513335"
            },
            {
              "area": "乡城县",
              "code": "513336"
            },
            {
              "area": "稻城县",
              "code": "513337"
            },
            {
              "area": "得荣县",
              "code": "513338"
            }
          ]
        },
        {
          "province": "凉山彝族自治州",
          "code": "513400",
          "areas": [
            {
              "area": "西昌市",
              "code": "513401"
            },
            {
              "area": "会理市",
              "code": "513402"
            },
            {
              "area": "木里藏族自治县",
              "code": "513422"
            },
            {
              "area": "盐源县",
              "code": "513423"
            },
            {
              "area": "德昌县",
              "code": "513424"
            },
            {
              "area": "会东县",
              "code": "513426"
            },
            {
              "area": "宁南县",
              "code": "513427"
            },
            {
              "area": "普格县",
              "code": "513428"
            },
            {
              "area": "布拖县",
              "code": "513429"
            },
            {
              "area": "金阳县",
              "code": "513430"
            },
            {
              "area": "昭觉县",
              "code": "513431"
            },
            {
              "area": "喜德县",
              "code": "513432"
            },
            {
              "area": "冕宁县",
              "code": "513433"
            },
            {
              "area": "越西县",
              "code": "513434"
            },
            {
              "area": "甘洛县",
              "code": "513435"
            },
            {
              "area": "美姑县",
              "code": "513436"
            },
            {
              "area": "雷波县",
              "code": "513437"
            }
          ]
        }
      ]
    },
    {
      "province": "贵州省",
      "code": "520000",
      "provinces": [
        {
          "province": "贵阳市",
          "code": "520100",
          "areas": [
            {
              "area": "南明区",
              "code": "520102"
            },
            {
              "area": "云岩区",
              "code": "520103"
            },
            {
              "area": "花溪区",
              "code": "520111"
            },
            {
              "area": "乌当区",
              "code": "520112"
            },
            {
              "area": "白云区",
              "code": "520113"
            },
            {
              "area": "观山湖区",
              "code": "520115"
            },
            {
              "area": "开阳县",
              "code": "520121"
            },
            {
              "area": "息烽县",
              "code": "520122"
            },
            {
              "area": "修文县",
              "code": "520123"
            },
            {
              "area": "清镇市",
              "code": "520181"
            }
          ]
        },
        {
          "province": "六盘水市",
          "code": "520200",
          "areas": [
            {
              "area": "钟山区",
              "code": "520201"
            },
            {
              "area": "六枝特区",
              "code": "520203"
            },
            {
              "area": "水城区",
              "code": "520204"
            },
            {
              "area": "盘州市",
              "code": "520281"
            }
          ]
        },
        {
          "province": "遵义市",
          "code": "520300",
          "areas": [
            {
              "area": "红花岗区",
              "code": "520302"
            },
            {
              "area": "汇川区",
              "code": "520303"
            },
            {
              "area": "播州区",
              "code": "520304"
            },
            {
              "area": "桐梓县",
              "code": "520322"
            },
            {
              "area": "绥阳县",
              "code": "520323"
            },
            {
              "area": "正安县",
              "code": "520324"
            },
            {
              "area": "道真仡佬族苗族自治县",
              "code": "520325"
            },
            {
              "area": "务川仡佬族苗族自治县",
              "code": "520326"
            },
            {
              "area": "凤冈县",
              "code": "520327"
            },
            {
              "area": "湄潭县",
              "code": "520328"
            },
            {
              "area": "余庆县",
              "code": "520329"
            },
            {
              "area": "习水县",
              "code": "520330"
            },
            {
              "area": "赤水市",
              "code": "520381"
            },
            {
              "area": "仁怀市",
              "code": "520382"
            }
          ]
        },
        {
          "province": "安顺市",
          "code": "520400",
          "areas": [
            {
              "area": "西秀区",
              "code": "520402"
            },
            {
              "area": "平坝区",
              "code": "520403"
            },
            {
              "area": "普定县",
              "code": "520422"
            },
            {
              "area": "镇宁布依族苗族自治县",
              "code": "520423"
            },
            {
              "area": "关岭布依族苗族自治县",
              "code": "520424"
            },
            {
              "area": "紫云苗族布依族自治县",
              "code": "520425"
            }
          ]
        },
        {
          "province": "毕节市",
          "code": "520500",
          "areas": [
            {
              "area": "七星关区",
              "code": "520502"
            },
            {
              "area": "大方县",
              "code": "520521"
            },
            {
              "area": "金沙县",
              "code": "520523"
            },
            {
              "area": "织金县",
              "code": "520524"
            },
            {
              "area": "纳雍县",
              "code": "520525"
            },
            {
              "area": "威宁彝族回族苗族自治县",
              "code": "520526"
            },
            {
              "area": "赫章县",
              "code": "520527"
            },
            {
              "area": "黔西市",
              "code": "520581"
            }
          ]
        },
        {
          "province": "铜仁市",
          "code": "520600",
          "areas": [
            {
              "area": "碧江区",
              "code": "520602"
            },
            {
              "area": "万山区",
              "code": "520603"
            },
            {
              "area": "江口县",
              "code": "520621"
            },
            {
              "area": "玉屏侗族自治县",
              "code": "520622"
            },
            {
              "area": "石阡县",
              "code": "520623"
            },
            {
              "area": "思南县",
              "code": "520624"
            },
            {
              "area": "印江土家族苗族自治县",
              "code": "520625"
            },
            {
              "area": "德江县",
              "code": "520626"
            },
            {
              "area": "沿河土家族自治县",
              "code": "520627"
            },
            {
              "area": "松桃苗族自治县",
              "code": "520628"
            }
          ]
        },
        {
          "province": "黔西南布依族苗族自治州",
          "code": "522300",
          "areas": [
            {
              "area": "兴义市",
              "code": "522301"
            },
            {
              "area": "兴仁市",
              "code": "522302"
            },
            {
              "area": "普安县",
              "code": "522323"
            },
            {
              "area": "晴隆县",
              "code": "522324"
            },
            {
              "area": "贞丰县",
              "code": "522325"
            },
            {
              "area": "望谟县",
              "code": "522326"
            },
            {
              "area": "册亨县",
              "code": "522327"
            },
            {
              "area": "安龙县",
              "code": "522328"
            }
          ]
        },
        {
          "province": "黔东南苗族侗族自治州",
          "code": "522600",
          "areas": [
            {
              "area": "凯里市",
              "code": "522601"
            },
            {
              "area": "黄平县",
              "code": "522622"
            },
            {
              "area": "施秉县",
              "code": "522623"
            },
            {
              "area": "三穗县",
              "code": "522624"
            },
            {
              "area": "镇远县",
              "code": "522625"
            },
            {
              "area": "岑巩县",
              "code": "522626"
            },
            {
              "area": "天柱县",
              "code": "522627"
            },
            {
              "area": "锦屏县",
              "code": "522628"
            },
            {
              "area": "剑河县",
              "code": "522629"
            },
            {
              "area": "台江县",
              "code": "522630"
            },
            {
              "area": "黎平县",
              "code": "522631"
            },
            {
              "area": "榕江县",
              "code": "522632"
            },
            {
              "area": "从江县",
              "code": "522633"
            },
            {
              "area": "雷山县",
              "code": "522634"
            },
            {
              "area": "麻江县",
              "code": "522635"
            },
            {
              "area": "丹寨县",
              "code": "522636"
            }
          ]
        },
        {
          "province": "黔南布依族苗族自治州",
          "code": "522700",
          "areas": [
            {
              "area": "都匀市",
              "code": "522701"
            },
            {
              "area": "福泉市",
              "code": "522702"
            },
            {
              "area": "荔波县",
              "code": "522722"
            },
            {
              "area": "贵定县",
              "code": "522723"
            },
            {
              "area": "瓮安县",
              "code": "522725"
            },
            {
              "area": "独山县",
              "code": "522726"
            },
            {
              "area": "平塘县",
              "code": "522727"
            },
            {
              "area": "罗甸县",
              "code": "522728"
            },
            {
              "area": "长顺县",
              "code": "522729"
            },
            {
              "area": "龙里县",
              "code": "522730"
            },
            {
              "area": "惠水县",
              "code": "522731"
            },
            {
              "area": "三都水族自治县",
              "code": "522732"
            }
          ]
        }
      ]
    },
    {
      "province": "云南省",
      "code": "530000",
      "provinces": [
        {
          "province": "昆明市",
          "code": "530100",
          "areas": [
            {
              "area": "五华区",
              "code": "530102"
            },
            {
              "area": "盘龙区",
              "code": "530103"
            },
            {
              "area": "官渡区",
              "code": "530111"
            },
            {
              "area": "西山区",
              "code": "530112"
            },
            {
              "area": "东川区",
              "code": "530113"
            },
            {
              "area": "呈贡区",
              "code": "530114"
            },
            {
              "area": "晋宁区",
              "code": "530115"
            },
            {
              "area": "富民县",
              "code": "530124"
            },
            {
              "area": "宜良县",
              "code": "530125"
            },
            {
              "area": "石林彝族自治县",
              "code": "530126"
            },
            {
              "area": "嵩明县",
              "code": "530127"
            },
            {
              "area": "禄劝彝族苗族自治县",
              "code": "530128"
            },
            {
              "area": "寻甸回族彝族自治县",
              "code": "530129"
            },
            {
              "area": "安宁市",
              "code": "530181"
            }
          ]
        },
        {
          "province": "曲靖市",
          "code": "530300",
          "areas": [
            {
              "area": "麒麟区",
              "code": "530302"
            },
            {
              "area": "沾益区",
              "code": "530303"
            },
            {
              "area": "马龙区",
              "code": "530304"
            },
            {
              "area": "陆良县",
              "code": "530322"
            },
            {
              "area": "师宗县",
              "code": "530323"
            },
            {
              "area": "罗平县",
              "code": "530324"
            },
            {
              "area": "富源县",
              "code": "530325"
            },
            {
              "area": "会泽县",
              "code": "530326"
            },
            {
              "area": "宣威市",
              "code": "530381"
            }
          ]
        },
        {
          "province": "玉溪市",
          "code": "530400",
          "areas": [
            {
              "area": "红塔区",
              "code": "530402"
            },
            {
              "area": "江川区",
              "code": "530403"
            },
            {
              "area": "通海县",
              "code": "530423"
            },
            {
              "area": "华宁县",
              "code": "530424"
            },
            {
              "area": "易门县",
              "code": "530425"
            },
            {
              "area": "峨山彝族自治县",
              "code": "530426"
            },
            {
              "area": "新平彝族傣族自治县",
              "code": "530427"
            },
            {
              "area": "元江哈尼族彝族傣族自治县",
              "code": "530428"
            },
            {
              "area": "澄江市",
              "code": "530481"
            }
          ]
        },
        {
          "province": "保山市",
          "code": "530500",
          "areas": [
            {
              "area": "隆阳区",
              "code": "530502"
            },
            {
              "area": "施甸县",
              "code": "530521"
            },
            {
              "area": "龙陵县",
              "code": "530523"
            },
            {
              "area": "昌宁县",
              "code": "530524"
            },
            {
              "area": "腾冲市",
              "code": "530581"
            }
          ]
        },
        {
          "province": "昭通市",
          "code": "530600",
          "areas": [
            {
              "area": "昭阳区",
              "code": "530602"
            },
            {
              "area": "鲁甸县",
              "code": "530621"
            },
            {
              "area": "巧家县",
              "code": "530622"
            },
            {
              "area": "盐津县",
              "code": "530623"
            },
            {
              "area": "大关县",
              "code": "530624"
            },
            {
              "area": "永善县",
              "code": "530625"
            },
            {
              "area": "绥江县",
              "code": "530626"
            },
            {
              "area": "镇雄县",
              "code": "530627"
            },
            {
              "area": "彝良县",
              "code": "530628"
            },
            {
              "area": "威信县",
              "code": "530629"
            },
            {
              "area": "水富市",
              "code": "530681"
            }
          ]
        },
        {
          "province": "丽江市",
          "code": "530700",
          "areas": [
            {
              "area": "古城区",
              "code": "530702"
            },
            {
              "area": "玉龙纳西族自治县",
              "code": "530721"
            },
            {
              "area": "永胜县",
              "code": "530722"
            },
            {
              "area": "华坪县",
              "code": "530723"
            },
            {
              "area": "宁蒗彝族自治县",
              "code": "530724"
            }
          ]
        },
        {
          "province": "普洱市",
          "code": "530800",
          "areas": [
            {
              "area": "思茅区",
              "code": "530802"
            },
            {
              "area": "宁洱哈尼族彝族自治县",
              "code": "530821"
            },
            {
              "area": "墨江哈尼族自治县",
              "code": "530822"
            },
            {
              "area": "景东彝族自治县",
              "code": "530823"
            },
            {
              "area": "景谷傣族彝族自治县",
              "code": "530824"
            },
            {
              "area": "镇沅彝族哈尼族拉祜族自治县",
              "code": "530825"
            },
            {
              "area": "江城哈尼族彝族自治县",
              "code": "530826"
            },
            {
              "area": "孟连傣族拉祜族佤族自治县",
              "code": "530827"
            },
            {
              "area": "澜沧拉祜族自治县",
              "code": "530828"
            },
            {
              "area": "西盟佤族自治县",
              "code": "530829"
            }
          ]
        },
        {
          "province": "临沧市",
          "code": "530900",
          "areas": [
            {
              "area": "临翔区",
              "code": "530902"
            },
            {
              "area": "凤庆县",
              "code": "530921"
            },
            {
              "area": "云县",
              "code": "530922"
            },
            {
              "area": "永德县",
              "code": "530923"
            },
            {
              "area": "镇康县",
              "code": "530924"
            },
            {
              "area": "双江拉祜族佤族布朗族傣族自治县",
              "code": "530925"
            },
            {
              "area": "耿马傣族佤族自治县",
              "code": "530926"
            },
            {
              "area": "沧源佤族自治县",
              "code": "530927"
            }
          ]
        },
        {
          "province": "楚雄彝族自治州",
          "code": "532300",
          "areas": [
            {
              "area": "楚雄市",
              "code": "532301"
            },
            {
              "area": "禄丰市",
              "code": "532302"
            },
            {
              "area": "双柏县",
              "code": "532322"
            },
            {
              "area": "牟定县",
              "code": "532323"
            },
            {
              "area": "南华县",
              "code": "532324"
            },
            {
              "area": "姚安县",
              "code": "532325"
            },
            {
              "area": "大姚县",
              "code": "532326"
            },
            {
              "area": "永仁县",
              "code": "532327"
            },
            {
              "area": "元谋县",
              "code": "532328"
            },
            {
              "area": "武定县",
              "code": "532329"
            }
          ]
        },
        {
          "province": "红河哈尼族彝族自治州",
          "code": "532500",
          "areas": [
            {
              "area": "个旧市",
              "code": "532501"
            },
            {
              "area": "开远市",
              "code": "532502"
            },
            {
              "area": "蒙自市",
              "code": "532503"
            },
            {
              "area": "弥勒市",
              "code": "532504"
            },
            {
              "area": "屏边苗族自治县",
              "code": "532523"
            },
            {
              "area": "建水县",
              "code": "532524"
            },
            {
              "area": "石屏县",
              "code": "532525"
            },
            {
              "area": "泸西县",
              "code": "532527"
            },
            {
              "area": "元阳县",
              "code": "532528"
            },
            {
              "area": "红河县",
              "code": "532529"
            },
            {
              "area": "金平苗族瑶族傣族自治县",
              "code": "532530"
            },
            {
              "area": "绿春县",
              "code": "532531"
            },
            {
              "area": "河口瑶族自治县",
              "code": "532532"
            }
          ]
        },
        {
          "province": "文山壮族苗族自治州",
          "code": "532600",
          "areas": [
            {
              "area": "文山市",
              "code": "532601"
            },
            {
              "area": "砚山县",
              "code": "532622"
            },
            {
              "area": "西畴县",
              "code": "532623"
            },
            {
              "area": "麻栗坡县",
              "code": "532624"
            },
            {
              "area": "马关县",
              "code": "532625"
            },
            {
              "area": "丘北县",
              "code": "532626"
            },
            {
              "area": "广南县",
              "code": "532627"
            },
            {
              "area": "富宁县",
              "code": "532628"
            }
          ]
        },
        {
          "province": "西双版纳傣族自治州",
          "code": "532800",
          "areas": [
            {
              "area": "景洪市",
              "code": "532801"
            },
            {
              "area": "勐海县",
              "code": "532822"
            },
            {
              "area": "勐腊县",
              "code": "532823"
            }
          ]
        },
        {
          "province": "大理白族自治州",
          "code": "532900",
          "areas": [
            {
              "area": "大理市",
              "code": "532901"
            },
            {
              "area": "漾濞彝族自治县",
              "code": "532922"
            },
            {
              "area": "祥云县",
              "code": "532923"
            },
            {
              "area": "宾川县",
              "code": "532924"
            },
            {
              "area": "弥渡县",
              "code": "532925"
            },
            {
              "area": "南涧彝族自治县",
              "code": "532926"
            },
            {
              "area": "巍山彝族回族自治县",
              "code": "532927"
            },
            {
              "area": "永平县",
              "code": "532928"
            },
            {
              "area": "云龙县",
              "code": "532929"
            },
            {
              "area": "洱源县",
              "code": "532930"
            },
            {
              "area": "剑川县",
              "code": "532931"
            },
            {
              "area": "鹤庆县",
              "code": "532932"
            }
          ]
        },
        {
          "province": "德宏傣族景颇族自治州",
          "code": "533100",
          "areas": [
            {
              "area": "瑞丽市",
              "code": "533102"
            },
            {
              "area": "芒市",
              "code": "533103"
            },
            {
              "area": "梁河县",
              "code": "533122"
            },
            {
              "area": "盈江县",
              "code": "533123"
            },
            {
              "area": "陇川县",
              "code": "533124"
            }
          ]
        },
        {
          "province": "怒江傈僳族自治州",
          "code": "533300",
          "areas": [
            {
              "area": "泸水市",
              "code": "533301"
            },
            {
              "area": "福贡县",
              "code": "533323"
            },
            {
              "area": "贡山独龙族怒族自治县",
              "code": "533324"
            },
            {
              "area": "兰坪白族普米族自治县",
              "code": "533325"
            }
          ]
        },
        {
          "province": "迪庆藏族自治州",
          "code": "533400",
          "areas": [
            {
              "area": "香格里拉市",
              "code": "533401"
            },
            {
              "area": "德钦县",
              "code": "533422"
            },
            {
              "area": "维西傈僳族自治县",
              "code": "533423"
            }
          ]
        }
      ]
    },
    {
      "province": "西藏自治区",
      "code": "540000",
      "provinces": [
        {
          "province": "拉萨市",
          "code": "540100",
          "areas": [
            {
              "area": "城关区",
              "code": "540102"
            },
            {
              "area": "堆龙德庆区",
              "code": "540103"
            },
            {
              "area": "达孜区",
              "code": "540104"
            },
            {
              "area": "林周县",
              "code": "540121"
            },
            {
              "area": "当雄县",
              "code": "540122"
            },
            {
              "area": "尼木县",
              "code": "540123"
            },
            {
              "area": "曲水县",
              "code": "540124"
            },
            {
              "area": "墨竹工卡县",
              "code": "540127"
            },
            {
              "area": "格尔木藏青工业园区",
              "code": "540171"
            },
            {
              "area": "拉萨经济技术开发区",
              "code": "540172"
            },
            {
              "area": "西藏文化旅游创意园区",
              "code": "540173"
            },
            {
              "area": "达孜工业园区",
              "code": "540174"
            }
          ]
        },
        {
          "province": "日喀则市",
          "code": "540200",
          "areas": [
            {
              "area": "桑珠孜区",
              "code": "540202"
            },
            {
              "area": "南木林县",
              "code": "540221"
            },
            {
              "area": "江孜县",
              "code": "540222"
            },
            {
              "area": "定日县",
              "code": "540223"
            },
            {
              "area": "萨迦县",
              "code": "540224"
            },
            {
              "area": "拉孜县",
              "code": "540225"
            },
            {
              "area": "昂仁县",
              "code": "540226"
            },
            {
              "area": "谢通门县",
              "code": "540227"
            },
            {
              "area": "白朗县",
              "code": "540228"
            },
            {
              "area": "仁布县",
              "code": "540229"
            },
            {
              "area": "康马县",
              "code": "540230"
            },
            {
              "area": "定结县",
              "code": "540231"
            },
            {
              "area": "仲巴县",
              "code": "540232"
            },
            {
              "area": "亚东县",
              "code": "540233"
            },
            {
              "area": "吉隆县",
              "code": "540234"
            },
            {
              "area": "聂拉木县",
              "code": "540235"
            },
            {
              "area": "萨嘎县",
              "code": "540236"
            },
            {
              "area": "岗巴县",
              "code": "540237"
            }
          ]
        },
        {
          "province": "昌都市",
          "code": "540300",
          "areas": [
            {
              "area": "卡若区",
              "code": "540302"
            },
            {
              "area": "江达县",
              "code": "540321"
            },
            {
              "area": "贡觉县",
              "code": "540322"
            },
            {
              "area": "类乌齐县",
              "code": "540323"
            },
            {
              "area": "丁青县",
              "code": "540324"
            },
            {
              "area": "察雅县",
              "code": "540325"
            },
            {
              "area": "八宿县",
              "code": "540326"
            },
            {
              "area": "左贡县",
              "code": "540327"
            },
            {
              "area": "芒康县",
              "code": "540328"
            },
            {
              "area": "洛隆县",
              "code": "540329"
            },
            {
              "area": "边坝县",
              "code": "540330"
            }
          ]
        },
        {
          "province": "林芝市",
          "code": "540400",
          "areas": [
            {
              "area": "巴宜区",
              "code": "540402"
            },
            {
              "area": "工布江达县",
              "code": "540421"
            },
            {
              "area": "墨脱县",
              "code": "540423"
            },
            {
              "area": "波密县",
              "code": "540424"
            },
            {
              "area": "察隅县",
              "code": "540425"
            },
            {
              "area": "朗县",
              "code": "540426"
            },
            {
              "area": "米林市",
              "code": "540481"
            }
          ]
        },
        {
          "province": "山南市",
          "code": "540500",
          "areas": [
            {
              "area": "乃东区",
              "code": "540502"
            },
            {
              "area": "扎囊县",
              "code": "540521"
            },
            {
              "area": "贡嘎县",
              "code": "540522"
            },
            {
              "area": "桑日县",
              "code": "540523"
            },
            {
              "area": "琼结县",
              "code": "540524"
            },
            {
              "area": "曲松县",
              "code": "540525"
            },
            {
              "area": "措美县",
              "code": "540526"
            },
            {
              "area": "洛扎县",
              "code": "540527"
            },
            {
              "area": "加查县",
              "code": "540528"
            },
            {
              "area": "隆子县",
              "code": "540529"
            },
            {
              "area": "浪卡子县",
              "code": "540531"
            },
            {
              "area": "错那市",
              "code": "540581"
            }
          ]
        },
        {
          "province": "那曲市",
          "code": "540600",
          "areas": [
            {
              "area": "色尼区",
              "code": "540602"
            },
            {
              "area": "嘉黎县",
              "code": "540621"
            },
            {
              "area": "比如县",
              "code": "540622"
            },
            {
              "area": "聂荣县",
              "code": "540623"
            },
            {
              "area": "安多县",
              "code": "540624"
            },
            {
              "area": "申扎县",
              "code": "540625"
            },
            {
              "area": "索县",
              "code": "540626"
            },
            {
              "area": "班戈县",
              "code": "540627"
            },
            {
              "area": "巴青县",
              "code": "540628"
            },
            {
              "area": "尼玛县",
              "code": "540629"
            },
            {
              "area": "双湖县",
              "code": "540630"
            }
          ]
        },
        {
          "province": "阿里地区",
          "code": "542500",
          "areas": [
            {
              "area": "普兰县",
              "code": "542521"
            },
            {
              "area": "札达县",
              "code": "542522"
            },
            {
              "area": "噶尔县",
              "code": "542523"
            },
            {
              "area": "日土县",
              "code": "542524"
            },
            {
              "area": "革吉县",
              "code": "542525"
            },
            {
              "area": "改则县",
              "code": "542526"
            },
            {
              "area": "措勤县",
              "code": "542527"
            }
          ]
        }
      ]
    },
    {
      "province": "陕西省",
      "code": "610000",
      "provinces": [
        {
          "province": "西安市",
          "code": "610100",
          "areas": [
            {
              "area": "新城区",
              "code": "610102"
            },
            {
              "area": "碑林区",
              "code": "610103"
            },
            {
              "area": "莲湖区",
              "code": "610104"
            },
            {
              "area": "灞桥区",
              "code": "610111"
            },
            {
              "area": "未央区",
              "code": "610112"
            },
            {
              "area": "雁塔区",
              "code": "610113"
            },
            {
              "area": "阎良区",
              "code": "610114"
            },
            {
              "area": "临潼区",
              "code": "610115"
            },
            {
              "area": "长安区",
              "code": "610116"
            },
            {
              "area": "高陵区",
              "code": "610117"
            },
            {
              "area": "鄠邑区",
              "code": "610118"
            },
            {
              "area": "蓝田县",
              "code": "610122"
            },
            {
              "area": "周至县",
              "code": "610124"
            }
          ]
        },
        {
          "province": "铜川市",
          "code": "610200",
          "areas": [
            {
              "area": "王益区",
              "code": "610202"
            },
            {
              "area": "印台区",
              "code": "610203"
            },
            {
              "area": "耀州区",
              "code": "610204"
            },
            {
              "area": "宜君县",
              "code": "610222"
            }
          ]
        },
        {
          "province": "宝鸡市",
          "code": "610300",
          "areas": [
            {
              "area": "渭滨区",
              "code": "610302"
            },
            {
              "area": "金台区",
              "code": "610303"
            },
            {
              "area": "陈仓区",
              "code": "610304"
            },
            {
              "area": "凤翔区",
              "code": "610305"
            },
            {
              "area": "岐山县",
              "code": "610323"
            },
            {
              "area": "扶风县",
              "code": "610324"
            },
            {
              "area": "眉县",
              "code": "610326"
            },
            {
              "area": "陇县",
              "code": "610327"
            },
            {
              "area": "千阳县",
              "code": "610328"
            },
            {
              "area": "麟游县",
              "code": "610329"
            },
            {
              "area": "凤县",
              "code": "610330"
            },
            {
              "area": "太白县",
              "code": "610331"
            }
          ]
        },
        {
          "province": "咸阳市",
          "code": "610400",
          "areas": [
            {
              "area": "秦都区",
              "code": "610402"
            },
            {
              "area": "杨陵区",
              "code": "610403"
            },
            {
              "area": "渭城区",
              "code": "610404"
            },
            {
              "area": "三原县",
              "code": "610422"
            },
            {
              "area": "泾阳县",
              "code": "610423"
            },
            {
              "area": "乾县",
              "code": "610424"
            },
            {
              "area": "礼泉县",
              "code": "610425"
            },
            {
              "area": "永寿县",
              "code": "610426"
            },
            {
              "area": "长武县",
              "code": "610428"
            },
            {
              "area": "旬邑县",
              "code": "610429"
            },
            {
              "area": "淳化县",
              "code": "610430"
            },
            {
              "area": "武功县",
              "code": "610431"
            },
            {
              "area": "兴平市",
              "code": "610481"
            },
            {
              "area": "彬州市",
              "code": "610482"
            }
          ]
        },
        {
          "province": "渭南市",
          "code": "610500",
          "areas": [
            {
              "area": "临渭区",
              "code": "610502"
            },
            {
              "area": "华州区",
              "code": "610503"
            },
            {
              "area": "潼关县",
              "code": "610522"
            },
            {
              "area": "大荔县",
              "code": "610523"
            },
            {
              "area": "合阳县",
              "code": "610524"
            },
            {
              "area": "澄城县",
              "code": "610525"
            },
            {
              "area": "蒲城县",
              "code": "610526"
            },
            {
              "area": "白水县",
              "code": "610527"
            },
            {
              "area": "富平县",
              "code": "610528"
            },
            {
              "area": "韩城市",
              "code": "610581"
            },
            {
              "area": "华阴市",
              "code": "610582"
            }
          ]
        },
        {
          "province": "延安市",
          "code": "610600",
          "areas": [
            {
              "area": "宝塔区",
              "code": "610602"
            },
            {
              "area": "安塞区",
              "code": "610603"
            },
            {
              "area": "延长县",
              "code": "610621"
            },
            {
              "area": "延川县",
              "code": "610622"
            },
            {
              "area": "志丹县",
              "code": "610625"
            },
            {
              "area": "吴起县",
              "code": "610626"
            },
            {
              "area": "甘泉县",
              "code": "610627"
            },
            {
              "area": "富县",
              "code": "610628"
            },
            {
              "area": "洛川县",
              "code": "610629"
            },
            {
              "area": "宜川县",
              "code": "610630"
            },
            {
              "area": "黄龙县",
              "code": "610631"
            },
            {
              "area": "黄陵县",
              "code": "610632"
            },
            {
              "area": "子长市",
              "code": "610681"
            }
          ]
        },
        {
          "province": "汉中市",
          "code": "610700",
          "areas": [
            {
              "area": "汉台区",
              "code": "610702"
            },
            {
              "area": "南郑区",
              "code": "610703"
            },
            {
              "area": "城固县",
              "code": "610722"
            },
            {
              "area": "洋县",
              "code": "610723"
            },
            {
              "area": "西乡县",
              "code": "610724"
            },
            {
              "area": "勉县",
              "code": "610725"
            },
            {
              "area": "宁强县",
              "code": "610726"
            },
            {
              "area": "略阳县",
              "code": "610727"
            },
            {
              "area": "镇巴县",
              "code": "610728"
            },
            {
              "area": "留坝县",
              "code": "610729"
            },
            {
              "area": "佛坪县",
              "code": "610730"
            }
          ]
        },
        {
          "province": "榆林市",
          "code": "610800",
          "areas": [
            {
              "area": "榆阳区",
              "code": "610802"
            },
            {
              "area": "横山区",
              "code": "610803"
            },
            {
              "area": "府谷县",
              "code": "610822"
            },
            {
              "area": "靖边县",
              "code": "610824"
            },
            {
              "area": "定边县",
              "code": "610825"
            },
            {
              "area": "绥德县",
              "code": "610826"
            },
            {
              "area": "米脂县",
              "code": "610827"
            },
            {
              "area": "佳县",
              "code": "610828"
            },
            {
              "area": "吴堡县",
              "code": "610829"
            },
            {
              "area": "清涧县",
              "code": "610830"
            },
            {
              "area": "子洲县",
              "code": "610831"
            },
            {
              "area": "神木市",
              "code": "610881"
            }
          ]
        },
        {
          "province": "安康市",
          "code": "610900",
          "areas": [
            {
              "area": "汉滨区",
              "code": "610902"
            },
            {
              "area": "汉阴县",
              "code": "610921"
            },
            {
              "area": "石泉县",
              "code": "610922"
            },
            {
              "area": "宁陕县",
              "code": "610923"
            },
            {
              "area": "紫阳县",
              "code": "610924"
            },
            {
              "area": "岚皋县",
              "code": "610925"
            },
            {
              "area": "平利县",
              "code": "610926"
            },
            {
              "area": "镇坪县",
              "code": "610927"
            },
            {
              "area": "白河县",
              "code": "610929"
            },
            {
              "area": "旬阳市",
              "code": "610981"
            }
          ]
        },
        {
          "province": "商洛市",
          "code": "611000",
          "areas": [
            {
              "area": "商州区",
              "code": "611002"
            },
            {
              "area": "洛南县",
              "code": "611021"
            },
            {
              "area": "丹凤县",
              "code": "611022"
            },
            {
              "area": "商南县",
              "code": "611023"
            },
            {
              "area": "山阳县",
              "code": "611024"
            },
            {
              "area": "镇安县",
              "code": "611025"
            },
            {
              "area": "柞水县",
              "code": "611026"
            }
          ]
        }
      ]
    },
    {
      "province": "甘肃省",
      "code": "620000",
      "provinces": [
        {
          "province": "兰州市",
          "code": "620100",
          "areas": [
            {
              "area": "城关区",
              "code": "620102"
            },
            {
              "area": "七里河区",
              "code": "620103"
            },
            {
              "area": "西固区",
              "code": "620104"
            },
            {
              "area": "安宁区",
              "code": "620105"
            },
            {
              "area": "红古区",
              "code": "620111"
            },
            {
              "area": "永登县",
              "code": "620121"
            },
            {
              "area": "皋兰县",
              "code": "620122"
            },
            {
              "area": "榆中县",
              "code": "620123"
            },
            {
              "area": "兰州新区",
              "code": "620171"
            }
          ]
        },
        {
          "province": "嘉峪关市",
          "code": "620200",
          "areas": []
        },
        {
          "province": "金昌市",
          "code": "620300",
          "areas": [
            {
              "area": "金川区",
              "code": "620302"
            },
            {
              "area": "永昌县",
              "code": "620321"
            }
          ]
        },
        {
          "province": "白银市",
          "code": "620400",
          "areas": [
            {
              "area": "白银区",
              "code": "620402"
            },
            {
              "area": "平川区",
              "code": "620403"
            },
            {
              "area": "靖远县",
              "code": "620421"
            },
            {
              "area": "会宁县",
              "code": "620422"
            },
            {
              "area": "景泰县",
              "code": "620423"
            }
          ]
        },
        {
          "province": "天水市",
          "code": "620500",
          "areas": [
            {
              "area": "秦州区",
              "code": "620502"
            },
            {
              "area": "麦积区",
              "code": "620503"
            },
            {
              "area": "清水县",
              "code": "620521"
            },
            {
              "area": "秦安县",
              "code": "620522"
            },
            {
              "area": "甘谷县",
              "code": "620523"
            },
            {
              "area": "武山县",
              "code": "620524"
            },
            {
              "area": "张家川回族自治县",
              "code": "620525"
            }
          ]
        },
        {
          "province": "武威市",
          "code": "620600",
          "areas": [
            {
              "area": "凉州区",
              "code": "620602"
            },
            {
              "area": "民勤县",
              "code": "620621"
            },
            {
              "area": "古浪县",
              "code": "620622"
            },
            {
              "area": "天祝藏族自治县",
              "code": "620623"
            }
          ]
        },
        {
          "province": "张掖市",
          "code": "620700",
          "areas": [
            {
              "area": "甘州区",
              "code": "620702"
            },
            {
              "area": "肃南裕固族自治县",
              "code": "620721"
            },
            {
              "area": "民乐县",
              "code": "620722"
            },
            {
              "area": "临泽县",
              "code": "620723"
            },
            {
              "area": "高台县",
              "code": "620724"
            },
            {
              "area": "山丹县",
              "code": "620725"
            }
          ]
        },
        {
          "province": "平凉市",
          "code": "620800",
          "areas": [
            {
              "area": "崆峒区",
              "code": "620802"
            },
            {
              "area": "泾川县",
              "code": "620821"
            },
            {
              "area": "灵台县",
              "code": "620822"
            },
            {
              "area": "崇信县",
              "code": "620823"
            },
            {
              "area": "庄浪县",
              "code": "620825"
            },
            {
              "area": "静宁县",
              "code": "620826"
            },
            {
              "area": "华亭市",
              "code": "620881"
            }
          ]
        },
        {
          "province": "酒泉市",
          "code": "620900",
          "areas": [
            {
              "area": "肃州区",
              "code": "620902"
            },
            {
              "area": "金塔县",
              "code": "620921"
            },
            {
              "area": "瓜州县",
              "code": "620922"
            },
            {
              "area": "肃北蒙古族自治县",
              "code": "620923"
            },
            {
              "area": "阿克塞哈萨克族自治县",
              "code": "620924"
            },
            {
              "area": "玉门市",
              "code": "620981"
            },
            {
              "area": "敦煌市",
              "code": "620982"
            }
          ]
        },
        {
          "province": "庆阳市",
          "code": "621000",
          "areas": [
            {
              "area": "西峰区",
              "code": "621002"
            },
            {
              "area": "庆城县",
              "code": "621021"
            },
            {
              "area": "环县",
              "code": "621022"
            },
            {
              "area": "华池县",
              "code": "621023"
            },
            {
              "area": "合水县",
              "code": "621024"
            },
            {
              "area": "正宁县",
              "code": "621025"
            },
            {
              "area": "宁县",
              "code": "621026"
            },
            {
              "area": "镇原县",
              "code": "621027"
            }
          ]
        },
        {
          "province": "定西市",
          "code": "621100",
          "areas": [
            {
              "area": "安定区",
              "code": "621102"
            },
            {
              "area": "通渭县",
              "code": "621121"
            },
            {
              "area": "陇西县",
              "code": "621122"
            },
            {
              "area": "渭源县",
              "code": "621123"
            },
            {
              "area": "临洮县",
              "code": "621124"
            },
            {
              "area": "漳县",
              "code": "621125"
            },
            {
              "area": "岷县",
              "code": "621126"
            }
          ]
        },
        {
          "province": "陇南市",
          "code": "621200",
          "areas": [
            {
              "area": "武都区",
              "code": "621202"
            },
            {
              "area": "成县",
              "code": "621221"
            },
            {
              "area": "文县",
              "code": "621222"
            },
            {
              "area": "宕昌县",
              "code": "621223"
            },
            {
              "area": "康县",
              "code": "621224"
            },
            {
              "area": "西和县",
              "code": "621225"
            },
            {
              "area": "礼县",
              "code": "621226"
            },
            {
              "area": "徽县",
              "code": "621227"
            },
            {
              "area": "两当县",
              "code": "621228"
            }
          ]
        },
        {
          "province": "临夏回族自治州",
          "code": "622900",
          "areas": [
            {
              "area": "临夏市",
              "code": "622901"
            },
            {
              "area": "临夏县",
              "code": "622921"
            },
            {
              "area": "康乐县",
              "code": "622922"
            },
            {
              "area": "永靖县",
              "code": "622923"
            },
            {
              "area": "广河县",
              "code": "622924"
            },
            {
              "area": "和政县",
              "code": "622925"
            },
            {
              "area": "东乡族自治县",
              "code": "622926"
            },
            {
              "area": "积石山保安族东乡族撒拉族自治县",
              "code": "622927"
            }
          ]
        },
        {
          "province": "甘南藏族自治州",
          "code": "623000",
          "areas": [
            {
              "area": "合作市",
              "code": "623001"
            },
            {
              "area": "临潭县",
              "code": "623021"
            },
            {
              "area": "卓尼县",
              "code": "623022"
            },
            {
              "area": "舟曲县",
              "code": "623023"
            },
            {
              "area": "迭部县",
              "code": "623024"
            },
            {
              "area": "玛曲县",
              "code": "623025"
            },
            {
              "area": "碌曲县",
              "code": "623026"
            },
            {
              "area": "夏河县",
              "code": "623027"
            }
          ]
        }
      ]
    },
    {
      "province": "青海省",
      "code": "630000",
      "provinces": [
        {
          "province": "西宁市",
          "code": "630100",
          "areas": [
            {
              "area": "城东区",
              "code": "630102"
            },
            {
              "area": "城中区",
              "code": "630103"
            },
            {
              "area": "城西区",
              "code": "630104"
            },
            {
              "area": "城北区",
              "code": "630105"
            },
            {
              "area": "湟中区",
              "code": "630106"
            },
            {
              "area": "大通回族土族自治县",
              "code": "630121"
            },
            {
              "area": "湟源县",
              "code": "630123"
            }
          ]
        },
        {
          "province": "海东市",
          "code": "630200",
          "areas": [
            {
              "area": "乐都区",
              "code": "630202"
            },
            {
              "area": "平安区",
              "code": "630203"
            },
            {
              "area": "民和回族土族自治县",
              "code": "630222"
            },
            {
              "area": "互助土族自治县",
              "code": "630223"
            },
            {
              "area": "化隆回族自治县",
              "code": "630224"
            },
            {
              "area": "循化撒拉族自治县",
              "code": "630225"
            }
          ]
        },
        {
          "province": "海北藏族自治州",
          "code": "632200",
          "areas": [
            {
              "area": "门源回族自治县",
              "code": "632221"
            },
            {
              "area": "祁连县",
              "code": "632222"
            },
            {
              "area": "海晏县",
              "code": "632223"
            },
            {
              "area": "刚察县",
              "code": "632224"
            }
          ]
        },
        {
          "province": "黄南藏族自治州",
          "code": "632300",
          "areas": [
            {
              "area": "同仁市",
              "code": "632301"
            },
            {
              "area": "尖扎县",
              "code": "632322"
            },
            {
              "area": "泽库县",
              "code": "632323"
            },
            {
              "area": "河南蒙古族自治县",
              "code": "632324"
            }
          ]
        },
        {
          "province": "海南藏族自治州",
          "code": "632500",
          "areas": [
            {
              "area": "共和县",
              "code": "632521"
            },
            {
              "area": "同德县",
              "code": "632522"
            },
            {
              "area": "贵德县",
              "code": "632523"
            },
            {
              "area": "兴海县",
              "code": "632524"
            },
            {
              "area": "贵南县",
              "code": "632525"
            }
          ]
        },
        {
          "province": "果洛藏族自治州",
          "code": "632600",
          "areas": [
            {
              "area": "玛沁县",
              "code": "632621"
            },
            {
              "area": "班玛县",
              "code": "632622"
            },
            {
              "area": "甘德县",
              "code": "632623"
            },
            {
              "area": "达日县",
              "code": "632624"
            },
            {
              "area": "久治县",
              "code": "632625"
            },
            {
              "area": "玛多县",
              "code": "632626"
            }
          ]
        },
        {
          "province": "玉树藏族自治州",
          "code": "632700",
          "areas": [
            {
              "area": "玉树市",
              "code": "632701"
            },
            {
              "area": "杂多县",
              "code": "632722"
            },
            {
              "area": "称多县",
              "code": "632723"
            },
            {
              "area": "治多县",
              "code": "632724"
            },
            {
              "area": "囊谦县",
              "code": "632725"
            },
            {
              "area": "曲麻莱县",
              "code": "632726"
            }
          ]
        },
        {
          "province": "海西蒙古族藏族自治州",
          "code": "632800",
          "areas": [
            {
              "area": "格尔木市",
              "code": "632801"
            },
            {
              "area": "德令哈市",
              "code": "632802"
            },
            {
              "area": "茫崖市",
              "code": "632803"
            },
            {
              "area": "乌兰县",
              "code": "632821"
            },
            {
              "area": "都兰县",
              "code": "632822"
            },
            {
              "area": "天峻县",
              "code": "632823"
            },
            {
              "area": "大柴旦行政委员会",
              "code": "632857"
            }
          ]
        }
      ]
    },
    {
      "province": "宁夏回族自治区",
      "code": "640000",
      "provinces": [
        {
          "province": "银川市",
          "code": "640100",
          "areas": [
            {
              "area": "兴庆区",
              "code": "640104"
            },
            {
              "area": "西夏区",
              "code": "640105"
            },
            {
              "area": "金凤区",
              "code": "640106"
            },
            {
              "area": "永宁县",
              "code": "640121"
            },
            {
              "area": "贺兰县",
              "code": "640122"
            },
            {
              "area": "灵武市",
              "code": "640181"
            }
          ]
        },
        {
          "province": "石嘴山市",
          "code": "640200",
          "areas": [
            {
              "area": "大武口区",
              "code": "640202"
            },
            {
              "area": "惠农区",
              "code": "640205"
            },
            {
              "area": "平罗县",
              "code": "640221"
            }
          ]
        },
        {
          "province": "吴忠市",
          "code": "640300",
          "areas": [
            {
              "area": "利通区",
              "code": "640302"
            },
            {
              "area": "红寺堡区",
              "code": "640303"
            },
            {
              "area": "盐池县",
              "code": "640323"
            },
            {
              "area": "同心县",
              "code": "640324"
            },
            {
              "area": "青铜峡市",
              "code": "640381"
            }
          ]
        },
        {
          "province": "固原市",
          "code": "640400",
          "areas": [
            {
              "area": "原州区",
              "code": "640402"
            },
            {
              "area": "西吉县",
              "code": "640422"
            },
            {
              "area": "隆德县",
              "code": "640423"
            },
            {
              "area": "泾源县",
              "code": "640424"
            },
            {
              "area": "彭阳县",
              "code": "640425"
            }
          ]
        },
        {
          "province": "中卫市",
          "code": "640500",
          "areas": [
            {
              "area": "沙坡头区",
              "code": "640502"
            },
            {
              "area": "中宁县",
              "code": "640521"
            },
            {
              "area": "海原县",
              "code": "640522"
            }
          ]
        }
      ]
    },
    {
      "province": "新疆维吾尔自治区",
      "code": "650000",
      "provinces": [
        {
          "province": "乌鲁木齐市",
          "code": "650100",
          "areas": [
            {
              "area": "天山区",
              "code": "650102"
            },
            {
              "area": "沙依巴克区",
              "code": "650103"
            },
            {
              "area": "新市区",
              "code": "650104"
            },
            {
              "area": "水磨沟区",
              "code": "650105"
            },
            {
              "area": "头屯河区",
              "code": "650106"
            },
            {
              "area": "达坂城区",
              "code": "650107"
            },
            {
              "area": "米东区",
              "code": "650109"
            },
            {
              "area": "乌鲁木齐县",
              "code": "650121"
            }
          ]
        },
        {
          "province": "克拉玛依市",
          "code": "650200",
          "areas": [
            {
              "area": "独山子区",
              "code": "650202"
            },
            {
              "area": "克拉玛依区",
              "code": "650203"
            },
            {
              "area": "白碱滩区",
              "code": "650204"
            },
            {
              "area": "乌尔禾区",
              "code": "650205"
            }
          ]
        },
        {
          "province": "吐鲁番市",
          "code": "650400",
          "areas": [
            {
              "area": "高昌区",
              "code": "650402"
            },
            {
              "area": "鄯善县",
              "code": "650421"
            },
            {
              "area": "托克逊县",
              "code": "650422"
            }
          ]
        },
        {
          "province": "哈密市",
          "code": "650500",
          "areas": [
            {
              "area": "伊州区",
              "code": "650502"
            },
            {
              "area": "巴里坤哈萨克自治县",
              "code": "650521"
            },
            {
              "area": "伊吾县",
              "code": "650522"
            }
          ]
        },
        {
          "province": "昌吉回族自治州",
          "code": "652300",
          "areas": [
            {
              "area": "昌吉市",
              "code": "652301"
            },
            {
              "area": "阜康市",
              "code": "652302"
            },
            {
              "area": "呼图壁县",
              "code": "652323"
            },
            {
              "area": "玛纳斯县",
              "code": "652324"
            },
            {
              "area": "奇台县",
              "code": "652325"
            },
            {
              "area": "吉木萨尔县",
              "code": "652327"
            },
            {
              "area": "木垒哈萨克自治县",
              "code": "652328"
            }
          ]
        },
        {
          "province": "博尔塔拉蒙古自治州",
          "code": "652700",
          "areas": [
            {
              "area": "博乐市",
              "code": "652701"
            },
            {
              "area": "阿拉山口市",
              "code": "652702"
            },
            {
              "area": "精河县",
              "code": "652722"
            },
            {
              "area": "温泉县",
              "code": "652723"
            }
          ]
        },
        {
          "province": "巴音郭楞蒙古自治州",
          "code": "652800",
          "areas": [
            {
              "area": "库尔勒市",
              "code": "652801"
            },
            {
              "area": "轮台县",
              "code": "652822"
            },
            {
              "area": "尉犁县",
              "code": "652823"
            },
            {
              "area": "若羌县",
              "code": "652824"
            },
            {
              "area": "且末县",
              "code": "652825"
            },
            {
              "area": "焉耆回族自治县",
              "code": "652826"
            },
            {
              "area": "和静县",
              "code": "652827"
            },
            {
              "area": "和硕县",
              "code": "652828"
            },
            {
              "area": "博湖县",
              "code": "652829"
            }
          ]
        },
        {
          "province": "阿克苏地区",
          "code": "652900",
          "areas": [
            {
              "area": "阿克苏市",
              "code": "652901"
            },
            {
              "area": "库车市",
              "code": "652902"
            },
            {
              "area": "温宿县",
              "code": "652922"
            },
            {
              "area": "沙雅县",
              "code": "652924"
            },
            {
              "area": "新和县",
              "code": "652925"
            },
            {
              "area": "拜城县",
              "code": "652926"
            },
            {
              "area": "乌什县",
              "code": "652927"
            },
            {
              "area": "阿瓦提县",
              "code": "652928"
            },
            {
              "area": "柯坪县",
              "code": "652929"
            }
          ]
        },
        {
          "province": "克孜勒苏柯尔克孜自治州",
          "code": "653000",
          "areas": [
            {
              "area": "阿图什市",
              "code": "653001"
            },
            {
              "area": "阿克陶县",
              "code": "653022"
            },
            {
              "area": "阿合奇县",
              "code": "653023"
            },
            {
              "area": "乌恰县",
              "code": "653024"
            }
          ]
        },
        {
          "province": "喀什地区",
          "code": "653100",
          "areas": [
            {
              "area": "喀什市",
              "code": "653101"
            },
            {
              "area": "疏附县",
              "code": "653121"
            },
            {
              "area": "疏勒县",
              "code": "653122"
            },
            {
              "area": "英吉沙县",
              "code": "653123"
            },
            {
              "area": "泽普县",
              "code": "653124"
            },
            {
              "area": "莎车县",
              "code": "653125"
            },
            {
              "area": "叶城县",
              "code": "653126"
            },
            {
              "area": "麦盖提县",
              "code": "653127"
            },
            {
              "area": "岳普湖县",
              "code": "653128"
            },
            {
              "area": "伽师县",
              "code": "653129"
            },
            {
              "area": "巴楚县",
              "code": "653130"
            },
            {
              "area": "塔什库尔干塔吉克自治县",
              "code": "653131"
            }
          ]
        },
        {
          "province": "和田地区",
          "code": "653200",
          "areas": [
            {
              "area": "和田市",
              "code": "653201"
            },
            {
              "area": "和田县",
              "code": "653221"
            },
            {
              "area": "墨玉县",
              "code": "653222"
            },
            {
              "area": "皮山县",
              "code": "653223"
            },
            {
              "area": "洛浦县",
              "code": "653224"
            },
            {
              "area": "策勒县",
              "code": "653225"
            },
            {
              "area": "于田县",
              "code": "653226"
            },
            {
              "area": "民丰县",
              "code": "653227"
            }
          ]
        },
        {
          "province": "伊犁哈萨克自治州",
          "code": "654000",
          "areas": [
            {
              "area": "伊宁市",
              "code": "654002"
            },
            {
              "area": "奎屯市",
              "code": "654003"
            },
            {
              "area": "霍尔果斯市",
              "code": "654004"
            },
            {
              "area": "伊宁县",
              "code": "654021"
            },
            {
              "area": "察布查尔锡伯自治县",
              "code": "654022"
            },
            {
              "area": "霍城县",
              "code": "654023"
            },
            {
              "area": "巩留县",
              "code": "654024"
            },
            {
              "area": "新源县",
              "code": "654025"
            },
            {
              "area": "昭苏县",
              "code": "654026"
            },
            {
              "area": "特克斯县",
              "code": "654027"
            },
            {
              "area": "尼勒克县",
              "code": "654028"
            }
          ]
        },
        {
          "province": "塔城地区",
          "code": "654200",
          "areas": [
            {
              "area": "塔城市",
              "code": "654201"
            },
            {
              "area": "乌苏市",
              "code": "654202"
            },
            {
              "area": "沙湾市",
              "code": "654203"
            },
            {
              "area": "额敏县",
              "code": "654221"
            },
            {
              "area": "托里县",
              "code": "654224"
            },
            {
              "area": "裕民县",
              "code": "654225"
            },
            {
              "area": "和布克赛尔蒙古自治县",
              "code": "654226"
            }
          ]
        },
        {
          "province": "阿勒泰地区",
          "code": "654300",
          "areas": [
            {
              "area": "阿勒泰市",
              "code": "654301"
            },
            {
              "area": "布尔津县",
              "code": "654321"
            },
            {
              "area": "富蕴县",
              "code": "654322"
            },
            {
              "area": "福海县",
              "code": "654323"
            },
            {
              "area": "哈巴河县",
              "code": "654324"
            },
            {
              "area": "青河县",
              "code": "654325"
            },
            {
              "area": "吉木乃县",
              "code": "654326"
            }
          ]
        },
        {
          "province": "自治区直辖县级行政区划",
          "code": "659000",
          "areas": [
            {
              "area": "石河子市",
              "code": "659001"
            },
            {
              "area": "阿拉尔市",
              "code": "659002"
            },
            {
              "area": "图木舒克市",
              "code": "659003"
            },
            {
              "area": "五家渠市",
              "code": "659004"
            },
            {
              "area": "北屯市",
              "code": "659005"
            },
            {
              "area": "铁门关市",
              "code": "659006"
            },
            {
              "area": "双河市",
              "code": "659007"
            },
            {
              "area": "可克达拉市",
              "code": "659008"
            },
            {
              "area": "昆玉市",
              "code": "659009"
            },
            {
              "area": "胡杨河市",
              "code": "659010"
            },
            {
              "area": "新星市",
              "code": "659011"
            },
            {
              "area": "白杨市",
              "code": "659012"
            }
          ]
        }
      ]
    },
    {
      "province": "台湾省",
      "code": "710000",
      "provinces": [
        {
          "province": "台北市",
          "code": "710000",
          "areas": [
            {
              "area": "台北市",
              "code": "710000"
            }
          ]
        },
        {
          "province": "新北市",
          "code": "710000",
          "areas": [
            {
              "area": "新北市",
              "code": "710000"
            }
          ]
        },
        {
          "province": "桃园市",
          "code": "710000",
          "areas": [
            {
              "area": "桃园市",
              "code": "710000"
            }
          ]
        },
        {
          "province": "台中市",
          "code": "710000",
          "areas": [
            {
              "area": "台中市",
              "code": "710000"
            }
          ]
        },
        {
          "province": "台南市",
          "code": "710000",
          "areas": [
            {
              "area": "台南市",
              "code": "710000"
            }
          ]
        },
        {
          "province": "高雄市",
          "code": "710000",
          "areas": [
            {
              "area": "高雄市",
              "code": "710000"
            }
          ]
        },
        {
          "province": "基隆市",
          "code": "710000",
          "areas": [
            {
              "area": "基隆市",
              "code": "710000"
            }
          ]
        },
        {
          "province": "新竹市",
          "code": "710000",
          "areas": [
            {
              "area": "新竹市",
              "code": "710000"
            }
          ]
        },
        {
          "province": "嘉义市",
          "code": "710000",
          "areas": [
            {
              "area": "嘉义市",
              "code": "710000"
            }
          ]
        },
        {
          "province": "新竹县",
          "code": "710000",
          "areas": [
            {
              "area": "新竹县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "苗栗县",
          "code": "710000",
          "areas": [
            {
              "area": "苗栗县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "彰化县",
          "code": "710000",
          "areas": [
            {
              "area": "彰化县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "南投县",
          "code": "710000",
          "areas": [
            {
              "area": "南投县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "云林县",
          "code": "710000",
          "areas": [
            {
              "area": "云林县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "嘉义县",
          "code": "710000",
          "areas": [
            {
              "area": "嘉义县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "屏东县",
          "code": "710000",
          "areas": [
            {
              "area": "屏东县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "宜兰县",
          "code": "710000",
          "areas": [
            {
              "area": "宜兰县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "花莲县",
          "code": "710000",
          "areas": [
            {
              "area": "花莲县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "台东县",
          "code": "710000",
          "areas": [
            {
              "area": "台东县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "澎湖县",
          "code": "710000",
          "areas": [
            {
              "area": "澎湖县",
              "code": "710000"
            }
          ]
        },
        {
          "province": "连江县",
          "code": "710000",
          "areas": [
            {
              "area": "连江县",
              "code": "710000"
            }
          ]
        }
      ]
    },
    {
      "province": "香港特别行政区",
      "code": "810000",
      "provinces": [
        {
          "province": "香港特别行政区",
          "code": "810000",
          "areas": [
            {
              "area": "中西区",
              "code": "810001"
            },
            {
              "area": "湾仔区",
              "code": "810002"
            },
            {
              "area": "东区",
              "code": "810003"
            },
            {
              "area": "南区",
              "code": "810004"
            },
            {
              "area": "油尖旺区",
              "code": "810005"
            },
            {
              "area": "深水埗区",
              "code": "810006"
            },
            {
              "area": "九龙城区",
              "code": "810007"
            },
            {
              "area": "黄大仙区",
              "code": "810008"
            },
            {
              "area": "观塘区",
              "code": "810009"
            },
            {
              "area": "荃湾区",
              "code": "810010"
            },
            {
              "area": "屯门区",
              "code": "810011"
            },
            {
              "area": "元朗区",
              "code": "810012"
            },
            {
              "area": "北区",
              "code": "810013"
            },
            {
              "area": "大埔区",
              "code": "810014"
            },
            {
              "area": "西贡区",
              "code": "810015"
            },
            {
              "area": "沙田区",
              "code": "810016"
            },
            {
              "area": "葵青区",
              "code": "810017"
            },
            {
              "area": "离岛区",
              "code": "810018"
            }
          ]
        }
      ]
    },
    {
      "province": "澳门特别行政区",
      "code": "820000",
      "provinces": [
        {
          "province": "澳门特别行政区",
          "code": "820000",
          "areas": [
            {
              "area": "花地玛堂区",
              "code": "820001"
            },
            {
              "area": "花王堂区",
              "code": "820002"
            },
            {
              "area": "望德堂区",
              "code": "820003"
            },
            {
              "area": "大堂区",
              "code": "820004"
            },
            {
              "area": "风顺堂区",
              "code": "820005"
            },
            {
              "area": "嘉模堂区",
              "code": "820006"
            },
            {
              "area": "路凼填海区",
              "code": "820007"
            },
            {
              "area": "圣方济各堂区",
              "code": "820008"
            }
          ]
        }
      ]
    }
  ]