/**
 * xr-app3 初始化配置项
 */
export const xrConfig = {
    // XR_UI:false,// 是否使用@xr/ui 前端UI组件，false时信融提供的EF标签组件将无法使用
    // plugins：[KendoGridInstaller,KendoButtonsInstaller], // 配置自己希望全局注册的插件，插件
    // App: ()=> import('../App.vue'), // 使用自定义的App.vue作为vue app主体。不配置默认使用@xr/core包的

    // EConfig: {
    //     EFGrid: {
    //         contextPath: '/eui-be'
    //     },
    // }

    baseThemes: [
        {name: 'tech', text: '科技感1111', imgUrl: ''},
        {name: 'ant', text: '科技感1', imgUrl: ''},
        {name: 'ghostDark', text: '科技感2', imgUrl: ''},
    ]
}
