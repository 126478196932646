import request from '@/utils/request'

export function getUserInfoApi() {
    return request({
        url: '/mdm/getUserInfo',
        method: 'get'
    })
}
export function getUsePermissionButtonsApi() {
    return request({
        url: '/user/queryUserResource',
        method: 'get'
    })
}
export const getUseInfoApi = () => {

    return request({
        url: 'user/queryUser?userId=hll',
        method: 'get'
    })
}
export const getRoleApi = () => {
    return request({
        url: 'role/roleQueryRequest',
        method: 'post'
    })
}
// 获取所有值集
export const getAllOptionsApi = () => {
    return request({
        url: '/queryCodesetAll',
        method: 'post'
    })
}
export const queryTravelReportUser = () => {
    return request({
        url: '/wcbbdb/queryTravelReportUser',
        method: 'get'
    })
}


// /wcbbdb/queryTravelReportUser

