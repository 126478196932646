import axios, {AxiosInstance} from "axios";
import {ElMessageBox, ElMessage, ElNotification, ElLoading} from "element-plus";
import {blobValidate, tansParams} from "@/utils/baoxin.js";
import errorCode from '@/utils/errorCode'
import {saveAs} from 'file-saver';
import {useUserStore} from '@/stores/user';
import cache from '@/plugins/cache.js'
import type {Action} from 'element-plus'
import { apiToken } from "./index";
import { useXrLoginStore } from '~xrcore/store/modules/xr_login';


const env: any = process.env;
// 定义全局计数器
let activeRequests = 0;
// 环境地址(接口请求ip):
const environmentAddress: any = env.VUE_APP_BASE_API_OWN;

export let isRelogin: { show: boolean } = {show: false};
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
const service: AxiosInstance = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: environmentAddress,
    // 超时
    timeout: 20000
})
console.log('environmentAddress', environmentAddress)

// 添加请求拦截器
service.interceptors.request.use(
    function (config: any) {
        // 用户登录成功后的请求都加loginUserToken
        const isRepeatSubmit: boolean = (config.headers || {}).repeatSubmit === false
        if (localStorage.getItem("signature_token")) {
            // config.headers['X-Token'] = localStorage.getItem("signature_token");
            config.headers['X-Token'] = localStorage.getItem("signature_token");
        }
        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url: string = config.url + '?' + tansParams(config.params);
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }
        if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
            const requestObj = {
                url: config.url,
                data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
                time: new Date().getTime()
            }
            const sessionObj: any = cache.session.getJSON('sessionObj')
            if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
                cache.session.setJSON('sessionObj', requestObj)
            } else {
                // const s_url: string = sessionObj.url;                // 请求地址
                // const s_data: any = sessionObj.data;              // 请求数据
                // const s_time: any = sessionObj.time;              // 请求时间
                // const interval = 1000;                       // 间隔时间(ms)，小于此时间视为重复提交
                // if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
                //     const message: string = '数据正在处理，请勿重复操作';
                //     return Promise.reject(new Error(message))
                // } else {
                //     cache.session.setJSON('sessionObj', requestObj)
                // }
            }
        }
        activeRequests++;
        useUserStore().setLoadingStatus(true);
        return config;
    },
    function (error) {
        useUserStore().setLoadingStatus(false);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(async (res) => {
        activeRequests--;
        console.log('响应拦截器', res, useUserStore().getLoadingStatus)
        // 未设置状态码则默认成功状态
        const code: number | string = res.data.code || 200;
        // 获取错误信息
        const msg: any = errorCode[code] || res.data.msg || errorCode['default']
        // 二进制数据则直接返回
        if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
            return res.data
        }
        console.log('响应拦截器跳转地址2')
        // 凭证过期提示重新登录
        if (res.request.response) {
            const tempRes = JSON.parse(res.request.response);
            console.log(res);
            
            if (tempRes['__sys__']?.msg == '票据过期或请求不合法，请重新登录或获取合法权限！') {
                const userStore = useXrLoginStore()
                const tokenStatus = await refreshToken(userStore.refreshToken)
        
                if (tokenStatus) {
                    // 重新赋值 token
                  //  response.config.headers ? (TOKEN = `${userStore.token}`) : null;
                  // if (response.config.headers) {
                    // response.config.headers['x-token'] = userStore.token;
                  //  }
                    // console.log('response.config.headers',response.config.headers);
                    
                    // 重新请求
                    const resp = await service.request(res.config)
                    return resp
                } else {
                    // 错误的特色情况 ==> 401 权限不足 或 token 过期 =>拦截到登录页面
                    // userStore.delToken()
                    // userStore.delRefreshToken()
                    return res.data
                }
                // ElMessageBox.alert('登录状态已过期，请重新登录', '系统提示', {
                //     confirmButtonText: '重新登录',
                //     callback: (action: Action) => {
                //         localStorage.clear()
                //         sessionStorage.clear()
                //         // window.location.reload();
                //         if (process.env.VUE_APP_EPLAY_RES_OATOTYEP=='Y') {
                //             window.location.replace(process.env.VUE_APP_EPLAY_RES_OA); 
                //         }else{
                //             window.location.reload(); 
                //         }
                        
                //     },
                // })
            }
        }
        if (code === 500) {
            // ElMessage({
            //     message: msg,
            //     type: 'error'
            // })
            ElMessageBox.alert(msg, '系统提示', {
                    confirmButtonText: '已知晓',
                }
            ).then(() => {
            })
            .catch(() => {
                
            });
            useUserStore().setLoadingStatus(false);
            return Promise.resolve(res.data);
            // return Promise.reject(new Error(msg))
        } else if (code !== 200) {
            useUserStore().setLoadingStatus(false);
            ElNotification.error({
                title: msg
            })
            return Promise.reject('error')
        } else {
            console.log('响应拦截器2', res, useUserStore().getLoadingStatus)
            if (activeRequests === 0) {
                useUserStore().setLoadingStatus(false); // 只有当所有请求完成后才设置为 false
            }
            return Promise.resolve(res.data)
        }
    },
    error => {
        console.log('err' + error)
        let {message} = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        ElMessage({
            message: message,
            type: 'error',
            duration: 5 * 1000
        })
        activeRequests = 0
        useUserStore().setLoadingStatus(false);
        return Promise.reject(error)
    }
);



let promise
function refreshToken(item) {
    // promise 防止多次请求和并发请求时连续刷新 token
    if (promise) return promise
    promise = new Promise(async (resolve) => {
        // 刷新 token
        const res = await apiToken(item)
        // 返回是否刷新成功
        resolve(res.data.code === 200)
    })
    promise.finally(() => {
        promise = null // 清空 promise
    })
    
    return promise
}

/**
 * 通用文件下载方法
 * @param url - 请求的下载地址
 * @param params - 请求的参数
 * @param filename - 下载后的文件名
 * @param method - 请求方式 (默认为 'get')
 */
export async function downloadFile(url: string, params = {}, filename = 'downloaded_file', method = 'get') {
    // 开始加载动画
    const loadingInstance = ElLoading.service({
        text: "正在下载文件，请稍候...",
        background: "rgba(0, 0, 0, 0.7)"
    });

    try {
        const response = await service({
            url,
            method,
            params: method === 'get' ? params : undefined,
            data: method === 'post' ? params : undefined,
            responseType: 'blob', // 确保响应为 blob 格式
        });

        // 检查返回的 blob 数据是否为空
        const isBlob = response instanceof Blob;
        if (!isBlob) {
            ElMessage.error("下载失败，请重试！");
            return;
        }

        // 保存文件
        const blob = new Blob([response]);
        saveAs(blob, filename);
    } catch (error) {
        console.error('文件下载失败:', error);
        ElMessage.error('下载文件出现错误，请联系管理员！');
    } finally {
        loadingInstance.close();
    }
}

// 文件下载
export const downloadFileApi = (url: string, data?: any, method?: any) => {
    const token: any = localStorage.getItem("signature_token");
    console.log('downloadFileApi')
    const msgLoading = ElMessage({
        showClose: true,
        message: '文件下载中...',
        center: true,
        duration: 0
      })
    axios({
        url: `${environmentAddress}${url}`,
        method: method ? method : "GET",
        responseType: "blob", //设置响应格式
        headers: {
            Accept: "application/octet-stream",
            'X-Token': token ? token : '',
        },
        data: data ? data : {},
        onDownloadProgress: (progressEvent) => {
            // let curRate = progressEvent.event.loaded;
            // let total = progressEvent.event.total;
            //
            // let total = data?.reduce((acc, cur) => {
            //     acc += cur.size;
            //     return acc;
            // }, 0) * 1024;

            // store.commit('setDownloadProgressData', {
            //     percentage: Number((progressEvent.event.loaded / total).toFixed(1) * 100),
            //     total,
            //     show: true
            // });
            // console.log("下载进度：", Number((progressEvent.event.loaded / total).toFixed(1) * 100));
        },
    })
        .then(function (response) {
            console.log("下载进度：响应", response);
            // 根据返回值文件类型和名称配置下载的文件名称和格式
            let fileType = response.headers["content-type"]; // 获取文件类型
            let fileName = "example"; // 设置默认文件名
            let disposition = response.headers["content-disposition"];
            if (disposition && disposition.indexOf("attachment") !== -1) {
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, "");
                }
            }
            const downloadUrl = window.URL.createObjectURL(
                new Blob([response.data])
            );
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", decodeURIComponent(fileName));
            document.body.appendChild(link);
            link.click();
            // 释放 URL 对象内存
            window.URL.revokeObjectURL(downloadUrl);
            document.body.removeChild(link);
            // setTimeout(() => {
            //     store.commit('setDownloadProgressData', {
            //         percentage: 0,
            //         total: 0,
            //         show: false,
            //     });
            // }, 300);
            msgLoading.close()
            return true;
        })
        .catch(function (error) {
            msgLoading.close()
            console.log(error);
        });

}


export const downloadFileApipdf = (url: string, data?: any, method: 'GET' | 'POST' = 'GET') => {
    const token: string | null = localStorage.getItem("signature_token");
    console.log('downloadFileApi');
    
    axios({
        url: `${environmentAddress}${url}`,
        method: method, // 可以传入 GET 或 POST 方法
        responseType: "blob", // 确保返回值是 blob 类型
        headers: {
            Accept: "application/octet-stream",  // 设置响应类型
            'X-Token': token ? token : '', // 添加 token
        },
        data: data ? data : {}, // 发送数据（如果有的话）
        onDownloadProgress: (progressEvent) => {
            // 如果需要，可以在这里更新下载进度
            // 例如：store.commit('setDownloadProgressData', {...});
        },
    })
    .then(function (response) {
        console.log("下载进度：响应", response);

        // 获取文件类型
        let fileType = response.headers["content-type"];
        let fileName = "申请表.pdf"; // 默认文件名为 "example.pdf"

        // 如果返回的 Content-Disposition 头部包含文件名
        let disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, "");
            }
        }

        // 如果是 PDF 文件，确保文件名包含 .pdf 后缀
        if (fileType && fileType.includes("pdf")) {
            fileName = fileName.endsWith(".pdf") ? fileName : fileName + ".pdf";
        }

        // 创建 Blob 对象，确保文件格式正确
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
        
        // 创建下载链接并触发点击事件
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", decodeURIComponent(fileName));
        document.body.appendChild(link);
        link.click();

        // 释放 URL 对象内存
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(link);
        
        return true;
    })
    .catch(function (error) {
        console.error("下载文件失败:", error);
        // 可根据需要显示错误提示
    });
}


export default service;
