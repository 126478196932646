/**
 * 自动导入 @/assets/iconsvg 下svg文件
 */
// '@node_modules/@xr/core/src/assets/svg-icons'
const XR_SVG_PATH = '~xrcore/assets/svg-icons/svg';
export function importAllSvg(): void {
    try {
      // eslint-disable-next-line no-undef
      const requireContext: __WebpackModuleApi.RequireContext = require.context(
        // '../../../.yalc/@xr/core/src/assets/svg-icons/svg', // ok
        // '~xrcore/assets/svg-icons/svg', // ok
        '../../../node_modules/@xr/core/src/assets/svg-icons/svg',
        false,
        /\.svg$/
      );

      // console.log('requireContext.keys', requireContext.keys());
      requireContext.keys().forEach(requireContext);
    } catch (error) {
      // eslint-disable-next-line no-console

      console.log('>>>> import svg error ', error);
    }
  }
