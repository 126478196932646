import request from '@/utils/request'

export function getUserHolidayWarnApi(data: any) {
    return request({
        url: `/uservacation/getUserVacationInfoByTypeCode1?userNumber=${data.userNumber}&vacationTypeCode=${data.vacationTypeCode}&endTime=${data.endTime?data.endTime:''}`,
        method: 'get',
    })
}


export function getgetUserVacationInfoByTypeCodeApi(data: any) {
    return request({
        url: `/uservacation/getUserVacationInfoByTypeCode2?userNumber=${data.userNumber}&vacationTypeCode=${data.vacationTypeCode}&endTime=${data.endTime?data.endTime:''}`,
        method: 'get',
    })
}


export function getUserHolidayOptionsByOrgApi(data: any) {
    return request({
        url: `/uservacation/getVacationTypeVoListByOrgCode?orgCode=${data.orgCode}&lastYearSVacationFlag=${data.lastYearSVacationFlag}&showDisplay=${data.showDisplay?data.showDisplay:''}`,
        method: 'get'
    })
}
export function getUserHolidayOrgCodeByOrgApi(data: any) {
    return request({
        url: `/uservacation/getVacationTypeVoListByOrgCode?orgCode=${data.orgCode}`,
        method: 'get'
    })
}
export function getWorkDaysByTimeApi(data: any) {
    return request({
        url: `/qjdb/getWorkDay`,
        method: 'post',
        data
    })
}

export function getWorkDay(data: any) {
    return request({
        url: `/ccdb/getWorkDay`,
        method: 'post',
        data
    })
}
// ccdb/getWorkDay


// sysAgentConfig/getSysAgentList
export function getSysAgentList(data: any) {
    return request({
        url: `sysAgentConfig/getSysAgentList?userCode=${data.userNumber}&type=${data.type}&source=${data.source}`,
        method: 'get'
    })
}

export function getSameDeptUser(data: any) {
    return request({
        url: `ccdb/getSameDeptUser`,
        method: 'post',
        data
    })
}




export function getYSCGWorkDaysByTimeApi(data: any) {
    return request({
        url: `/cgjCertApp/getWorkDay`,
        method: 'post',
        data
    })
}
export function getHolidayRulesByOrgIdApi(data: any) {
    return request({
        url: `/unitvacationrule/getByVacationTypeCode?companyCode=${data.companyCode}&vacationTypeCode=${data.vacationTypeCode?data.vacationTypeCode:''}`,
        method: 'get',
    })
}



// unitEvectionRule/getByEvectionCode
export function getByEvectionCode(data: any) {
    return request({
        url: `/unitEvectionRule/getByEvectionCode?companyCode=${data.companyCode}&evectionCode=${data.evectionCode?data.evectionCode:''}&showDisplay=${data.showDisplay?data.showDisplay:''}`,
        method: 'get',
    })
}


// unitEvectionRule/getByEvectionCode
export function getTravelReportAgentUserList(data: any) {
    return request({
        url: `/wcbbdb/getTravelReportAgentUserList?userNumber=${data.userNumber}`,
        method: 'get',
    })
}


export function changeLeaveApplyApi(data: any) {
    return request({
        url: '/qjdb/qjChangeApply',
        method: 'post',
        data
    })
}
export function createLeaveApplyApi(data: any) {
    return request({
        url: '/qjdb/qjApply',
        method: 'post',
        data
    })
}
export const getHolidayApproversByLogUserIdApi = (data: any) => {
    return request({
        url: `/qjdb/getDownApproval?busId=${data.id}&operationType=${data.operationType}`,
        method: 'get',
    })
}
export function submitApplyApi(data: any) {
    return request({
        url: '/qjdb/qjApplyCommit',
        method: 'post',
        data
    })
}

export function submitTravelReport(data: any) {
    return request({
        url: '/wcbbdb/submitTravelReport',
        method: 'post',
        data
    })
}

export function createBusTripApplyApi(data: any) {
    return request({
        url: '/qjdb/ccApply',
        method: 'post',
        data
    })
}
export function createGooutApplyApi(data: any) {
    return request({
        url: '/qjdb/wycbApply',
        method: 'post',
        data
    })
}
export function getApplyTodoTableDataApi(data: any) {
    return request({
        url: '/qjdb/qjEvectionQueryPage',
        method: 'post',
        data
    })
}
export function submitApplyApproveApi(data: any) {
    return request({
        url: '/qjdb/qjApplyApproval',
        method: 'post',
        data
    })
}

export function wcBbApplyApproval(data: any) {
    return request({
        url: '/wcbbdb/wcBbApplyApproval',
        method: 'post',
        data
    })
}


export function updateLeaveApplyApi(data: any) {
    return request({
        url: '/qjdb/qjApplyModify',
        method: 'post',
        data
    })
}

export function updateQjTravelReport(data: any) {
    return request({
        url: '/wcbbdb/updateQjTravelReport',
        method: 'post',
        data
    })
}


export function deleteLeaveApplyApi(data: any) {
    return request({
        url: '/qjdb/qjApplyDelete',
        method: 'post',
        data
    })
}

export function getApplyTodoDetailByIdApi(id: any) {
    return request({
        url: `/qjdb/qjApplyDetail?id=${id}`,
        method: 'get'
    })
}


export function getQjTravelReportDetail(id: any) {
    return request({
        url: `/wcbbdb/getQjTravelReportDetail?id=${id}`,
        method: 'get'
    })
}




export function multiAgreeTodoApi(data: any) {
    return request({
        url: '/cgjCertApp/batchAgree',
        method: 'post',
        data
    })
}

export function getTodoByIdAPi(id: any) {
    return request({
        url: `/cgjCertApp/getReportDetail?id=${id}`,
        method: 'get',
    })
}

export function approveTodoApi(data: any) {
    return request({
        url: '/cgjCertApp/auditCgCertificate',
        method: 'post',
        data
    })
}

export function revokeAbroadApi(data: any) {
    return request({
        url: '/cgjCertApp/applyCgPrivateExitApp',
        method: 'post',
        data
    })
}

export function submitApprovalApi(data: any) {
    return request({
        url: '/cgjCertApp/submitDownApproval',
        method: 'post',
        data
    })
}

export function createCardAPi(data: any) {
    return request({
        url: '/cgjCertApp/addReport',
        method: 'post',
        data
    })
}

export function createAbroadAPi(data: any) {
    return request({
        url: '/cgjCertApp/addCgPrivateExitApp',
        method: 'post',
        data
    })
}

export function updateAbroadAPi(data: any) {
    return request({
        url: '/cgjCertApp/updateCgPrivateExitApp',
        method: 'post',
        data
    })
}


export function updateTodoAPi(data: any) {
    return request({
        url: '/cgjCertApp/updateReport',
        method: 'post',
        data
    })
}

export function deleteTodoAPi(data: any) {
    return request({
        url: '/cgjCertApp/deleteReport',
        method: 'post',
        data
    })
}

export const getTodoTableApi = (data: any) => {
    return request({
        url: '/cgjCertApp/getQiReportList',
        method: 'post',
        data
    })
}
export const getApproversByLogUserIdApi = (data: any) => {
    return request({
        url: `/cgjCertApp/getDownApproval?busId=${data.id}&operationType=${data.operationType?data.operationType:''}`,
        method: 'get',
    })
}
export const creatAbroadApi = (data: any) => {
    return request({
        url: `/cgjCertApp/saveReport`,
        method: 'post',
        data
    })
}

export const createCCApplyApi = (data: any) => {
    return request({
        url: `/ccdb/ccApply`,
        method: 'post',
        data
    })
}

export const updateCCApplyApi = (data: any) => {
    return request({
        url: `/ccdb/ccApplyModify`,
        method: 'post',
        data
    })
}
export const deleteCCApplyApi = (data: any) => {
    return request({
        url: `/ccdb/ccApplyDelete`,
        method: 'post',
        data
    })
}


export function delQjTravelReportDetail(id: any) {
    return request({
        url: `/wcbbdb/delQjTravelReportDetail?id=${id}`,
        method: 'get'
    })
}


export const approveCCApplyApi = (data: any) => {
    return request({
        url: `/ccdb/ccApplyApproval`,
        method: 'post',
        data
    })
}
export const submitCCApplyApi = (data: any) => {
    return request({
        url: `/ccdb/submitDownApproval`,
        method: 'post',
        data
    })
}
export const getCCApplyDetailApi = (id: any) => {
    return request({
        url: `/ccdb/qjEvectionQueryDetail?id=${id}`,
        method: 'get',
    })
}
export const getCCApplyNextApproverOptionsApi = (data: any) => {
    return request({
        url: `/ccdb/getDownApproval`,
        method: 'get',
        params:data
    })
}



// 待销假申请
export const portPrivateExitApp = (data: any) => {
    return request({
        url: `/cgjCertApp/submitCgPrivateExitApp`,
        method: 'post',
        data
    })
}



// 同意待销假

export const portAuditCgPrivateExitApp = (data: any) => {
    return request({
        url: `/cgjCertApp/auditCgPrivateExitApp`,
        method: 'post',
        data
    })
}



// 外出报备申请

export const portwycbApply = (data: any) => {
    return request({
        url: `/wcbbdb/wycbApply`,
        method: 'post',
        data
    })
}

export const portApproveUser = (data: any) => {
    return request({
        url: `/wcbbdb/getApproveUser`,
        method: 'post',
        data
    })
}



export function getQjReportByUser(data){
    return request({
        url: `/qjReport/getQjReportByUser?userNumber=${data}`,
        method: 'get'
    })
}
// 出差类型下拉
export function getUserTravelOptionsByOrgApi(data: any) {
    return request({
        url: `/unitEvectionRule/getUnitEvectionRuleeVoListByOrgCode`,
        method: 'get',
        params:data
    })
}
export function turnOverProcessConfig(data: any) {
    return request({
        url: `/process/turnOverProcessConfig`,
        method: 'post',
        data
    })
}


//  判断外出报备权限
// wcbbdb/ueryTravelReportUser

export function ueryTravelReportUserr(data){
    return request({
        url: `/wcbbdb/qeryTravelReportUser`,
        method: 'get'
    })
}
