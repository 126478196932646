import {defineStore} from 'pinia'
import {getUsePermissionButtonsApi, getUserInfoApi} from "@/api/request/userInfo";
import {getAllOptionsAsync} from "@/views/CG/DB/apis";
import cache from "@/plugins/cache";

export const useUserStore = defineStore('user', {
    // 公共数据
    state: () => ({
        // 登录用户权限按钮数据
        permissionButtonKeys: <any>[],
        userInfo: <any>{},
        loadingStatus: false,
        allCodes: <any>[],//全部的数据字典
    }),
    getters: {
        getLoadingStatus: (state: any) => state.loadingStatus,
        getPermissionButtonKeysByState: (state) => {
            return state.permissionButtonKeys;
        },
        getLoginUserInfoByState: (state) => {
            return state.userInfo;
        }

    },
    // 公共的同步和异步方法
    actions: {
        getAllCodesAsync() {
            getAllOptionsAsync().then((allCode: any) => {
                this.allCodes = allCode;
            })
        },
        setLoadingStatus(payload: any) {
            console.log('获取加载状态-状态机', payload);
            return this.loadingStatus = payload;
        },
        setPermissionButtonKeysByState(payload: any) {
            console.log('获取用户-状态机', payload);
            return this.permissionButtonKeys = payload;
        },
        async getUserInfoAsync() {
            const res = await getUserInfoApi();
            console.log('状态机获取用户信息:', res);
            this.userInfo = res.data;
            localStorage.setItem('symUserInfo', JSON.stringify(res.data));
        },
        // 获取登录用户权限按钮
        async getUsePermissionButtonsAsync() {
            const userInfo: any = localStorage.getItem('userInfo');
            if (userInfo) {
                const res = await getUsePermissionButtonsApi();
                const permissionButtonKeys = res.data?.flatMap((item: any) => item.buttonScope)
                cache.local.set('permissionButtonKeys', JSON.stringify(permissionButtonKeys));
                console.log('app获取用户权限按钮:', permissionButtonKeys)
            }
        }
    },

})