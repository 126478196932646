import '@xr/theme3-default/dist/all.css';
import {initXrApp} from "~xrcore/core";
import {xrConfig} from "@/xr-config";
// @/context 内的代码用于可自定义扩展,在xrApp初始化之前import，修改@xr/core包的默认配置
import '@/context';
import '@/flexible.js'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 引入样式
// import customAppSetting from '@/plugins/AppSetting.vue';
// xrConfig[`customAppSetting`] = customAppSetting;
const app = initXrApp(xrConfig);
import cache from '@/plugins/cache.js'
import '@/assets/fonts/font.scss'
console.log('initXrApp',app)

app.directive('permission', {
    mounted(el: any, binding: any, vnode: any, prevVnode: any) {
        const permission = binding.value; // 获取指令参数，即权限值
        const permissionButtonKeys: any = cache.local.get('permissionButtonKeys');
        console.log('app-main查看自定义权限:', permissionButtonKeys, permission, permissionButtonKeys && JSON.parse(permissionButtonKeys)?.includes(permission))
        if (permissionButtonKeys && JSON.parse(permissionButtonKeys)?.includes(permission)) {
            el.style.display = 'flex';
        } else {
            // 如果用户没有该权限，则隐藏按钮
            el.style.display = 'none';
        }
    },
});
import './utils/request';
// pinia状态机注册使用
// import {createPinia} from 'pinia';
// const pinia = createPinia();
// app.use(pinia);
// app.use(ElementPlus);
app.mount('#app');


