import {importAllSvg} from "@/context/dev/svg";
import {addRoutes, setDefaultRouteComponent, setRouteBaseContext} from "~xrcore/routers/config";
import {addWhiteList, getWhiteList, setWhiteList} from "~xrcore/routers/utils";
import {RoutePermissionFactory} from "~xrcore/factory/route-permission";
import {RouteKeeper} from "~xrcore/routers/routeKeeper/RouteKeeper";
import X4JFormAuthChecker from "~xrcore/routers/security/form-auth";
import SsoToIPLAT4JWorker from "~xrcore/routers/security/SsoToIPLAT4JWorker";
import SSOWorker from "~xrcore/factory/sso//SSOWorker";
import {SSOFactory} from "~xrcore/factory/sso/SSOFactory";
import Base64 from "~xrcore/utils/Base64";



importAllSvg();
/**
 * 1.自定义路由上下文
 */
// setRouteBaseContext('/xrapp3');

/**
 * 2.使用自定义组件修改默认路由中的component
 * 默认component来自@xr/core包
 */
setDefaultRouteComponent('dashboard',()=>import('../views/dashboard/myIndex.vue'));
// setDefaultRouteComponent('dashboard',()=>import('@/views/dashboard/index.vue'));
// addRoutes([{path:'/home',component: ()=>import('@/views/home')}]); // 新增自定义路由，不用再去后台注册菜单
/**
 * 3.白名单
 */
// addWhiteList('/home'); // 添加白名单
// setWhiteList(['/login']); // 白名单全部置换
// getWhiteList(); // 获取白名单
// addWhiteList('/register'); // 添加白名单
// addWhiteList('/web/ZZFI01'); // 添加白名单
// addWhiteList('/web/ZZSMQ2'); // 添加白名单
// addWhiteList('/web/ZZUS09'); // 添加白名单


/**
 * 4.路由导航守卫设置路由策略，类似spring filter
 * 参考RouteKeeper的几个实现类
 */
// class MyRouteKeeper implements RouteKeeper{
//     type='mykeeper';
//
//     match(to: RouteLocationNormalized): boolean {
//         return false; // 设置匹配规则
//     }
//
//     toNext(to: RouteLocationNormalized, from: RouteLocationNormalized, ...args): Promise<any> {
//      // 符合匹配规则的路由请求会进入到toNext ,此处可以执行自己的逻辑
//         return  "/myDemo";
//     }
//
// }
// const myRouteKeeper : RouteKeeper;
// RoutePermissionFactory.prepend(myRouteKeeper);

/**
 * 单点登录：用于区分4J菜单还是VUE菜单，如果是4J菜单则直接访问4J后台页面地址
 * https://confluence.baocloud.cn/pages/viewpage.action?pageId=287772000
 */
// const ssoWorker: SsoToIPLAT4JWorker = new SsoToIPLAT4JWorker();
// ssoWorker.setServerBaseUrl('http://localhost:8080/xr-ua');
// SSOFactory.setSSOWorker(ssoWorker)
