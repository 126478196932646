import axios from 'axios';
import { useXrLoginStore } from '~xrcore/store/modules/xr_login';



export function apiToken(sContent:any=null) {
    // return EiCommunicator.$get(`/oa/refreshToken?refreshToken=${sContent}`, {
    //   'Content-Type': 'application/json;charset=utf-8',
    //     __isRefreshToken: true,
    //     //'Accept': 'json' // 服务器返回的数据格式
    //   })


 return   axios.get(process.env.VUE_APP_BASE_API_OWN + `/oa/refreshToken?refreshToken=${sContent}`, {
      headers: {
          'Content-Type': 'application/json;charset=utf-8',
        __isRefreshToken: true,
      }
    })
        .then((res: any) => {
          if (res.data.code === 200) {
            const userStore = useXrLoginStore()
            localStorage.setItem('signature_token',res.data.data.token)
            localStorage.setItem('refreshToken',res.data.data.refreshToken)
            userStore.setToken(res.data.data.token)
            userStore.setRefreshToken(res.data.data.refreshToken)
          return res
          }
        })
        .catch(error => {
  
        });
}